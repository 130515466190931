import { Component, OnInit } from "@angular/core";

import { Auth0Service } from "../../services/auth0.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConsultasService } from "../../services/consultas.service";

import * as $ from "jquery";
import { NominaService } from "../../services/nomina.service";
import swal from 'sweetalert2';
import { Auth0ServiceNomina } from '../../services/auth0ServiceNomina';
import { Subscription, Observable, Subscriber } from 'rxjs';
import { ZonasHorarias } from '../../pages/Clases/ZonasHorarias';



declare var jQuery: any;
declare var $: any;

@Component({
  selector: "app-navbar-nomina",
  templateUrl: "./navbar-nomina.component.html",
  styleUrls: ["./navbar-nomina.component.css"]
})
export class NavbarNominaComponent implements OnInit {
  nombreUsuario: any;
  formaDes: FormGroup;
  formaCambioPass: FormGroup;
  formaDesAuto: FormGroup;
  // inicial: string = this.getDate1();
  // final: string = this.getDate2();
  emailpattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";

  Obj: any = [];

  sub1: Subscription;
  subscription: Subscription;
  Timezone = new ZonasHorarias();
  constructor(
    private _consultasService: ConsultasService,
    public _Auth0Service: Auth0Service,
    private _nominaService: NominaService,
    private _Auth0ServiceNomina:Auth0ServiceNomina
  ) {
    this.subscription = this.regresaObservable().subscribe(
      numero => console.log("Subs", numero),
      error => console.error("Error en el obs", error),
      () => console.log("El observador termino!")
    );
    // this.formaDes = new FormGroup({
    //   ClaveCIEC: new FormControl(""),
    //   FechaInicial: new FormControl(
    //     this.inicial,
    //     CustomValidators.minDate("2005-01-01")
    //   ),
    //   FechaFinal: new FormControl(
    //     this.final,
    //     CustomValidators.minDate("2005-01-01")
    //   )
    // });
    // this.formaDesAuto = new FormGroup({
    //   ClaveCiecAUT: new FormControl(""),
    //   ClaveCiecAUTConfirmacion: new FormControl("")
    // });
    this.formaCambioPass = new FormGroup({
      Oldpass: new FormControl(""),
      Newpass: new FormControl(""),
      Newpass2: new FormControl(""),
      Correo: new FormControl("",Validators.pattern(this.emailpattern))
    });
  }
  ngOnInit() {
    this.nombreUsuario = this._Auth0Service.usuarioNamePagina();
  }
//   getDate1() {
//     var now = new Date();
//     var day = ("0" + now.getDate()).slice(-2);
//     var month = ("0" + (now.getMonth() - 1 + 1)).slice(-2);
//     var today = now.getFullYear() + "-" + month + "-" + day;
//     return (this.inicial = today.toString());
//   }

//   //obtiene la fecha actual
//   getDate2() {
//     var now = new Date();
//     var day = ("0" + now.getDate()).slice(-2);
//     var month = ("0" + (now.getMonth() + 1)).slice(-2);
//     var today = now.getFullYear() + "-" + month + "-" + day;
//     return (this.final = today.toString());
//   }

//   //metodo que identifica cual es el tipo de descarga seleccionado y te manda a su descarga correspondiente
//   DescargaSAT() {
//     if (this.ManualOAutomatico == "MANUAL") {
//       this.descargaSatManual();
//     } else if (this.ManualOAutomatico == "AUTO") {
//       this.descargaSatAutomatico();
//     }
//   }
//   MensajeModalDesSat: string;
//   private descargaSatManual() {
//     //realiza descarga SAT manual
//     this.Obj = this.formaDes.value;
//     if (
//       this.Obj.ClaveCIEC == "" ||
//       this.Obj.FechaInicial == "" ||
//       this.Obj == ""
//     ) {
//       this.MensajeModalDesSat = "No puede haber campos vacios";
//       $("#modalErrorSat").modal("show"); //Open Modal
//     } else {
//       this._consultasService
//         .DescargaSatManual(
//           this.Obj.ClaveCIEC,
//           this.Obj.FechaInicial,
//           this.Obj.FechaFinal,
//           false
//         )
//         .subscribe(data => {
//           if (data.session == 1) {
//             this.CerrarSesion(data.msg);
//           } else {
//           if (
//             data.msg ==
//             "Ha ocurrido un error al intentar iniciar el servicio de descarga manual, favor de intentar mas tarde nuevamente."
//           )
//             this.MensajeModalDesSat = "Ha ocurrido un error";
//           else if (data.msg == "Ha ocurrido un error al intentar guardar.") {
//             this.MensajeModalDesSat == "Ha ocurrido un error";
//           } else if (
//             data.msg == "La descarga automática se ha activado exitosamente."
//           ) {
//             this.MensajeModalDesSat == data.msg;
//           } else {
//             this.MensajeModalDesSat == data.msg;
//           }
//           $("#modalErrorSat").modal("show"); //Open Modal
//         }
//         });
//     }
//   }
//   //realiza descarga SAT automatica
//   descargaSatAutomatico() {
//     this.Obj = this.formaDesAuto.value;
//     if (
//       this.Obj.ClaveCiecAUT == this.Obj.ClaveCiecAUTConfirmacion &&
//       this.Obj.ClaveCiecAUT !== ""
//     ) {
//       this._consultasService
//         .DescargaSatAutomatica(this.Obj.ClaveCiecAUT)
//         .subscribe(data => {
//           if (data.session == 1) {
//             this.CerrarSesion(data.msg);
//           } else {
//           if (
//             data.msg == "La descarga automática se ha activado exitosamente."
//           ) {
//             this.MensajeModalDesSat == data.msg;
//           } else if (data.msg == "Ha ocurrido un error al intentar guardar.") {
//             this.MensajeModalDesSat = "Ha ocurrido un error";
//           } else {
//             this.MensajeModalDesSat == data.msg;
//           }
//           $("#modalErrorSat").modal("show"); //Open ModalFF
//         }
//         });
//     } else if (
//       this.Obj.ClaveCiecAUT == "" ||
//       this.Obj.ClaveCiecAUTConfirmacion == ""
//     ) {
//       this.MensajeModalDesSat = "No puede haber campos vacios";
//       $("#modalErrorSat").modal("show"); //Open Modal
//     } else {
//       this.MensajeModalDesSat = "los campos no son iguales";
//       $("#modalErrorSat").modal("show"); //Open Modal
//     }
//   }

//   ManualOAutomatico: string = "MANUAL";

//   //Identifica el tipo de descarga seleccionada
//   esManualOAutomatica(ManOrAut) {
//     if (ManOrAut == "MANUAL") {
//       this.ManualOAutomatico = "MANUAL";
//     } else {
//       if ((ManOrAut = "AUTO")) {
//         this.ManualOAutomatico = "AUTO";
//       }
//     }
//   }
  cambiarContrasena() {
    
    var Obj =this.formaCambioPass.value
  
if(Obj.Oldpass=="")
this.Warning('la contraseña actual debe ser agregada')
else if(Obj.Newpass=="")
this.Warning('la nueva contraseña debe ser agregada')
else if(Obj.Newpass2=="")
this.Warning('la nueva contraseña debe ser agregada de nuevo')
else if(Obj.Newpass!=Obj.Newpass2)
this.Warning('Las nuevas contraseñas no coinciden')
else if(Obj.Correo=="")
this.Warning('el correo debe ser agregado')

else {
  
  this._nominaService
  .CambiarContrasenaNomina(Obj.Oldpass, Obj.Newpass, Obj.Correo)
  .subscribe(data => {
    if (data.session == 1) {
      this.CerrarSesion(data.msg);
    } else {
    if(data.success==true)
    {
      swal(
        '¡Bien hecho!',
        'Has cambiado la contraseña con exito',
        'success'
      )
     $("#cambiarContrasena").modal("hide");

    }
    else{
      swal(
        'Ha ocurrido un error!',
        data.msg.toString(),
        'error'
      )
    }
  }
  });
error => console.error(error);

    }

  }


  Warning(e)
  {
    swal(
      'Ha ocurrido un error!',
      e.toString(),
      'error'
    )
  }
  CerrarSesion(dataMensaje:string) {
    this.WarningPerzonalizado("Atencion!",dataMensaje);
    this._Auth0Service.logout();
  }
  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  CerrarSesionNormal() {
    this._Auth0Service.logout();
    this._Auth0ServiceNomina.logout();
    window.location.reload();
  }


  closer: boolean = false;
  contador2: number = 0;
  
  /////////////////////////////////////////////
///////Este Metodo contiene la funcionalidad que validad la hora y fecha del token
  ////////////////////////////////////////////

  regresaObservable(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      let contador = 0;

      const intervalo = setInterval(() => {
        contador++;


////obtiene la fecha y hora de vencimiento del token
        let fechaTokenVence = localStorage.getItem("Token").split(",");
//////Obtine la fecha y hora actual de la computadora
        var TokenDate = new Date();
////obtienes la fecha y hora de vencimiento una vez mas la cual se le agregara mas adelante un minuto mas        
        var TokenDateVence = new Date(fechaTokenVence[1].replace("p. m.", ""));
        ///aqui solo declaro otra fecha que usare mas adelante
        var TokenDateVenceTry = new Date(
          fechaTokenVence[1].replace("p. m.", "")
        );
   
/////Este es el metodo el cual se encarga de validar las zonas horarias y devuelve una hora que se ajusta de acuerdo a la hora del servidor
        var HoraGMT7 = this.Timezone.ValidaZonaHoraria(TokenDate);
/////aqui es donde se le agrega el minuto para forzar el cierre de sesion si es que se hace caso omiso al contador       
        TokenDateVenceTry.setMinutes(TokenDateVence.getMinutes() + 1);
/////aqui se forza el cierre si es que el minuto del contador ya paso      
        if (HoraGMT7 > TokenDateVenceTry) {
          this.CerrarSesionNormal();
        }

////si la hora actual es mayor a la fecha del token aparecera el aviso
        if (HoraGMT7 > TokenDateVence && this.closer == false) {
          // if(hour>=1 || hour<=-1  || Min>=20 || Min<=-20 && this.closer==false)
          // this.ngOnDestroy()
          clearInterval(intervalo);
          observer.complete();
          this.closer = true;

          $("#ModalSesionOutN").modal("show");
          this.tmieles();

          TokenDateVence.setDate(TokenDateVence.getDate() + 1);
        }
      }, 2000);
    });
  }
  actualizarToken() {
    this._consultasService.ActualizarToken().subscribe(data => {
      //RENUEVA TOKEN

      var x = localStorage.getItem("Token");
      localStorage.removeItem("Token");
      var x = localStorage.getItem("Token");
      localStorage.setItem("Token", data.token);

      if (data != null) {
        this.entendido = true;
        this.closer = false;
        $("#ModalSesionOutN").modal("hide");
        this.subscription = this.regresaObservable().subscribe(
          numero => console.log("Subs", numero),
          error => console.error("Error en el obs", error),
          () => console.log("El observador termino!")
        );
      }
    });
  }
  //CUENTA REGRESIVA DE 1MIN

  tmieles() {
    return (this.sub1 = this.regresaObservable2().subscribe(data => {
      console.log("Subs", data.valor);
    }));
  }
  contador: number;

  regresaObservable2(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this.contador = 60;

      const intervalo = setInterval(() => {
        if (this.entendido == true) {
          clearInterval(intervalo);
          observer.complete();
          this, (this.entendido = false);
        }

        this.contador--;

        const salida = {
          valor: this.contador
        };

        observer.next(salida);

        if (this.contador <= 0) {
          this.CerrarSesionNormal();
        }
      }, 1000);
    });
  }
  entendido: boolean = false;
  RefrescarSesion() {
    this.actualizarToken();
  }
  // regresaObservable(): Observable<any> {
  //   return new Observable((observer: Subscriber<any>) => {
  //     let contador = 0;

  //     const intervalo = setInterval(() => {
  //       contador++;

  //       // const salida = {
  //       //   valor: contador
  //       // };

  //       // observer.next(salida);

    

  //       let fechaTokenVence = localStorage.getItem("Token").split(",");

  //       // if ( contador === 3 ) {
  //       //
  //       //
  //       // }

  //       // if ( contador === 2 ) {
  //       //   // clearInterval(intervalo);
  //       //   observer.error('Auxilio!');
  //       // }

  //       var TokenDate = new Date();
  //       var TokenDateVence = new Date(fechaTokenVence[1].replace("p. m.", ""));

     
    
  //       if (TokenDate > TokenDateVence && this.closer == false) {
  //         // if(hour>=1 || hour<=-1  || Min>=20 || Min<=-20 && this.closer==false)
  //         // this.ngOnDestroy()
  //         clearInterval(intervalo);
  //         observer.complete();
  //         this.closer = true;

          
  //         $("#ModalSesionOutN").modal("show");
  //         this.tmieles();
     
  //         TokenDateVence.setDate(TokenDateVence.getDate() + 1);
  //       }
  //     }, 2000);
  //   });
  // }
  // actualizarToken() {
  //   this._consultasService.ActualizarToken().subscribe(data => {
  //     //RENUEVA TOKEN
      

  //     var x = localStorage.getItem("Token");
  //     localStorage.removeItem("Token");
  //     var x = localStorage.getItem("Token");
  //     localStorage.setItem("Token", data.token);
 
  //     if (data != null) {
  //       this.entendido = true;
  //       this.closer = false;
  //       $("#ModalSesionOutN").modal("hide");
  //       this.subscription = this.regresaObservable().subscribe(
  //         numero => console.log("Subs", numero),
  //         error => console.error("Error en el obs", error),
  //         () => console.log("El observador termino!")
  //       );
  //     }
  //   });
  // }
  // //CUENTA REGRESIVA DE 1MIN

  // tmieles() {
  //   return (this.sub1 = this.regresaObservable2()
  //   .subscribe(data => {
  //     console.log("Subs", data.valor);
  //   }));
  // }
  // contador: number;

  // regresaObservable2(): Observable<any> {
  //   return new Observable((observer: Subscriber<any>) => {
  //     this.contador = 60;

  //     const intervalo = setInterval(() => {
  //       if (this.entendido == true) {
  //         clearInterval(intervalo);
  //         observer.complete();
  //         this, (this.entendido = false);
  //       }

  //       this.contador--;

  //       const salida = {
  //         valor: this.contador
  //       };

  //       observer.next(salida);

  //       if (this.contador <= 0) {
  //         this.CerrarSesionNormal();
  //       }
  //     }, 1000);
  //   });
  // }
  // entendido: boolean = false;
  // RefrescarSesion() {
  //   this.actualizarToken();
  // }
}
