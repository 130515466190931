export class ZonasHorarias {
  constructor() {}
  //Valida la Zona Horaria Para ajustar al hora del pacifico (Hora de hermosillo)
  public ValidaZonaHoraria(hora) {
    var time = hora.toString().substring(25, 33);
    switch (time) {
      case "GMT-1200":
        hora.setHours(hora.getHours() + 5);
        break;
      case "GMT-1100":
        hora.setHours(hora.getHours() + 4);
        break;
      case "GMT-1000":
        hora.setHours(hora.getHours() + 3);
        break;
      case "GMT-0900":
        hora.setHours(hora.getHours() + 2);
        break;
      case "GMT-0800":
        hora.setHours(hora.getHours() + 1);
        break;
      case "GMT-0600":
        hora.setHours(hora.getHours() - 1);
        break;
      case "GMT-0500":
        hora.setHours(hora.getHours() - 2);
        break;
      case "GMT-0400":
        hora.setHours(hora.getHours() - 3);
        break;
      case "GMT-0300":
        hora.setHours(hora.getHours() - 4);
        break;
      case "GMT-0200":
        hora.setHours(hora.getHours() - 5);
        break;
      case "GMT-0100":
        hora.setHours(hora.getHours() - 6);
        break;
      case "GMT+0000":
        hora.setHours(hora.getHours() - 7);
        break;
      case "GMT+0100":
        hora.setHours(hora.getHours() - 8);
        break;
      case "GMT+0200":
        hora.setHours(hora.getHours() - 9);
        break;
      case "GMT+0300":
        hora.setHours(hora.getHours() - 10);
        break;
      case "GMT+0400":
        hora.setHours(hora.getHours() - 11);
        break;
      case "GMT+0500":
        hora.setHours(hora.getHours() - 12);
        break;
      case "GMT+0600":
        hora.setHours(hora.getHours() - 13);
        break;
      case "GMT+0700":
        hora.setHours(hora.getHours() - 14);
        break;
      case "GMT+0800":
        hora.setHours(hora.getHours() - 15);
        break;
      case "GMT+0900":
        hora.setHours(hora.getHours() - 16);
        break;
      case "GMT+1000":
        hora.setHours(hora.getHours() - 17);
        break;
      case "GMT+1100":
        hora.setHours(hora.getHours() - 18);
        break;
      case "GMT+1200":
        hora.setHours(hora.getHours() - 19);
        break;
      case "GMT+1300":
        hora.setHours(hora.getHours() - 20);
        break;
      case "GMT+1400":
        hora.setHours(hora.getHours() - 21);
        break;
      default:
        break;
    }

    return hora;
  }
}
