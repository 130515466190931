import { tokenize } from "@angular/compiler/src/ml_parser/lexer";
import {
  Component,
  OnInit,
  SimpleChanges,
  HostListener,
  Inject
} from "@angular/core";
import { RFC } from "../../Interfaces/formulario.comprobante";
import { Auth0Service } from "../../services/auth0.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConsultasService } from "../../services/consultas.service";
import { CustomValidators } from "ng4-validators";
import { Observable, Subscriber, Subscription } from "rxjs";
import { retry, map, filter } from "rxjs/operators";

import * as $ from "jquery";
import swal from "sweetalert2";

import { Router } from "@angular/router";
import { Auth0ServiceNomina } from "../../services/auth0ServiceNomina";
import { TILDE } from "@angular/cdk/keycodes";
import { parse } from "path";
import { date } from "ng4-validators/src/app/date/validator";
import { validacionServices } from "../../services/validacion.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ZonasHorarias } from "../../pages/Clases/ZonasHorarias";
import { buzonService } from "../../services/buzon.service";
import { GlobalsService } from "../../services/globalService.service";
declare var jQuery: any;
declare var $: any;
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent implements OnInit {
  nombreUsuario: any;
  formaDes: FormGroup;
  formaDesAuto: FormGroup;
  inicial: string = this.getDate1();
  final: string = this.getDate2();
  formaCambioPass: FormGroup;
  fechaVencimiento : Date = new Date;

  sub1: Subscription;
  subscription: Subscription;
  Timezone = new ZonasHorarias();
  Obj: any = [];
  ngBuzon:any;
  hidden = true;
  quitarSoporte : boolean = false;
  mostrarMensaje : boolean = false;
  esPrueba : boolean = false;
  sinCorreo : boolean = false;
  correoForm  : FormControl = new FormControl();
  isLoadingResults = false;
  styleAmbos : boolean = false;

  constructor(
    private _consultasService: ConsultasService,
    public _Auth0Service: Auth0Service,
    public router: Router,
    private _Auth0ServiceNomina: Auth0ServiceNomina,
    private _buzonService: buzonService,
    public _globalService : GlobalsService
  ) {
    // _globalService.consultarUsuarioIRC();
    this.subscription = this.regresaObservable().subscribe(
      numero => console.log("Subs", numero),
      error => console.error("Error en el obs", error),
      () => console.log("El observador termino!")
    );
    this.quitarSoporte = this._globalService.usuarioIRC;
    this.formaDes = new FormGroup({
      ClaveCIEC: new FormControl(""),
      FechaInicial: new FormControl(
        this.inicial,
        CustomValidators.minDate("2005-01-01")
      ),
      FechaFinal: new FormControl(
        this.final,
        CustomValidators.minDate("2005-01-01")
      )
    });
    this.formaDesAuto = new FormGroup({
      ClaveCiecAUT: new FormControl(""),
      ClaveCiecAUTConfirmacion: new FormControl("")
    });
    this.formaCambioPass = new FormGroup({
      Oldpass: new FormControl(""),
      Newpass: new FormControl(""),
      Newpass2: new FormControl("")
    });
  }
  EmpresaXUsuario: any = [];
  EmpresaUsuario: boolean = false;
  innerWidth: any;
  ngOnInit() {
    this.innerWidth = innerWidth;

    let EmpresaXusuario = JSON.parse(localStorage.getItem("empresaXusuario"));
    this.esPrueba = JSON.parse(localStorage.getItem("prueba"));
    this.sinCorreo = JSON.parse(localStorage.getItem("sinCorreo"));

    this.validarMensajeFecha();
    if (EmpresaXusuario != null) {
      this.EmpresaUsuario = true;
    } else {
      this.EmpresaUsuario = false;
    }
    this.EmpresaXUsuario = EmpresaXusuario;
    this.nombreUsuario = this._Auth0Service.usuarioNamePagina();

    $(document).on("click", "#freshwidget-close", function() {
      window.location.reload();
    });

    $(document).on("click", "#freshwidget-overlay", function() {
      window.location.reload();
    });
  }
  ngAfterViewInit() {
  this.Refrescar();
  }
  // Refrescar() {
  //   this._buzonService
  //     .GetCFDIPendientes(localStorage.getItem("nombreUsu"))

  //     .subscribe(data => {
  //       data.success == true? this.ngBuzon = data.data.length : this.ngBuzon = 0;
  //       this.ngBuzon != 0 ? this.hidden = false : this.hidden = true;
  //     });

  //    error => console.error(error);
  // }
  Refrescar() {

        // this.ngBuzon = 0;
        // this.ngBuzon != 0 ? this.hidden = false : this.hidden = true;


  };
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    console.log("La página se va a cerrar");
    this.subscription.unsubscribe();
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = innerWidth;
  }

  getDate1() {
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() - 1 + 1)).slice(-2);
    var today = now.getFullYear() + "-" + month + "-" + day;
    return (this.inicial = today.toString());
  }

  //obtiene la fecha actual
  getDate2() {
    var now = new Date();
    var day = ("0" + now.getDate()).slice(-2);
    var month = ("0" + (now.getMonth() + 1)).slice(-2);
    var today = now.getFullYear() + "-" + month + "-" + day;
    return (this.final = today.toString());
  }

  //metodo que identifica cual es el tipo de descarga seleccionado y te manda a su descarga correspondiente
  DescargaSAT() {
    if (this.ManualOAutomatico == "MANUAL") {
      this.descargaSatManual();
    } else if (this.ManualOAutomatico == "AUTO") {
      this.descargaSatAutomatico();
    }
  }
  MensajeModalDesSat: string;
  public descargaSatManual() {
    //realiza descarga SAT manual
    this.Obj = this.formaDes.value;
    var Ini = new Date(this.Obj.FechaInicial);
    var Fin = new Date(this.Obj.FechaFinal);
    if (Ini > Fin) {
      this.WarningFechas();
    } else if (
      this.Obj.ClaveCIEC == "" ||
      this.Obj.FechaInicial == "" ||
      this.Obj == ""
    ) {
      this.WarningCamposvacios();
    } else {
      this._consultasService
        .DescargaSatManual(
          this.Obj.ClaveCIEC,
          this.Obj.FechaInicial,
          this.Obj.FechaFinal,
          false
        )
        .subscribe(data => {
          if (
            data.msg ==
            "Ha ocurrido un error al intentar iniciar el servicio de descarga manual, favor de intentar mas tarde nuevamente."
          )
            this.WarningError(data.msg);
          else if (data.msg == "Ha ocurrido un error al intentar guardar.") {
            this.WarningError(data.msg);
          } else if (
            data.msg == "La descarga automática se ha activado exitosamente."
          ) {
            this.WarningSucces(data.msg);
          } else if (
            data.msg ==
            "Su usuario no cuenta con este servicio, para más información contacte a nuestro equipo de ventas."
          ) {
            this.WarningError(data.msg);
          }
        });
    }
  }
  //realiza descarga SAT automatica
  descargaSatAutomatico() {
    this.Obj = this.formaDesAuto.value;
    if (
      this.Obj.ClaveCiecAUT == this.Obj.ClaveCiecAUTConfirmacion &&
      this.Obj.ClaveCiecAUT !== ""
    ) {
      this._consultasService
        .DescargaSatAutomatica(this.Obj.ClaveCiecAUT)
        .subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
            if (
              data.msg == "La descarga automática se ha activado exitosamente."
            ) {
              this.WarningSucces(data.msg);
            } else if (
              data.msg == "Ha ocurrido un error al intentar guardar."
            ) {
              this.WarningError(data.msg);
            } else if (
              data.msg ==
              "Su usuario no cuenta con este servicio, para más información contacte a nuestro equipo de ventas."
            ) {
              this.WarningError(data.msg);
            } else {
              this.WarningError(data.msg);
            }
          }
        });
    } else if (
      this.Obj.ClaveCiecAUT == "" ||
      this.Obj.ClaveCiecAUTConfirmacion == ""
    ) {
      this.MensajeModalDesSat = "No puede haber campos vacios";
      this.WarningError(this.MensajeModalDesSat);
    } else {
      this.MensajeModalDesSat = "los campos no son iguales";
      this.WarningError(this.MensajeModalDesSat);
    }
  }

  ManualOAutomatico: string = "MANUAL";

  //Identifica el tipo de descarga seleccionada
  esManualOAutomatica(ManOrAut) {
    if (ManOrAut == "MANUAL") {
      this.ManualOAutomatico = "MANUAL";
    } else {
      if ((ManOrAut = "AUTO")) {
        this.ManualOAutomatico = "AUTO";
      }
    }
  }

  public WarningCamposvacios() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "No puede haber campos vacios",
      text: "Ingrese datos faltantes",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  public WarningFechas() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Error de fechas",
      text: "la fecha inicial no puede ser mayor a la fecha final",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  public WarningError(msg) {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Ha ocurrido un error",
      text: msg.toString(),
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  viewEye: string = "password";
  public WarningSuccessTopEnd(msg) {
    swal({
      position: "top-end",
      type: "success",
      title: msg.toString(),
      showConfirmButton: false,
      timer: 1500
    });
  }
  verEye() {
    if (this.viewEye == "text") {
      this.viewEye = "password";
    } else {
      this.viewEye = "text";
    }
  }
  public cambiarContrasena() {
    var Obj = this.formaCambioPass.value;

    var asd = this.validaciones(Obj.Oldpass, Obj.Newpass, Obj.Newpass2);

    if (asd == true) {
      this._consultasService
        .CambiarContrasena(Obj.Oldpass, Obj.Newpass)
        .subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
            if (data.success == true) {
              this.WarningSucces("Has cambiado la contraseña con exito");
              $("#cambiarContrasena").modal("hide");
            } else {
              this.Warning(data.msg);
            }
          }
        });
      error => console.error(error);
    }
  }

  Warning(e) {
    swal("Ha ocurrido un error!", e.toString(), "error");
  }
  public ResultadosPorConsulta() {
    var cant = $("#sltResultados").val();

    this._consultasService.EnviarCantidadConsulta(cant).subscribe(data => {
      if (data.session == 1) {
        this.CerrarSesion(data.msg);
      } else {
      }
      if (data.success == true) {
        $("#mdlCambiarCantidadConsulta").modal("hide");
        this.WarningSucces(
          "Vuelva a iniciar sesion para ver reflejado el cambio"
        );
      }
    });
    error => console.log(error);
  }
  WarningSucces(val) {
    swal("¡Bien hecho!", val, "success");
  }
  validaciones(Oldpass, Newpass, Newpass2) {
    if (Oldpass == "") {
      this.Warning("la contraseña actual debe ser agregada");
      return false;
    } else if (Newpass == "") {
      this.Warning("la nueva contraseña debe ser agregada");
      return false;
    } else if (Newpass2 == "") {
      this.Warning("la nueva contraseña debe ser agregada de nuevo");
      return false;
    } else if (Newpass != Newpass2) {
      this.Warning("Las nuevas contraseñas no coinciden");
      return false;
    } else {
      return true;
    }
  }
  public WarningResultadosCambio() {
    swal({
      title: "¡Listo!",
      text: "Vuelva a iniciar sesión para ver los cambios",
      type: "success",
      confirmButtonText: "Entendido"
    });
  }
  CerrarSesion(dataMensaje: string) {
    this.WarningPerzonalizado("Atencion!", dataMensaje);
    this._Auth0Service.logout();
  }
  public WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  CerrarSesionNormal() {
    this._Auth0Service.logout();
    this._Auth0ServiceNomina.logout();
    //  window.location.reload();
    if(this._globalService.usuarioIRC){
      this.router.navigate(["/LoginIRC"]).then(x=>{
        localStorage.setItem('usuarioIRC', "false");
        this._globalService.usuarioIRC = false;
        location.reload();
      });
    }else{
      this.router.navigate([""]).then(x=>{
        localStorage.setItem('usuarioIRC', "false");
        this._globalService.usuarioIRC = false;
        localStorage.setItem('mensajeToolbar', "false");
        localStorage.setItem('loginIndex', "false");
        location.reload();

      });
    }

  }
  // Warning(e)
  // {
  //   swal(
  //     'Ha ocurrido un error!',
  //     e.toString(),
  //     'error'
  //   )
  // }

  public navegarConfiguracion() {
    this.router.navigate(["/comprobante/configuracion"]).then(x=>{
      location.reload();
    });
  }

  public navegarReporte() {
    this.router.navigate(["/comprobante/reporte"]).then(x=>{
      location.reload();
    });
  }


  public WarningCambiompresa(text) {
    swal({
      title: "¡Cambio realizado!",
      text: "Usted cambio a " + text,
      type: "success",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Entendido"
    }).then(result => {
      if (result.value) {
        //  this.router.navigate(["/comprobante/configuracion"]);
        window.location.reload();
        // this.navegarConsulta();
      }
    });
  }

  public navegarConsulta() {
    this.router.navigate(["/comprobante/consulta"]);
  }

  //   SeleccionarGrupoEmpresas()
  //   {
  // $("#modalGrupoEmpresas").modal("show")
  //   }
  TokenEmpPrincipal: string = "";
  public cambiarSession(rfc) {
    // var rfcId= $( "#selecionadoEmpresa" ).val();
    debugger
    let letRfc = this.EmpresaXUsuario[rfc].RFC;
    this._consultasService
      .CambiarSession(
        this.EmpresaXUsuario[rfc].RFC,
        localStorage.getItem("TokenMaster")
      )
      .subscribe(data => {
        if (data.sesionX != null) {
          localStorage.setItem("TokenMaster", data.tokens);
        }
        if (data.session == 1) {
          this.WarningError(data.msg);
        } else if (data.success == false) {
          this.WarningError(data.msg);
        } else {
          this._Auth0Service.login(data.sesionX);

          this.WarningCambiompresa(data.datos);
        }
      });
    error => console.error(error);

    // alert("it works")
    $("#modalGrupoEmpresas").modal("hide");
  }
  verEmpresas: boolean = false;
  openmod() {
    // $("#modalGrupoEmpresas").modal("show")

    this.verEmpresas = true;
  }

  closer: boolean = false;
  contador2: number = 0;



  /////////////////////////////////////////////
///////Este Metodo contiene la funcionalidad que validad la hora y fecha del token
  ////////////////////////////////////////////

  regresaObservable(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      let contador = 0;

      const intervalo = setInterval(() => {
        contador++;


////obtiene la fecha y hora de vencimiento del token
        let fechaTokenVence = localStorage.getItem("Token").split(",");
//////Obtine la fecha y hora actual de la computadora
        var TokenDate = new Date();
////obtienes la fecha y hora de vencimiento una vez mas la cual se le agregara mas adelante un minuto mas
        var TokenDateVence = new Date(fechaTokenVence[1].replace("p. m.", ""));
        ///aqui solo declaro otra fecha que usare mas adelante
        var TokenDateVenceTry = new Date(
          fechaTokenVence[1].replace("p. m.", "")
        );

/////Este es el metodo el cual se encarga de validar las zonas horarias y devuelve una hora que se ajusta de acuerdo a la hora del servidor
        var HoraGMT7 = this.Timezone.ValidaZonaHoraria(TokenDate);
/////aqui es donde se le agrega el minuto para forzar el cierre de sesion si es que se hace caso omiso al contador
        TokenDateVenceTry.setMinutes(TokenDateVence.getMinutes() + 1);
/////aqui se forza el cierre si es que el minuto del contador ya paso
        if (HoraGMT7 > TokenDateVenceTry) {
          this.CerrarSesionNormal();
        }

////si la hora actual es mayor a la fecha del token aparecera el aviso
        if (HoraGMT7 > TokenDateVence && this.closer == false) {
          // if(hour>=1 || hour<=-1  || Min>=20 || Min<=-20 && this.closer==false)
          // this.ngOnDestroy()
          clearInterval(intervalo);
          observer.complete();
          this.closer = true;

          $("#ModalSesionOut").modal("show");
          this.tmieles();

          TokenDateVence.setDate(TokenDateVence.getDate() + 1);
        }
      }, 2000);
    });
  }
  actualizarToken() {
    this._consultasService.ActualizarToken().subscribe(data => {
      //RENUEVA TOKEN

      var x = localStorage.getItem("Token");
      localStorage.removeItem("Token");
      var x = localStorage.getItem("Token");
      localStorage.setItem("Token", data.token);

      if (data != null) {
        this.entendido = true;
        this.closer = false;
        $("#ModalSesionOut").modal("hide");
        this.subscription = this.regresaObservable().subscribe(
          numero => console.log("Subs", numero),
          error => console.error("Error en el obs", error),
          () => console.log("El observador termino!")
        );
      }
    });
  }
  //CUENTA REGRESIVA DE 1MIN

  tmieles() {
    return (this.sub1 = this.regresaObservable2().subscribe(data => {
      console.log("Subs", data.valor);
    }));
  }
  contador: number;

  regresaObservable2(): Observable<any> {
    return new Observable((observer: Subscriber<any>) => {
      this.contador = 60;

      const intervalo = setInterval(() => {
        if (this.entendido == true) {
          clearInterval(intervalo);
          observer.complete();
          this, (this.entendido = false);
        }

        this.contador--;

        const salida = {
          valor: this.contador
        };

        observer.next(salida);

        if (this.contador <= 0) {
          this.CerrarSesionNormal();
        }
      }, 1000);
    });
  }
  entendido: boolean = false;
  RefrescarSesion() {
    this.actualizarToken();
  }


  MensajeFasePrueba()
  {

    // swal({
    //   title: "¡Atención!",
    //   text: "Esta pantalla se encuentra en fase de desarrollo",
    //   type: "question",
    //   confirmButtonColor: "#3085d6",
    //   confirmButtonText: "Entendido"
    // }).then(result => {
    //   if (result.value) {
    //     this.router.navigate(["/comprobante/buzonCancelacion"]);
    //     location.reload();
    //   }
    // });
   // location.reload();
    this.router.navigate(["/comprobante/buzonCancelacion"]).then(x=> {
      location.reload();
    });


  }
  Descargas()
  {   //  location.reload();
        this.router.navigate(["/comprobante/descargas"]).then(x =>{
          location.reload();
        });

  }

  validarMensajeFecha(){
    let fecha = new Date(localStorage.getItem("fechaVencimiento"));
    this.fechaVencimiento = fecha;
    let mensajeToolbar = (localStorage.getItem("mensajeToolbar") == "true" ? true : false);
    let loginIndex = (localStorage.getItem("loginIndex") == "true" ? true : false );
    if(mensajeToolbar && loginIndex){
      this.mostrarMensaje = true;
      this.styleAmbos = true;
    }
  }

  guardarCorreo(){
    if(this.correoForm.value !== null && this.correoForm.value !== ""){
      let correo = this.correoForm.value;
      if(!correo.includes("@")){
        this.isLoadingResults = true;
        this._consultasService.guardarCorreoSucursal(correo).subscribe(data =>{
          this.isLoadingResults = false;
          if(data !== null){
            if(data.success){
              $("#ModalCorreoRepobox").modal("hide");
              this.cerraMensajeCorreo();
              swal({
                title: "Éxito",
                type: "success",
                confirmButtonText: "Ok"
              });
            }else{
              this.mensajeError(data.msg);
            }
          }else{
            this.mensajeError("Ocurrio un error al guardar el correo");
          }
        }, error =>{
          this.mensajeError("Ocurrio un error al guardar el correo");
        })
      }else{
      this.mensajeAtencion("No se permite '@'.");
      }
    }else{
      this.mensajeAtencion("Favor de agregar un correo.");
    }
  }

  mensajeError(error){
    swal({
      title: "Error",
      text: error,
      type: "error",
      confirmButtonText: "Entendido"
    });
  }

  mensajeAtencion(atencion){
    swal({
      title: "Atención",
      text: atencion,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }

  cerraMensaje(){
    localStorage.setItem("mensajeToolbar", JSON.stringify(false));
    localStorage.setItem("loginIndex", JSON.stringify(false));
    this.mostrarMensaje = false;
    this.styleAmbos = false;
  }

  cerraMensajeCorreo(){
    localStorage.setItem("sinCorreo", JSON.stringify(false));
    this.sinCorreo = false;
  }

  abrirModalCorreo(){
    $("#ModalCorreoRepobox").modal("show");
  }
}
