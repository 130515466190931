import { Component, OnInit } from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
export interface PeriodicElement {
  folio: string;
  fecha: string;
  total: string;
  position:number;
  
}

const ELEMENT_DATA: PeriodicElement[] = [
  {position:1,folio: 'OBR016578', fecha: '31/01/2020', total: '$102,899'},
  {position:2,folio: 'OBR016588', fecha: '03/02/2020', total: '$1000'},
 
];
@Component({
  selector: 'app-boveda-fiscal',
  templateUrl: './boveda-fiscal.component.html',
  styleUrls: ['./boveda-fiscal.component.css']
})
export class BovedaFiscalComponent implements OnInit {
  displayedColumns: string[] = ['sel', 'folio', 'fecha', 'total', 'sat', 'val','zip','select'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  constructor() { }

  ngOnInit() {
  }

}
