
import { Injectable } from "@angular/core";
import { Http, Headers, Response, ResponseContentType } from "@angular/http";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;
import 'rxjs/Rx';

@Injectable()
export class sharedService {
  //=================================
  //Cadenas de conexion
  // uriProduccion:any ="http://localhost:54723/api/"
  uriProduccion: string = ConexionApisRepoBox;
  GetFilesEmail: string = this.uriProduccion + "api/ServiceAws/DownloadFileEmail";
  UrlCambiarContrasenaLink: string = this.uriProduccion + "api/Servicio/Almacenamiento/CambiarContrasenaLink"
  obtenerDatosEmpresa: string = this.uriProduccion + "api/Servicio/correo/obtenerEmpresaNombre/"
  constructor(private http: Http) { }

  //=================================
  //DESCARGA LOS COMPROBANTES QUE SE ENVIARON ATRAVES DE UN EMAIL, 
  //-Recive un id encriptado en base64
  //=================================

    public DescargarCFDIEmail(id: string) {
      let url = this.GetFilesEmail;
      let body: string = JSON.stringify(id);
  
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
  
      return this.http
        .post(url, body, {
          headers: headerOptions,
          responseType: ResponseContentType.Blob
        })
        .map((response: Response) => response.blob());
    }
  
    public CambiarContrasenaLink(psContrasenaNew: string, link: string) {
      let url = this.UrlCambiarContrasenaLink;
      let dato = psContrasenaNew + "," + link
      var body = JSON.stringify(dato);
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
  
      return this.http
        .post(url, body, { headers: headerOptions })
        .map(x => x.json());
    }
  
    public ObtenerDatosEmpresa(link: string) {
      let url = this.obtenerDatosEmpresa;
      var headerOptions = new Headers({ "Content-Type": "application/json" });
  
      return this.http
        .get(url + link, { headers: headerOptions })
        .map(x => x.json());
    }
    
} 