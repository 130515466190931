import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import swal from "sweetalert2";
import { ConsultasService } from '../components/services/consultas.service';
import { Router } from '@angular/router';
import { Auth0Service } from '../components/services/auth0.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {
  Usuario
} from "../components/Interfaces/formulario.comprobante";
import { GlobalsService } from '../components/services/globalService.service';

@Component({
  selector: 'app-index-irc',
  templateUrl: './index-irc.component.html',
  styleUrls: ['./index-irc.component.css']
})
export class IndexIRCComponent implements OnInit {

  forma: FormGroup;
  usuarioLogin: Usuario = {
    usuario: null,
    password: null
  };
  isLoadingResults: boolean = false;
  constructor(
    private _consultasService: ConsultasService,
    private router: Router,
    private _Auth0Service: Auth0Service,
    public _globalService : GlobalsService
  ) { }

  ngOnInit() {
    this.inicializarForma();
  }

  inicializarForma(){
    this.forma = new FormGroup({
      email: new FormControl("", [Validators.minLength(5)]),
      Password: new FormControl("", [Validators.minLength(4)])
    });
  }


  guardar() {
    this.isLoadingResults = true;
    this.usuarioLogin["Usuario"] = this.forma.value["email"];
    this.usuarioLogin["Password"] = this.forma.value["Password"];
    this.isLoadingResults = true;
    if(this.usuarioLogin["Usuario"].toUpperCase() !== "IIFE.NACIONAL")
    {
      swal("Atención", "El usuario que esta intentando acceder no existe", "warning");
      this.isLoadingResults = false;
    }else{
      this._consultasService
      .LoginValidate(
        this.usuarioLogin["Usuario"],
        this.usuarioLogin["Password"]
      )
      .subscribe(data => {
        let resp = this._Auth0Service.login(data);
        if (resp == "esEmpresa") {

          localStorage.setItem("tipo", "esEmpresa");
          localStorage.setItem("usuario", JSON.stringify(data.empresa));
          localStorage.setItem("clavePrincipal", data.clavePrincipal);
          if (data.prueba != undefined) {
            localStorage.setItem("prueba", data.prueba);
          }
          this._Auth0Service.isLogged = true;
          // this.modalEntrar.nativeElement.click();
          this._globalService.usuarioIRC = true;
          localStorage.setItem('usuarioIRC', "true")
          this.router.navigate(["/comprobante/consulta"]);
          this.isLoadingResults = false;

        }

        else {
          if(data.msg.toString() === "el usuario que esta intentando acceder no existe"){
            swal("Atención", "El usuario o password es incorrecto", "warning");
            this.isLoadingResults = false;
          }else{
            swal("Ha ocurrido un error!", data.msg.toString(), "error");
            this.isLoadingResults = false;
          }
          
        }

        if (data.empresa == 'undefined' || data.empresa == undefined) {
        }
        else {
          if (data.empresa.EMPRESAxUSUARIO[0] != null) {
            localStorage.setItem(
              "empresaXusuario",
              JSON.stringify(data.empresa.EMPRESAxUSUARIO)
            );
          }
        }

      }, error => {
        console.error(error)
      });
    error => {
      console.error(error)
    }
    }
    
  }


}
