import { Component, OnInit } from "@angular/core";
import { RFC } from "../../Interfaces/formulario.comprobante";
import { Auth0Service } from "../../services/auth0.service";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ConsultasService } from '../../services/consultas.service';
import { CustomFormsModule, CustomValidators } from "ng4-validators";
import { validateConfig } from "@angular/router/src/config";
import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;
import swal from "sweetalert2";
import { NominaService } from "../../services/nomina.service";
import { Router } from "@angular/router";
import { ValidarPermisos } from "../consulta-facturas/ClasesFacturas/Permisos";
import { SweetAlertModals } from '../Clases/alert';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  nombreUsuario: any;
  formaDes: FormGroup;
  formaDesAuto: FormGroup;
  inicial: string = this.getDate1();
  final: string = this.getDate2();
  formaCambioPass: FormGroup;
  mostrarCertificado: boolean=false;
  mostrarFiel: boolean=false;
  ngPasswordCSD: any;
  ngPasswordConfirmarCSD: any;
  FileKey:any=null;
  FileCer:any=null;
  alertWarning:boolean=false;
  mensajeAlert:string="";
  ngPasswordFail:boolean;
  Alerts:SweetAlertModals;
  
  panelOpenState = false;
  Obj: any = [];
  permisosUsuarios:ValidarPermisos;
  formaContrasenaCSD: FormGroup;
  constructor(private _consultasService: ConsultasService,public _Auth0Service: Auth0Service,public router:Router) {
    this.permisosUsuarios = new ValidarPermisos();
    (this.permisosUsuarios)
    let usuarioEmpresa=JSON.parse(localStorage.getItem("usuario"));
    this.permisosUsuarios.consultarPermisos(usuarioEmpresa.Servicios);
    this.formaDes = new FormGroup({
      ClaveCIEC: new FormControl(""),
      FechaInicial: new FormControl(
        this.inicial,
        CustomValidators.minDate("2005-01-01")
      ),
      FechaFinal: new FormControl(
        this.final,
        CustomValidators.minDate("2005-01-01")
      )
    });
    this.formaDesAuto = new FormGroup({
      ClaveCiecAUT: new FormControl(""),
      ClaveCiecAUTConfirmacion: new FormControl("")
    });
    this.formaCambioPass = new FormGroup({
      Oldpass: new FormControl(""),
      Newpass: new FormControl("",Validators.minLength(7)),
      Newpass2: new FormControl("",Validators.minLength(7))
    });
    this.formaContrasenaCSD = new FormGroup({
      Newpass: new FormControl("",Validators.minLength(7)),
      Newpass2: new FormControl("",Validators.minLength(7))
    });
    this.Alerts= new SweetAlertModals();
  }
  ngOnInit() {
    this.nombreUsuario = this._Auth0Service.usuarioNamePagina();
  }
  //obtiene la fecha actual
  private getDate1() {
    
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 1 ? "0" + month : month;

    var day = new Date(now.getFullYear(), now.getMonth(), 1);
    var today =
      now.getFullYear() +
      "-" +
      this.conseguirmes(mesFinal) +
      "-" +
      "0" +
      day.getDate();

    return (this.inicial = today.toString());
  }
  conseguirdia(dia) {
    if (dia.toString().length == 3) return dia.substring(1, 3);
    else return dia;
  }
  conseguirmes(mes) {
    
    if (mes.toString().length == 3) return mes.substring(1, 3);
    else return mes;
  }

  //obtiene la fecha actual
  private getDate2() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let dia = "0" + now.getDate().toString();

    // let diaBueno =dia.toString.length>=2? dia.toString().replace('0',''):dia.toString() ;
    let today =
      now.getFullYear() +
      "-" +
      this.conseguirmes(mesFinal) +
      "-" +
      this.conseguirdia(dia);

    return (this.final = today);
  }

  //metodo que identifica cual es el tipo de descarga seleccionado y te manda a su descarga correspondiente
  DescargaSAT() {
    if (this.ManualOAutomatico == "MANUAL") {
      this.descargaSatManual();
    } else if (this.ManualOAutomatico == "AUTO") {
      this.descargaSatAutomatico();
    }
  }
  MensajeModalDesSat: string;
  public descargaSatManual() {
    //realiza descarga SAT manual
    this.Obj = this.formaDes.value;
    var Ini = new Date(this.Obj.FechaInicial);
    var Fin = new Date(this.Obj.FechaFinal);
    if (Ini > Fin) {
      this.WarningFechas();
    } else if (
      this.Obj.ClaveCIEC == "" ||
      this.Obj.FechaInicial == "" ||
      this.Obj == ""
    ) {
      this.WarningCamposvacios();
    } else {
      this._consultasService
        .DescargaSatManual(
          this.Obj.ClaveCIEC,
          this.Obj.FechaInicial,
          this.Obj.FechaFinal,
          false
        )
        .subscribe(data => {
                    if ( data.success==false )
            this.WarningErrorDescarga(data.msg);
     
          else if (data.success==true) {
            this.WarningSucces(data.msg);
          }
        });
    }
  }
  //realiza descarga SAT automatica
  descargaSatAutomatico() {
    this.Obj = this.formaDesAuto.value;
    if (
      this.Obj.ClaveCiecAUT == this.Obj.ClaveCiecAUTConfirmacion &&
      this.Obj.ClaveCiecAUT !== ""
    ) {
      this._consultasService
        .DescargaSatAutomatica(this.Obj.ClaveCiecAUT)
        .subscribe(data => {
                    if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
          (data);
          if (
            data.msg == "La descarga automática se ha activado exitosamente."
          ) {
            this.WarningSucces("Sus CFDIs se reflejaran a partir de mañana.");
          } else if (data.msg == "Ha ocurrido un error al intentar guardar.") {
            this.WarningError(data.msg);
          } else if (
            data.msg ==
            "Su usuario no cuenta con este servicio, para más información contacte a nuestro equipo de ventas."
          ) {
            this.WarningErrorDescarga(data.msg);
          } else {
            this.WarningErrorDescarga(data.msg);
          }}
        });
    } else if (

      this.Obj.ClaveCiecAUT == "" ||
      this.Obj.ClaveCiecAUTConfirmacion == ""
    ) {
            this.MensajeModalDesSat = "No puede haber campos vacios";
      this.WarningErrorDescarga(this.MensajeModalDesSat);
    } else {
      this.MensajeModalDesSat = "los campos no son iguales";
      this.WarningErrorDescarga(this.MensajeModalDesSat);
    }
  }

  ManualOAutomatico: string = "MANUAL";

  //Identifica el tipo de descarga seleccionada
  esManualOAutomatica(ManOrAut) {
    if (ManOrAut == "MANUAL") {
      this.ManualOAutomatico = "MANUAL";
    } else {
      if ((ManOrAut = "AUTO")) {
        this.ManualOAutomatico = "AUTO";
      }
    }
  }

  private WarningCamposvacios() {
    swal({
      title: "No puede haber campos vacios",
      text: "Ingrese datos faltantes",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  private WarningFechas() {
    swal({
      title: "Error de fechas",
      text: "la fecha inicial no puede ser mayor a la fecha final",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningError(msg) {
    swal({
      title: "Ha ocurrido un error",
      text: msg.toString(),
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningErrorDescarga(msg) {
    swal({
      title: "Atención",
      text: msg.toString(),
      type: "warning",
      confirmButtonText: "Entendido"
    });
  }
  viewEye: string = "password";
  private WarningSuccessTopEnd(msg) {
    swal({
      position: "top-end",
      type: "success",
      title: msg.toString(),
      showConfirmButton: false,
      timer: 1500
    });
  }
  verEye() {
    if (this.viewEye == "text") {
      this.viewEye = "password";
    } else {
      this.viewEye = "text";
    }
  }
  public cambiarContrasena() {
    let Obj = this.formaCambioPass.value;
    let asd = this.validaciones(Obj.Oldpass, Obj.Newpass, Obj.Newpass2);

    if (asd == true) {
      this._consultasService
        .CambiarContrasena(Obj.Oldpass, Obj.Newpass)
        .subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
          
          (data);

          if (data.success == true) {
            this.WarningSucces("Has cambiado la contraseña con exito");
            $("#cambiarContrasena").modal("hide");
          } else {
            this.Warning(data.msg);
          }}
        });
      error => console.error(error);
    }
  }

  Warning(e) {
    swal("Ha ocurrido un error!", e.toString(), "error");
  }


public ResultadosPorConsulta(){

let cant = $("#sltResultados").val()
this._consultasService.EnviarCantidadConsulta(cant).subscribe(data => {
    if (data.session == 1) {
    this.CerrarSesion(data.msg);
  } else {
(data);}
 
if (data.success==true)
{
    $('#mdlCambiarCantidadConsulta').modal('hide');
  this.WarningSucces('Vuelva a iniciar sesion para ver reflejado el cambio');
  localStorage.setItem("pageSize",cant)
}
});
error => (error);
}
  WarningSucces(val) {
    swal("¡Bien hecho!", val, "success");
  }
  validaciones(Oldpass, Newpass, Newpass2) {
    if (Oldpass == "") {
      this.Warning("la contraseña actual debe ser agregada");
      return false;
    } else if (Newpass == "") {
      this.Warning("la nueva contraseña debe ser agregada");
      return false;
    } else if (Newpass2 == "") {
      this.Warning("la nueva contraseña debe ser agregada de nuevo");
      return false;
    } else if (Newpass != Newpass2) {
      this.Warning("Las nuevas contraseñas no coinciden");
      return false;
    } else {
      return true;
    }
  }


private WarningResultadosCambio() {
  swal({
    title: '¡Listo!',
    text: 'Vuelva a iniciar sesión para ver los cambios',
    type: 'success',
    confirmButtonText: 'Entendido'    
  })
}
CerrarSesion(dataMensaje:string) {
  this.WarningPerzonalizado("Atencion!",dataMensaje);
  this._Auth0Service.logout();
}
private WarningPerzonalizado(titulo, texto) {
  swal({
    title: titulo,
    text: texto,
    type: "question",
    confirmButtonText: "Entendido"
  });
}
CerrarSesionNormal() {
  this._Auth0Service.logout();
}


private WarningFuncDesabilitada() {
  swal({
    title: '¡Funcion desabilitada!',
    text: 'Esta funcionalidad se encuentra en fase de desarrollo',
    type: 'warning',
    confirmButtonText: 'Entendido'    
  })
}

  public navegarConsulta(){
    this.router.navigate(["/consulta/nueva"]);
  
  }

  mostrarCertificadoModal(){
   
    if( this.mostrarCertificado == true)
      if(localStorage.getItem("certificadoCSD") == "true"){
        $('#modalConsultaCertificado').modal('show');
        
       
      }else{
        $('#modalAltaFiel').modal('show');
      }
      this.mostrarFiel = false;
      this.mostrarCertificado=true;
   
  }
  mostrarCertificadoModalFiel(){
    if( this.mostrarFiel == true)
      if(localStorage.getItem("certificadoFiel") == "true"){
        $('#modalConsultaCertificadoFiel').modal('show');
        
       
      }else{
        $('#modalAltaFielF').modal('show');
      }
      this.mostrarCertificado = false;
      this.mostrarFiel=true;

  }
}
