//==================================
//Produccion
//
//================================== //==================================
  // module.exports.ConexionApisRepobox = "https://54.187.107.30:8989/"
  // module.exports.LigaVerDetalleValidacion = "https://54.187.107.30:8990/validacion/"
  // module.exports.ConexionApisRepobox = "https://www.repobox.com.mx:8989/"
  // module.exports.LigaVerDetalleValidacion = "https://www.repobox.com.mx:8990/validacion/"

  module.exports.ConexionApisRepobox = "https://54.187.107.30:8989/"
  module.exports.LigaVerDetalleValidacion = "https://54.187.107.30:8990/AcuseValidacion/"
  module.exports.ConexionApisRepobox = "https://www.repobox.com.mx:8989/"
  module.exports.LigaVerDetalleValidacion = "https://www.repobox.com.mx:8990/AcuseValidacion/"
  
  
//PRUEBAS 
//==================================
//module.exports.ConexionApisRepobox = "http://localhost:54723/"
// module.exports.LigaVerDetalleValidacion = "http://localhost:4200/validacion/"
