import { Component, OnInit } from '@angular/core';
import swal from "sweetalert2";


export class SweetAlertModals  {

  constructor() { }



  public ModalError(titulo,texto) {
    // this.MensajeDelModal = "No se encontraron facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: titulo,
      text: texto,
      type: "error",
      confirmButtonText: "Entendido"
    });
  }

  public ModalQuestion(titulo,texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  public ModalWarning(titulo,texto) {
    swal({
      title: titulo,
      text: texto,
      type: "warning",
      confirmButtonText: "Entendido"
    });
  }

  public ModalSuccess(titulo,texto) {
    swal({
      title: titulo,
      text: texto,
      type: "success",
      confirmButtonText: "Entendido"
    });
  }


}
