import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { sharedService } from '../../services/shared.service';
import { SweetAlertModals } from "../../pages/Clases/alert";
import { FormGroup,FormControl,Validators } from '@angular/forms';
import { debug } from 'util';

@Component({
  selector: 'app-recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.css']
})
export class RecuperarPasswordComponent {
  forma:FormGroup;
  correcto:boolean=true;
  id:string; 
  rfc:any; 
  nombreEmpresa:string;
  valores:any=null;
//variable formulario
  ContrasenaOld:string="";
  contrasenaNueva:string;
  confirmarContrasena:string;
 alerta = new SweetAlertModals();
 
      constructor(private activatedRoute:ActivatedRoute,private _sharedService:sharedService){ 
       
        this.activatedRoute.params.subscribe(params=>{
          
          this.id= params['id'];
  
        })
        this.ObtenerDatosEmpresa();
        this.forma = new FormGroup({
          contrasenaNueva: new FormControl("",[Validators.minLength(7)]),
          confirmarContrasena: new FormControl("",[Validators.minLength(7)])
        });
 }
   public ActualizarContrasena(){
  
      if(this.forma.value["contrasenaNueva"]==this.forma.value["confirmarContrasena"] ){

        this._sharedService.CambiarContrasenaLink(this.forma.value["contrasenaNueva"],this.id).subscribe(response=>{
       if(response.success==true) {
       this.alerta.ModalSuccess("Exito",response.msg);
       }else{
        this.alerta.ModalError("Error",response.msg);
       }
    })
    }else{
      this.correcto=false;
      this.alerta.ModalError("Las contraseñas no coinciden","Por favor verifica que las contraseñas sean iguales");
  
    }
   }

   public ObtenerDatosEmpresa(){
  

      this._sharedService.ObtenerDatosEmpresa(this.id).subscribe(response=>{
     if(response.success==true) {
      let data=response.data.split("|");
      this.rfc=data[0];
      this.nombreEmpresa=data[1];
     }else{
      this.alerta.ModalError("Error",response.msg);
         }
      })
   }
}
