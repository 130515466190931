
import { Injectable } from "@angular/core";
import {Http,Headers} from "@angular/http";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;
import 'rxjs/Rx';
@Injectable()
export class validacionServices {
   //=================================
  //Cadenas de conexion
   CadenaConexion:any = ConexionApisRepoBox;
   uriProduccion = this.CadenaConexion+"api/Servicio/acuseValidacion";
   uriAcuseValidacionXml = this.CadenaConexion+"api/Servicio/acuseValidacionXml";

  constructor(private http: Http) {}
  //=================================
  //Trae la validacion del comprobante 
  //=================================
  public validacion(id:any, fecha:any, esPago:any ) {
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    let url = `${this.uriProduccion}/${id}/${fecha}/${esPago}`;
      return this.http.get(url, { headers: headerOptions }).map(x => x.json());
  }
  public validacionXml(uuid:any ) {
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    let url = `${this.uriAcuseValidacionXml}/${uuid}`;
      return this.http.get(url, { headers: headerOptions }).map(x => x.json());
  }
} 
