import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
@Injectable()
export class Auth0ServiceNomina implements CanActivate {
  isLogged:boolean;
  usuarioName:any;
  constructor( private router:Router) {  this.isLogged = false;}
  isloged(){
    var aValue = localStorage.getItem("Token");
    let usuario=localStorage.getItem("tipo");
      if(aValue!=null && usuario=="esNomina"){
        return this.isLogged=true
      }
      else{
        return this.isLogged=false;
      }
  }
  public login (){
      this.isLogged = true;
  }
    public logout(){
      this.isLogged = false;
      localStorage.removeItem("Token");
      localStorage.removeItem("nombreUsu")
      localStorage.removeItem("razonSocial");
      localStorage.removeItem("tipo");
      this.router.navigate([""]);
    }

    public canActivate( ){
      if( this.isloged() ){
        console.log("El guard paso!");
        return true;
      }else{
        console.log("Bloqueado por el Guard");
        this.router.navigate([""]);
        return false;
      }
    }
  }
  
