import { Component, OnInit } from '@angular/core';
import { Auth0Service } from '../../components/services/auth0.service';
import {Usuario} from "../../components/Interfaces/formulario.comprobante";
import { ConsultasService } from "../../components/services/consultas.service";
import { FormGroup, FormControl, Validators, Form } from "@angular/forms";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Auth0ServiceNomina } from "../../components/services/auth0ServiceNomina";

import { indexService } from "../../components/services/index.Service";
import { RegistroModelo } from "../../components/Interfaces/formulario.comprobante";
import { Router } from '@angular/router';
import { ComprobanteWeb, comprobanteValidacionWeb, ModeloXmlValidacion } from '../../components/Interfaces/modeloXmlValidacion';
import { SweetAlertModals } from '../../components/pages/Clases/alert';

@Component({
  selector: 'app-validar-factura-electronica',
  templateUrl: './validar-factura-electronica.component.html',
  styleUrls: ['./validar-factura-electronica.component.css',
  "../style.css",
  "../light_blue.css",
  "../custom.css",
  "../index.component.css",
  "../animations.css",
  "../animate.css"]
})
export class ValidarFacturaElectronicaComponent implements OnInit {
  alertWarning:boolean=false;
  selectedFile:File=null;
  mensajeAlert:string="";
  ComprobanteWeb:ComprobanteWeb;
  cargarImagenesVisible : boolean=true;
  isLoadingResults:boolean=false;
  Alerts:SweetAlertModals;
  panelOpenState = false;
  constructor(private _indexService:indexService, private router:Router){
    this.validaEstilosViejos();
    this.ComprobanteWeb;
    this.Alerts= new SweetAlertModals();
  }
  ngOnInit(){
    this.ComprobanteWeb = new ComprobanteWeb();
  }
   alertArchivoFormato(tipoAlert:boolean,nombreArchivo:string){
     if(tipoAlert==false){
      this.alertWarning=true;
      this.mensajeAlert=nombreArchivo;
     }else{
      this.alertWarning=false;
      this.mensajeAlert=nombreArchivo;
     }
   }
  onFileSelected(event){
    this.selectedFile=<File>event.target.files[0];
    if(this.selectedFile.type!="text/xml"){
      this.alertArchivoFormato(false, this.selectedFile.name);
      return;
    }
    this.alertArchivoFormato(true, this.selectedFile.name);

  }
  public onUpload(){
    let validaciones = this.validacionesUpload()
    if(validaciones == false){
      return
    }
    
    const fd = new FormData();
    fd.append('cfdi', this.selectedFile, this.selectedFile.name)
    this.isLoadingResults=true;

    this._indexService.uploadCFDI(fd).subscribe(data=>{
      if(data.success == false){
        this.isLoadingResults=false;
        this.Alerts.ModalError("XML mal formado",data.msg)
        
      }else{
      this.cargarImagenesVisible=false;
      this.ComprobanteWeb.comprobanteValidacionWeb =<comprobanteValidacionWeb> data.comprobante.ComprobanteWebx;
      this.ComprobanteWeb.ModeloXmlValidacion =<ModeloXmlValidacion> data.comprobante.ModeloXmlValidacion;
      this.isLoadingResults=false;
      this.selectedFile=null;
      }
    })
    error => console.log(error);
  }
  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
}
public reactiveForm: FormGroup = new FormGroup({
  recaptchaReactive: new FormControl(null, Validators.required)
  
});

public validacionesUpload (): boolean{
  
  if(this.selectedFile == null ){
    this.Alerts.ModalWarning("Atencion" , "Favor de seleccionar un comprobante a validar.")
    return false;
  }

  else if(this.selectedFile.type!="text/xml"){
    this.Alerts.ModalWarning("Atencion", "El archivo " + this.selectedFile.name +" no es de tipo XML.")
    return false;
  }
  return true;
}
  public ocultarValidacion(){
    this.cargarImagenesVisible=true;
    this.reactiveForm.reset(this.reactiveForm);
  }
  public regresar(){
    
   
    this.router.navigate([""]).then( x =>{
      location.reload();
    });
  }

  validaEstilosViejos(){
           $("#nuevo18" ).attr("href", "");
  }
}
