import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { validacionServices } from '../../services/validacion.service';
import * as $ from 'jquery';



@Component({
  selector: 'app-acuse-validacion',
  templateUrl: './acuse-validacion.component.html',
  styleUrls: ['./acuse-validacion.component.css']
})
export class AcuseValidacionComponent implements OnInit {

  algo: string;
  id:string;
  folio: number;
  fecha:string;
  myVal:any=null;
  decode: string;


  constructor(private activatedRoute:ActivatedRoute,private _validacionService:validacionServices, private router:Router) {
    this.validaEstilosViejos();
   }

  ngOnInit() {
    this.decodificarParametro();
  }

  decodificarParametro(){
    this.activatedRoute.queryParams.filter(params => params.ID).subscribe(params =>{
      this.id = params.ID;
      console.log(this.id);
    })

    
    //this._validacionService.validacion(this.folio,this.fecha).subscribe(respuesta=>{
      this._validacionService.validacion(this.id,"null","0").subscribe(respuesta=>{
      if(respuesta!=null)
      this.myVal=respuesta;
     })
    
  }

  asd(){
    let selected = this.algo ? this.algo :null
    this.router.navigate(['/AcuseValidacion',{ queryParams: { ID: selected}}])
  }

  validaEstilosViejos(){
    $("#nuevo18" ).attr("href", "");
  }

}
