import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConsultasService } from '../../../services/consultas.service';
import swal from "sweetalert2";


@Component({
  selector: 'app-cancelar-suscripcion',
  templateUrl: './cancelar-suscripcion.component.html',
  styleUrls: ['./cancelar-suscripcion.component.css']
})
export class CancelarSuscripcionComponent implements OnInit {

  id:string;
  algo: string;
  correo: string;
  constructor( private router:Router, private _consultaService: ConsultasService, private activatedRoute:ActivatedRoute) { }

  ngOnInit() {
    this.cancelarSuscripcion();
  }


  cancelarSuscripcion(){
    this.activatedRoute.queryParams.filter(params => params.ID).subscribe(params =>{
      this.id = params.ID;
    })
    swal({
      title: "Está seguro de cancelar la suscripción",
      text:
        "Si da click en si, se cancelaran las notificaciones que lleguen a su correo electrónico.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
    }).then(result => {
      if (result.value) {
        this._consultaService.cancelarSuscripcion(this.id).subscribe( data =>{
          console.log(data);
        this.correo = data.correo;
      }, error =>{

      })  
      }else{
        this.regresarInicio();
      }
    }
    );
    
  }

  asd(){
    let selected = this.algo ? this.algo :null
    this.router.navigate(['/AcuseValidacion',{ queryParams: { ID: selected}}])
  }

  regresarInicio(){
    this.router.navigate(['/']);
  }

}
