import { RouterModule, Routes } from "@angular/router";
//import { ConsultaComprobanteComponent } from './components/pages/consulta-comprobante/consulta-comprobante.component';
import { Auth0Service } from "./components/services/auth0.service";


import { PagesComponent } from "./components/pages.component";
import { IndexComponent } from "./index/index.component";
import { PagoComponent } from "./components/pages/pago/pago.component";
import { ConsultaFacturasComponent } from './components/pages/consulta-facturas/consulta-facturas.component';
import { ConsultaNominaComponent } from "./components/pages/consulta-nomina/consulta-nomina.component";
import { NominaEmpleadoComponent } from "./components/pages/nomina-empleado/nomina-empleado.component";
import { ListaEmpleadosComponent } from "./components/pages/lista-empleados/lista-empleados.component";
import { ConfiguracionComponent} from "./components/pages/configuracion/configuracion.component"
import { Error404Component } from './components/pages/errors/error404/error404.component';
import { Error500Component } from './components/pages/errors/error500/error500.component';
import { Auth0ServiceNomina } from "./components/services/auth0ServiceNomina";
import { ValidacionComponent } from './components/shared/validacion/validacion.component';
import { EdicionComponent } from "./components/pages/edicion/edicion.component";
import { EdicionrecepcionComponent } from './components/pages/edicionrecepcion/edicionrecepcion.component';
import { EdicioncomprobanteComponent } from './components/pages/edicioncomprobante/edicioncomprobante.component';
import { FacturacionComponent } from "./components/pages/facturacion/facturacion.component";
import { ConsultaComprobante } from './components/Interfaces/formulario.comprobante';
import { DownloadsFileComponent } from './components/shared/downloads-file/downloads-file.component';
import { RecuperarPasswordComponent } from './components/shared/recuperar-password/recuperar-password.component';
import { BuzonCancelacionComponent } from "./components/pages/buzon-cancelacion/buzon-cancelacion.component";
import { ValidarFacturaElectronicaComponent } from "./index/validar-factura-electronica/validar-factura-electronica.component";
import { ResultadoValidacionCFDComponent } from "./index/validar-factura-electronica/resultado-validacion-cfd/resultado-validacion-cfd.component";
import { DescargaMasivaComponent } from "./components/pages/descarga-masiva/descarga-masiva.component";
import { MostrarReporteComponent } from './components/pages/consulta-facturas/componentesFacturas/mostrar-reporte/mostrar-reporte.component';
import { AcuseValidacionComponent } from "./components/shared/acuse-validacion/acuse-validacion.component";
import { ForoComponent } from "./components/shared/foro/foro.component";
import { HelpComponent } from './components/shared/help/help.component';
import {IndexNuevoComponent} from './index-nuevo/index-nuevo.component'
import { LoginAdminComponent } from './components/shared/login-admin/login-admin.component';
import { AdminComponent } from './components/pages/admin/admin.component';
import { Auth0ServiceAdminService } from './components/services/auth0-service-admin.service';
import { BovedaFiscalComponent } from './components/shared/boveda-fiscal/boveda-fiscal.component';
import { CancelarSuscripcionComponent } from './components/shared/cancelarSuscripcion/cancelar-suscripcion/cancelar-suscripcion.component';
import { IrcAcuseValidacionComponent } from "./components/shared/irc-acuse-validacion/acuse-validacion.component";
import { IndexIRCComponent } from './indexIRC/index-irc.component';
import { IrcValidacionComponent } from './components/shared/irc-validacion/irc-validacion.component';
import { ConsultaReportesComponent } from './components/pages/consulta-reportes/consulta-reportes.component';
import { VerCFDIComponent } from "./components/shared/ver-cfdi/ver-cfdi.component";



const APP_ROUTES: Routes = [
  {
    path: "comprobante",
    component: PagesComponent,
    children: [
      { path: "consulta", component: ConsultaFacturasComponent, canActivate: [Auth0Service]},
      { path: "pagos", component: PagoComponent },
      { path: "error", component: Error404Component },
      { path: "error2", component: Error500Component,},
      //========NOMINA
      { path: "nomina", component: ConsultaNominaComponent,  canActivate: [Auth0ServiceNomina] },
      { path: "empleadonomina", component: NominaEmpleadoComponent,canActivate: [Auth0ServiceNomina]},
      { path: "listaempleados", component: ListaEmpleadosComponent,canActivate: [Auth0ServiceNomina] },
       //=======FIN NOMINA
      { path: "configuracion", component: ConfiguracionComponent},
      { path: "reporte", component: ConsultaReportesComponent, canActivate: [Auth0Service]},
      { path: "edicionValidacion", component: EdicionComponent, canActivate: [Auth0Service]},
      { path: "edicionRecepcion", component: EdicionrecepcionComponent, canActivate: [Auth0Service]},
      { path: "edicionComprobante", component: EdicioncomprobanteComponent, canActivate: [Auth0Service]},
      
      { path: "buzonCancelacion", component: BuzonCancelacionComponent, canActivate: [Auth0Service]},

      { path: "descargas", component: DescargaMasivaComponent, canActivate: [Auth0Service]},
      
     
      
  //    { path: "consultaComprobantesObsoleto", component: ConsultaComprobanteComponent},
      { path: "", redirectTo: "/consulta", pathMatch: "full"  },
    ] 
    
  },
  // { path: "admin", component: AdminComponent, canActivate: [Auth0ServiceAdminService]},
  {
    path: "admin", 
    component: AdminComponent,
    children:[
     { path: "", loadChildren: './components/pages/admin/admin.module#AdminModule' },
    ]
  },

  { path: "boveda", component: BovedaFiscalComponent},
  { path: "", component: IndexNuevoComponent },
  { path: "aaa", component: MostrarReporteComponent },
  { path: "downloadsFile/:id", component: DownloadsFileComponent},
  { path: "recuperarPassword/:id", component: RecuperarPasswordComponent},
  { path: "validacion/:id/:fecha/:esPago", component: ValidacionComponent},
  { path: "facturacion", component: FacturacionComponent},
  { path: "validarCFDI", component:ValidarFacturaElectronicaComponent},
  { path: "CFDI", component:VerCFDIComponent},
  { path: "AcuseValidacion", component: AcuseValidacionComponent},
  { path: "IRCAcuseValidacion", component: IrcAcuseValidacionComponent},
  { path: "IRCvalidacion/:id/:fecha/:esPago", component: IrcValidacionComponent},
  { path: "LoginIRC", component: IndexIRCComponent},
  { path:"CancelarSuscripcion", component:CancelarSuscripcionComponent},
  {path: "Foro", component: ForoComponent},
  {path: "Help", component: HelpComponent},
  {path: "loginAdmin", component: LoginAdminComponent},
  { path: "**", component: Error404Component },
 
];

// const rutaAcuseVal : Routes = this.ru.navigate(['/AcuseValidacion'], { queryParams: { ID: 'popular' } });
// const stringRutaAcusVal = "AcuseValidacion" + {queryParams: {ID: 'as'}} ;


export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, {});
