import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Auth0Service } from "../components/services/auth0.service";
import {
  Usuario
} from "../components/Interfaces/formulario.comprobante";
import { ConsultasService } from "../components/services/consultas.service";
import { FormGroup, FormControl, Validators, Form } from "@angular/forms";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Auth0ServiceNomina } from "../components/services/auth0ServiceNomina";

import { indexService } from "../components/services/index.Service";
import { RegistroModelo } from "../components/Interfaces/formulario.comprobante";



@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: [
    "./style.css",
    "./light_blue.css",
    "./custom.css",
    "./index.component.css",
    "./animations.css",
    "./animate.css"
  ]
})
export class IndexComponent implements OnInit {
  hide = true;
valorCheck:string="";
  forma: FormGroup;
  emailRecover: FormGroup;
  emailpattern = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$";
  usuarioLogin: Usuario = {
    usuario: null,
    password: null
  };
  formaRegistro: FormGroup;
  frmEnvioContacto: FormGroup;
  user: string;
  pass: string;
  unamePattern = "^[a-zA-Z0-9_-]{1,40}$";
  registroModelo: RegistroModelo;
  isLoadingResults:boolean=false;
  color="warn";
NAmepatern="^[a-zA-Z ]{1,40}$"
  receptorPattern = "^[a-z A-Z0-9ñÑ,._-]{1,100}$";
  // ligaYouTube = "https://www.youtube.com/embed/308f68K24Us?autoplay=0"; 

  constructor(
    private _consultasService: ConsultasService,
    private router: Router,
    private _Auth0Service: Auth0Service,
    private _Auth0ServiceNomina: Auth0ServiceNomina,
    private indexservice: indexService,
  ) {
    this.forma = new FormGroup({
      email: new FormControl("", [Validators.minLength(5)]),
      Password: new FormControl("", [Validators.minLength(4)])
    });

    this.frmEnvioContacto = new FormGroup(
    {
      nameMsg: new FormControl("",[Validators.pattern(this.NAmepatern)]),emailMsg: new FormControl("",[Validators.pattern(this.emailpattern)]),messageMsg: new FormControl("",[Validators.minLength(10)])
    });
    this.emailRecover = new FormGroup({
      emailRecov: new FormControl("", [Validators.pattern(this.emailpattern)])
    });

    this.formaRegistro = new FormGroup({
      nombre: new FormControl("", [
        Validators.maxLength(100),
        Validators.pattern(this.unamePattern)
      ]),
      razonSocial: new FormControl(""),
      rfc: new FormControl("", [
        Validators.maxLength(40),
        Validators.minLength(2),
        Validators.pattern(
          "^[A-ZÑ&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9][A-Z,0-9][0-9A]$"
        )
      ]),
      email: new FormControl("", [
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,3}$")
      ]),
      password: new FormControl("", [Validators.minLength(7)]),
      password2: new FormControl("", [Validators.minLength(7)]),
      claveCiec: new FormControl("", [Validators.required]),
      telefono: new FormControl(""),
      direccion: new FormControl("", [Validators.minLength(10)]),
      codigoPostal: new FormControl("", [
        Validators.minLength(5),
        Validators.maxLength(5)
      ]),
      estado: new FormControl("Sonora"),
      ciudad: new FormControl(""),
      checkbox: new FormControl("", [Validators.required])
    });
  }
  initial: boolean = false;

  ngOnInit() {
    
  if(localStorage.getItem("tipo") != "undefined" ){
    if(localStorage.getItem("tipo") == "esEmpresa" && localStorage.getItem("token") != "undefined"){
      this.router.navigate(["/comprobante/consulta"]);
    }
    else if(localStorage.getItem("tipo") == "esNomina" && localStorage.getItem("token") != "undefined" ){
      this.router.navigate(["/comprobante/nomina"]);
    }
  }




    $(document).on("click", "#blockBack", function() {
      $("#frame").hide();
      $("#blockBack").hide();
    });

    $(document).ready(function() {
      $(".scrolly").click(function() {
        var iddiv = $(this).attr("dir");
        $("html,body").animate(
          {
            scrollTop: $("#" + iddiv).offset().top
          },
          1000
        );
      });

      $(".zoomImage").click(function() {
        var ImgenSelecionada = $("#carouselExampleIndicators")
          .children()
          .find("div.carousel-item.active")
          .find("img")
          .attr("src");
        $("#myModalImagenes").show();
        // modal.style.display = "block";
        $("#myModalImagenes #img01").attr("src", ImgenSelecionada);
        // captionText.innerHTML = this.alt;
      });

      $(".close2").click(function() {
        $("#myModalImagenes").hide();
      });
    });

    $(".modal").css({ top: "inherit !important;" });
  }


  ngAfterViewInit() {
    $(document).click(function(e) {
      if (
        !$("#LoginMenu").is(e.target) && // if the target of the click isn't the container...
        $("#LoginMenu").has(e.target).length === 0 
        &&  !$("#con_Nom").is(e.target) && // if the target of the click isn't the container...
        $("#con_Nom").has(e.target).length === 0 
        &&  !$("#con_Alm").is(e.target) && // if the target of the click isn't the container...
        $("#con_Alm").has(e.target).length === 0 
        &&  !$("#dropdownMenuLinks").is(e.target) && // if the target of the click isn't the container...
        $("#dropdownMenuLinks").has(e.target).length === 0 
        
      ) {
        // ... nor a descendant of the container
        $("#LoginMenu").hide();
      }
    });
  }
  ischeked:boolean=false
 

  guardar() {
    this.usuarioLogin["Usuario"] = this.forma.value["email"];
    this.usuarioLogin["Password"] = this.forma.value["Password"];
    this.isLoadingResults=true;


    this._consultasService
      .LoginValidate(
        this.usuarioLogin["Usuario"],
        this.usuarioLogin["Password"]
      )
      .subscribe(data => {
        let resp = this._Auth0Service.login(data);
        // Guardar
    
        if (resp == "esEmpresa") {
          localStorage.setItem("tipo", "esEmpresa");
          localStorage.setItem("usuario", JSON.stringify(data.empresa));
          localStorage.setItem("clavePrincipal", data.clavePrincipal );
          this._Auth0Service.isLogged = true;
          this.router.navigate(["/comprobante/consulta"]);
    
        } else if (resp == "esNomina") {
          localStorage.setItem("tipo", "esNomina");
          this._Auth0ServiceNomina.isLogged = true;
          this._Auth0ServiceNomina.login;
          if (data.loNomina.TipCve == "2" || data.loNomina.TipCve == "4") {
            this.router.navigate(["/comprobante/nomina"]);
         
          } else {
            this.router.navigate(["/comprobante/empleadonomina"]);
            
          }
        } else {
          swal("Ha ocurrido un error!", data.msg.toString(), "error");
          this.isLoadingResults=false;
        }
     
     if(data.empresa=='undefined'||data.empresa==undefined)
     {
    }
  else
{
  if (data.empresa.EMPRESAxUSUARIO[0] != null) {
    localStorage.setItem(
      "empresaXusuario",
      JSON.stringify(data.empresa.EMPRESAxUSUARIO)
    );
} }
      

      });
    error => console.error(error);
  }
  Registrar() {
 
    this.registroModelo = {
      psNombre: "",
      psRazonSocial: "",
      psRfc: "",
      psEmail: "",
      psContrasena: "",
      psContrasena2: "",
      psCiec: "",
      psTelefono: "",
      psDireccion: "",
      psPostal: "",
      psEstado: "",
      psCiudad: "",
      psIngreso: "",
      psComentario: ""
    };
    this.registroModelo.psNombre = this.formaRegistro.value["nombre"];
    this.registroModelo.psRazonSocial = this.formaRegistro.value["razonSocial"];
    this.registroModelo.psRfc = this.formaRegistro.value["rfc"];
    this.registroModelo.psEmail = this.formaRegistro.value["email"];
    this.registroModelo.psContrasena = this.formaRegistro.value["password"];
    this.registroModelo.psContrasena2 = this.formaRegistro.value["password"];
    this.registroModelo.psCiec = this.formaRegistro.value["claveCiec"];
    this.registroModelo.psTelefono = this.formaRegistro.value["telefono"];
    this.registroModelo.psDireccion = this.formaRegistro.value["direccion"];
    this.registroModelo.psPostal = this.formaRegistro.value["codigoPostal"];
    this.registroModelo.psEstado = this.formaRegistro.value["estado"];
    this.registroModelo.psCiudad = this.formaRegistro.value["ciudad"];
    this.registroModelo.psIngreso = "Ninguno";
    this.registroModelo.psComentario = "";
    this.indexservice.Registrar(this.registroModelo).subscribe(params => {
      if (params.success == true) {
        swal("Correcto", params.msg, "success");
      } else {
        swal({
          title: "Ocurrio un problema",
          text: params.msg,
          type: "error",
          confirmButtonText: "Entendido"
        });
      }
    });
  }
  closeVideo() {
    $("#frame").hide();
    $("#blockBack").hide();
  }
  recuperarPassword() {

    var email = this.emailRecover.value["emailRecov"];
    var CbEsNomAlma
    if($( "#CBAlmacenamiento" ).hasClass( "mat-radio-checked" )==true)
    {
      CbEsNomAlma=false
    }
    else
    if($( "#CBNomina" ).hasClass( "mat-radio-checked" )==true)
    {
      CbEsNomAlma=true
    }
    else{
      swal("Seleccione tipo de cuenta",'Es necesario que seleccione almacenamiento o nomina','question')
    }

    
    if (email == "") {
      swal("Error", "Ingrese un correo", "error");
    } else {
      this._consultasService.RecuperarPassword(email,CbEsNomAlma).subscribe(data => {
    
        if (
          data.msg.toString() ==
          "Se le ha enviado un correo con su contraseña, favor de ir a su bandeja de entrada para continuar."
        ) {
          swal("¡Exito!", data.msg.toString(), "success");
        } else {
          swal("Error", data.msg.toString(), "error");
        }
      });
      error => console.error(error);
    }
  }
  desplegarLogin() {
    $("#LoginMenu").show();
  }

  enviarA_Contacto()
  {
    
var name,email,mensaje

name = this.frmEnvioContacto.value['nameMsg']
email = this.frmEnvioContacto.value['emailMsg']
mensaje = this.frmEnvioContacto.value['messageMsg']
if(name==""&&email==""&&mensaje=="")
  {
    swal("Error",'No ha ingresado datos para enviar', "error");
  }
  else  {
  this.indexservice.EnviarAContacto(name,email,mensaje,"","").subscribe(data=>{
    swal("¡Perfecto!", 'Su mensaje ha sido enviado correctamente', "success");
    })
    error=> console.error(error);
  }}
  ischekedAl:boolean=false
  ischekedNom:boolean=false

  UncheckAlmacenamineto()
  {
    this.ischekedNom=true
    this.ischekedAl=false
  }
  UncheckNomina()
  {
    this.ischekedNom=false
    this.ischekedAl=true
  }
}
