import { Component, OnInit } from '@angular/core';
import { Auth0Service } from './services/auth0.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {
  tipo:boolean;
  isLoadingResults:boolean=false;
  color="warn";

  estilosViejosHref = [
    "https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css",
    // "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
    // "https://fonts.googleapis.com/css?family=Roboto+Condensed",
    // "https://fonts.googleapis.com/icon?family=Material+Icons",
    // "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
    // "assets/repoboxweb/favicons/favicon-96x96.png",
    // 'https://fonts.googleapis.com/css?family=Roboto:400,300,300italic,400italic,500,500italic,700,700italic',
    // 'https://fonts.googleapis.com/css?family=Raleway:700,400,300',
    // 'https://fonts.googleapis.com/css?family=Pacifico',
    // 'https://fonts.googleapis.com/css?family=PT+Serif',
    // "assets/repoboxweb/plugins/magnific-popup/magnific-popup.css",
    // "assets/repoboxweb/plugins/rs-plugin/css/settings.css",
    // "assets/repoboxweb/plugins/owl-carousel/owl.carousel.css",
    // "assets/repoboxweb/plugins/owl-carousel/owl.transitions.css",
    // "assets/repoboxweb/plugins/hover/hover-min.css",
    // "assets/css/offline-theme-default.css",
    // "assets/css/español.css",
    "https://www.w3schools.com/w3css/4/w3.css", 
    // "https://www.w3schools.com/w3css/4/w3.css"
  ]
  constructor( public _Auth0Service: Auth0Service) {
  this.isLoadingResults = true;
   this.validaEstilosViejos();
   this.tipo=_Auth0Service.tipoUsuario();
   
  }
  ngOnInit() {
  }


  validaEstilosViejos(){
    var pagina = window.location.pathname;
    var frame = (pagina === '/' ? 'nuevo' : 'viejo');
    var conta = this.estilosViejosHref.length;
    for (var i = 0; i < (conta + 1); i++) {
      try {
        var num = i+1;
              if ($("#viejos" + num.toString()).attr("href") !== null && $("#viejos" + num.toString()).attr("href") !== undefined)
                  if ($("#viejos" + num.toString()).attr("href") === ""/*(window.location.origin + "/")*/)
                      $("#viejos" + num.toString()).attr("href", this.estilosViejosHref[i]);
      }
      catch (error) {
        this.isLoadingResults = false;
      }
     }
     this.isLoadingResults = false;
  }
}
