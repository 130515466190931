import { Component, OnInit } from '@angular/core';
import { ConsultasService } from '../../../../../services/consultas.service';
import { SweetAlertModals } from '../../../../Clases/alert';
import { saveAs } from "file-saver";
@Component({
  selector: 'app-alta-certificado',
  templateUrl: './alta-certificado.component.html',
  styleUrls: ['./alta-certificado.component.css']
})
export class AltaCertificadoComponent implements OnInit {
  ngPasswordCSD: any;
  ngPasswordConfirmarCSD: any;
  FileKey:any=null;
  FileCer:any=null;
  alertWarning:boolean=false;
  ngAlertWarningkey:boolean=false;
  ngMensajeAlert:string
  mensajeAlert:string="";
  ngPasswordFail:boolean;
  Alerts:SweetAlertModals;
  constructor(private _consultasService: ConsultasService) { }

  ngOnInit() {
    this.Alerts= new SweetAlertModals();
  }

//========================Alta FIEL===============
public alertArchivoFormato(tipoAlert:boolean,nombreArchivo:string, tipo:string){
  if(tipo == "cer"){
  if(tipoAlert==false){
   this.alertWarning=true;
   this.mensajeAlert=nombreArchivo;
  }else{
   this.alertWarning=false;
   this.mensajeAlert=nombreArchivo;
  }
}else{
  if(tipoAlert==false){
    this.ngAlertWarningkey=true;
    this.ngMensajeAlert=nombreArchivo;
   }else{
    this.ngAlertWarningkey=false;
    this.ngMensajeAlert=nombreArchivo;
   }
}


}
public onFileSelecteCer(event){
  
    this.FileCer=<File>event.target.files[0];
    if(this.FileCer.type != "application/x-x509-ca-cert"){
      this.alertArchivoFormato(false, this.FileCer.name,"cer");
      return
    }
    this.alertArchivoFormato(true, this.FileCer.name, "cer");
}
public onFileSelectedKey(event){  
  
   this.FileKey=<File>event.target.files[0];
   let extension : string = this.FileKey.name.split('.').pop();
   if( extension != "key"){
    this.alertArchivoFormato(false, this.FileKey.name,"key");
    return
  }
  this.alertArchivoFormato(true, this.FileCer.name, "key");
}
public validacionesUpload():FormData{
  let  form = new FormData();
  if(this.ngPasswordCSD.trim() != this.ngPasswordConfirmarCSD.trim() ){
    this.Alerts.ModalWarning("Atencion","Las contraseñas no son iguales");
    this.ngPasswordFail=true
  }
  else{
  if(this.FileKey !=null && this.FileCer !=null){
    this.ngPasswordFail = false;
      form.append('FileCer', this.FileCer, this.FileCer.name)
      form.append('FileKey', this.FileKey, this.FileKey.name)
      form.append(this.ngPasswordConfirmarCSD.trim(), this.FileKey, this.ngPasswordConfirmarCSD.trim())
      form.append('Tipo', this.FileKey, 'CSD')
      return form;
    }else{
      this.Alerts.ModalWarning("Atencion","Falta subir el certificado (.cer) o clave privada (.key)");
    }   
  }
  return null;
}

public onUpload(){
  let validaciones = this.validacionesUpload()
  if(validaciones == null){
    return
  }
  console.log(validaciones);
  this._consultasService.altaCertificado(validaciones).subscribe(data=>{
    
    if(data.success == false){
      this.Alerts.ModalError("Error",data.msg );
    }else{
      this.Alerts.ModalSuccess("Correcto",data.msg + ",  Ya puedes aceptar/rechazar solicitudes");
      localStorage.setItem("certificadoCSD","true");
    }
  },error => {
    console.log(error);
    this.Alerts.ModalError("","Ocurrio un error al guardar el certificado");
  })
  
}


descargarCertificado(){
    
  let rfc: string  =  localStorage.getItem("nombreUsu");
  this._consultasService.descargarCertificado(rfc, "CSD").subscribe(data =>{     
         
     console.log(data);
      var blob = new Blob([data], { type: "application/zip" });
    saveAs(
      blob,
      "RepoBox_" + rfc + "_" + "CSD" + ".zip"
    );
    
}, error =>{
  this.Alerts.ModalWarning("Atencion","No se encontro los certificacios de este usuario");
});
}
}

//========================Alta FIEL===============

