import { Component, OnInit } from '@angular/core';
import { NominaService } from "../../services/nomina.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConsultaEmpleado } from '../../Interfaces/formulario.comprobante';
import { MatTableDataSource } from '@angular/material';
import swal from "sweetalert2";
import * as $ from "jquery";
import { Auth0Service } from "../../services/auth0.service";

@Component({
  selector: 'app-lista-empleados',
  templateUrl: './lista-empleados.component.html',
  styleUrls: ['./lista-empleados.component.css']
})
export class ListaEmpleadosComponent implements OnInit {
  isLoadingResults:boolean;
  formaEmpleado:FormGroup;
  ConsultaEmpleado:any=[];
  Empleado:any=[];
  unamePattern = "^[a-z A-Z0-9_-]{3,40}$";
  

  
  consultaEmpleado:ConsultaEmpleado ={
    Usuario: ""
  }
  displayedColumns: string[] = [
    "RFC",
    "Nombre",
    "Email",
    "Restablecer",
    "Reiniciar"
  ];
  constructor(private _nominaService:NominaService,private _Auth0Service: Auth0Service ) { 
    this.formaEmpleado= new FormGroup(
    {
      usuario: new FormControl("",[Validators.required,Validators.minLength(3),Validators.maxLength(50),Validators.pattern(this.unamePattern)])

    });
  }

  ngOnInit() {
  }

  dataSource = new MatTableDataSource<any>();

  refresh() {
    return (this.dataSource.data = this.Empleado);
  }
reiniciarUsuario(rfc)
{
  this._nominaService.ReiniciarUsuario(rfc).subscribe(data=>{
    if (data.session == 1) {
      this.CerrarSesion(data.msg);
    } else {
}
  })
  error => console.error(error);
}
  
vaidateUser:boolean;

consultaEmpleados()
{
  this.consultaEmpleado.Usuario = this.formaEmpleado.value['usuario']

  this.isLoadingResults=true;
  this._nominaService.ConsultaEmpleados(this.consultaEmpleado.Usuario).subscribe(data =>{

    if (data.session == 1) {
      this.CerrarSesion(data.msg);
    } else {
  this.Empleado= data.employe;
  this.refresh();
  this.isLoadingResults=false;
  if(data.employe.toString()=="")
  {
    swal(
      'Error!',
      'No se encontraron coincidencias',
      'error'
    )
  }
}
   })
  error=> console.error(error);

 
}



Modalreiniciar(row)
{
  swal({
    title: '¿Estas seguro?',
    text: "Desea restablecer los datos del usuario seleccionado",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText:'Cancelar',
    confirmButtonText: 'Si, reiniciar!'
  }).then((result) => {
    if (result.value) {
      this.reiniciar(row);
    }
  })
}


reiniciar(row)
{

this._nominaService.ReiniciarUsuario(this.Empleado[row].Rfc).subscribe(data=>{
  if (data.session == 1) {
    this.CerrarSesion(data.msg);
  } else {

  this.Empleado[row].Correo="";
  
  if(data.msg=="Los cambios se realizaron correctamente")
  {
    swal(
      'Perfecto!',
      'El empleado ha sido reiniciado con exito',
      'success'
    )
    
  }else
  {
    swal(
      'Error!',
      data.msg.toString(),
      'error'
    )
  }}
})
error => console.error(error);
}


Modalrestablecer(row)
{
  swal({
    title: '¿Estas seguro?',
    text: "Desea restablecer los datos del usuario seleccionado",
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText:'Cancelar',
    confirmButtonText: 'Si, reiniciar!'
  }).then((result) => {
    if (result.value) {
      this.restablecer(row);
    }
  })
}

restablecer(row)
{
  
  this._nominaService.RecuperarPassword(this.Empleado[row].Correo).subscribe(data=>{
    if (data.session == 1) {
        this.CerrarSesion(data.msg);
    } else {
  
    if(data.msg=="Se le ha enviado un correo con su contraseña, favor de ir a su bandeja de entrada para continuar.")
    {
      swal(
        'Perfecto!',
        'El empleado ha sido restablecido con exito',
        'success'
      )
    }else
    {
      swal(
        'Error!',
        data.msg.toString(),
        'error'
      )
    }}
  })
  error => console.error(error);
}
public coloRow(i){
  
  $("#tblTabla tr").removeClass("Seleccionado");
  $(i.currentTarget).addClass("Seleccionado");

}

CerrarSesion(dataMensaje:string) {
  this.WarningPerzonalizado("Atencion!",dataMensaje);
  this._Auth0Service.logout();
}
private WarningPerzonalizado(titulo, texto) {
  swal({
    title: titulo,
    text: texto,
    type: "question",
    confirmButtonText: "Entendido"
  });
}

}
