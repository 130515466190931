import { Injectable } from "@angular/core";
import { Http, Response, Headers } from "@angular/http";
import { ResponseContentType } from "@angular/http";
import { ConsultaNomina} from "../Interfaces/formulario.comprobante";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;
import "rxjs/Rx";

@Injectable()
export class NominaService {

   URLConexion: string = ConexionApisRepoBox;
  UrlReiniciarUsuario: string = this.URLConexion + "api/nomina/ReiniciarUsuario";
  UrlConsultaEmpleados: string = this.URLConexion + "api/nomina/ConsultaEmpleados/";
  UrlRecuperarPassword: string = this.URLConexion + "api/nomina/RecuperarPassword";
  UrlCambiarContrasenaNomina: string = this.URLConexion + "api/nomina/CambiarContrasenaNomina";
  UrlUpdateFechaRevision: string = this.URLConexion + "api/nomina/UpdateFechaRevision";
//--
  ConsultaNominaUrl: string = this.URLConexion + "api/nomina/consultaNomina";
  ConsultaReporteNominaUrl: string = this.URLConexion + "api/nomina/consultaReporteNomina";
  GetFileNomina: string = this.URLConexion + "api/nomina/getFilesNomina/?ids=";
  UrlConsultarEmpleados: string = this.URLConexion + "api/nomina/ConsultarEmpleados/?psNombre=";
  constructor(private http: Http) {}

  ReiniciarUsuario(RFC) {
    let url = `${this.UrlReiniciarUsuario}/?RFC=${RFC}`;
    var body = JSON.stringify(RFC);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    // var rgd = this.http
    //   .post(url, body, { headers: headerOptions })
    //   .map(x => x.json());
    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  public ConsultaEmpleados(nombre) {
    var body = JSON.stringify(nombre);
    let url = `${this.UrlConsultaEmpleados}?nombreRfc=${nombre}`;
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  RecuperarPassword(correo) {
    let url = this.UrlRecuperarPassword;
    var body = JSON.stringify(correo);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  CambiarContrasenaNomina(psContrasenaOld, psContrasenaNew, psCorreo) {
    let url = this.UrlCambiarContrasenaNomina;
    var body = JSON.stringify({ psContrasenaOld, psContrasenaNew, psCorreo });
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  UpdatePdf(id) {
    let url = this.UrlUpdateFechaRevision;
    var body = JSON.stringify(id);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }



  consultaNomina(consultaCom: ConsultaNomina) {
    var body = JSON.stringify(consultaCom);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
    return this.http
      .post(this.ConsultaNominaUrl, body, { headers: headerOptions })
      .map(x => x.json());
  }



  public DescargaZipNomina(Ficheros: string) {
    let url = `${this.GetFileNomina}${Ficheros}`;

    var headerOptions = new Headers({ "Content-Type": "arraybuffer" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .get(url, {
        headers: headerOptions,
        responseType: ResponseContentType.Blob
      })
      .map((response: Response) => response.blob());
  }


  public ConsultarEmpleados(nombre) {
    let url = `${this.UrlConsultarEmpleados}`;
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .get(url + nombre, { headers: headerOptions })
      .map(x => x.json());
  }

  consultaReporteNomina(consultaCom: ConsultaNomina) {
    var body = JSON.stringify(consultaCom);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
    return this.http
      .post(this.ConsultaReporteNominaUrl, body, { headers: headerOptions })
      .map(x => x.json());
  }




}
