import { Component, OnInit } from '@angular/core';
import { Auth0ServiceAdminService } from '../../services/auth0-service-admin.service';

import { Router } from "@angular/router";

@Component({
  selector: 'app-navbar-admin',
  templateUrl: './navbar-admin.component.html',
  styleUrls: ['./navbar-admin.component.css']
})
export class NavbarAdminComponent implements OnInit {

  constructor(private _AuthServiceAdmin: Auth0ServiceAdminService, public router: Router) { }

  ngOnInit() {
  }

  CerrarSesionNormal() {
    this._AuthServiceAdmin.logout();
    // this._Auth0ServiceNomina.logout();
    this.router.navigate([""]).then(x=>{
      location.reload();
    });
  }

}
