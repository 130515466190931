import { Injectable } from "@angular/core";
import {ConsultaComprobante, ConsultaNomina} from "../Interfaces/formulario.comprobante";
import { Http, Response, Headers } from "@angular/http";
import { ResponseContentType } from "@angular/http";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;
import "rxjs/Rx";
@Injectable()

export class buzonService {
    constructor(private http:Http){}
    
    conexionApis: any = ConexionApisRepoBox;
    ConsultaCFDIPendientes: string = this.conexionApis + "api/WS/GetCFDIPendientes";
    UrlAceptacionRechazoCFDI: string = this.conexionApis + "api/WS/AceptacionRechazoCFDI";
    

    GetCFDIPendientes(rfc:string)
    {
        var body = JSON.stringify(rfc);
        var headerOptions = new Headers({ "Content-Type": "application/json" });
        headerOptions.append("Authorization", localStorage.getItem("Token"));
        var url = this.ConsultaCFDIPendientes+"/"+rfc;
        //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
        var resp =this.http.get(url, { headers: headerOptions })
        .map(x => x.json());
        return resp;
    }
    acepatarCancelacion(modelo:object)
    {
        var body = JSON.stringify(modelo);
        var headerOptions = new Headers({ "Content-Type": "application/json" });
        headerOptions.append("Authorization", localStorage.getItem("Token"));
        //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
        return this.http.post(this.UrlAceptacionRechazoCFDI, body, { headers: headerOptions })
          .map(x => x.json());

    }
}