import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { IndexComponent } from './index/index.component';
//import { ConsultaComprobanteComponent } from './components/pages/consulta-comprobante/consulta-comprobante.component';
import { TruncatePipe } from './components/pages/pipes/truncate.pipes'
import { RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings} from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';

// servicios
import { APP_ROUTING } from './app.routes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { ConsultasService } from './components/services/consultas.service';
import { Auth0Service } from './components/services/auth0.service';
import { PagesComponent } from './components/pages.component';
import { PagoComponent } from './components/pages/pago/pago.component';
import { ConsultaFacturasComponent } from './components/pages/consulta-facturas/consulta-facturas.component';
import { DemoMaterialModule } from '../Angular Material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConsultaNominaComponent } from './components/pages/consulta-nomina/consulta-nomina.component';
import { NominaEmpleadoComponent } from './components/pages/nomina-empleado/nomina-empleado.component'; 
import { ListaEmpleadosComponent } from './components/pages/lista-empleados/lista-empleados.component'; 
import {NominaService} from './components/services/nomina.service';
import { NavbarNominaComponent } from './components/shared/navbar-nomina/navbar-nomina.component'; 
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { ConfiguracionComponent } from './components/pages/configuracion/configuracion.component';
import { Error404Component } from './components/pages/errors/error404/error404.component';
import { Error500Component } from './components/pages/errors/error500/error500.component';
import { Auth0ServiceNomina } from './components/services/auth0ServiceNomina';
import { EdicionComponent } from './components/pages/edicion/edicion.component';
import {CKEditorModule} from 'ng2-ckeditor';
import { EdicionrecepcionComponent } from './components/pages/edicionrecepcion/edicionrecepcion.component';
import { EdicioncomprobanteComponent } from './components/pages/edicioncomprobante/edicioncomprobante.component';
import { ValidacionComponent } from './components/shared/validacion/validacion.component';
import { validacionServices } from './components/services/validacion.service';
import { DomseguroPipe } from './components/pages/pipes/domseguro.pipe';
import { indexService } from './components/services/index.Service';
import { FacturacionComponent } from './components/pages/facturacion/facturacion.component';
import { SafePipe } from './components/pages/pipes/safe.pipe';
import {buzonService} from './components/services/buzon.service'

// import {
//   IMqttMessage,
//   MqttModule,
//   IMqttServiceOptions
// } from 'ngx-mqtt';
import { DownloadsFileComponent } from './components/shared/downloads-file/downloads-file.component';
import { sharedService } from './components/services/shared.service';
import { RecuperarPasswordComponent } from './components/shared/recuperar-password/recuperar-password.component';
import { BuzonCancelacionComponent } from './components/pages/buzon-cancelacion/buzon-cancelacion.component';
import { ValidarFacturaElectronicaComponent } from './index/validar-factura-electronica/validar-factura-electronica.component';
import { ResultadoValidacionCFDComponent } from './index/validar-factura-electronica/resultado-validacion-cfd/resultado-validacion-cfd.component';
import { DescargaMasivaComponent } from './components/pages/descarga-masiva/descarga-masiva.component';
import { AltaCertificadoComponent } from './components/pages/configuracion/sharedConfiguracion/certificadoCSD/alta-certificado/alta-certificado.component';
import { ConsultaCertificadoComponent } from './components/pages/configuracion/sharedConfiguracion/certificadoCSD/consulta-Certificado/consulta-certificado/consulta-certificado.component';
import { MostrarReporteComponent } from './components/pages/consulta-facturas/componentesFacturas/mostrar-reporte/mostrar-reporte.component';
import { AcuseValidacionComponent } from './components/shared/acuse-validacion/acuse-validacion.component';
import { ForoComponent } from './components/shared/foro/foro.component';
import { HelpComponent } from './components/shared/help/help.component';
import { EstrellasComponent } from './components/shared/estrellas/estrellas.component';
import { IndexNuevoComponent } from './index-nuevo/index-nuevo.component';
import { LoginAdminComponent } from './components/shared/login-admin/login-admin.component';
import { AdminComponent } from './components/pages/admin/admin.component';
import { NavbarAdminComponent } from './components/shared/navbar-admin/navbar-admin.component';
import { BovedaFiscalComponent } from './components/shared/boveda-fiscal/boveda-fiscal.component';
import { AdminModule } from './components/pages/admin/admin.module';
import { CancelarSuscripcionComponent } from './components/shared/cancelarSuscripcion/cancelar-suscripcion/cancelar-suscripcion.component';
import { DialogInfoIndexComponent } from './components/shared/dialog-info-index/dialog-info-index.component';
import { IrcAcuseValidacionComponent } from './components/shared/irc-acuse-validacion/acuse-validacion.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { IndexIRCComponent } from './indexIRC/index-irc.component';
import { IrcValidacionComponent } from './components/shared/irc-validacion/irc-validacion.component';
import { ConsultaReportesComponent } from './components/pages/consulta-reportes/consulta-reportes.component';
import { VerCFDIComponent } from './components/shared/ver-cfdi/ver-cfdi.component';
import { AltaFielComponent } from './components/pages/configuracion/sharedConfiguracion/alta-fiel/alta-fiel.component';
import { ConsultaFielComponent } from './components/pages/configuracion/sharedConfiguracion/consulta-fiel/consulta-fiel.component';

//==================================
//Servicio de notificaciones
//==================================
// export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
//   hostname: 'ec2-52-90-200-108.compute-1.amazonaws.com',
//   protocol:"ws",
//   clientId:(Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase()



// };

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent, 
    IndexComponent,
   // ConsultaComprobanteComponent,
    PagesComponent,
    PagoComponent,
    ConsultaNominaComponent,
    ConsultaFacturasComponent,
    TruncatePipe,
    NavbarNominaComponent,
    NominaEmpleadoComponent,
    Error404Component,
    Error500Component,
    ListaEmpleadosComponent,
    ConfiguracionComponent,
    EdicionComponent,
    EdicionrecepcionComponent,
    EdicioncomprobanteComponent,
    ValidacionComponent,
    DomseguroPipe,
    FacturacionComponent,
    SafePipe,
    DownloadsFileComponent,
    RecuperarPasswordComponent,
    BuzonCancelacionComponent,
    ValidarFacturaElectronicaComponent,
    ResultadoValidacionCFDComponent,
    DescargaMasivaComponent,
    AltaCertificadoComponent,
    ConsultaCertificadoComponent,
    MostrarReporteComponent,
    AcuseValidacionComponent,
    ForoComponent,
    HelpComponent,
    EstrellasComponent,
    IndexNuevoComponent,
    LoginAdminComponent,
    AdminComponent,
    NavbarAdminComponent,
    BovedaFiscalComponent,
    CancelarSuscripcionComponent,
    DialogInfoIndexComponent,
    IrcAcuseValidacionComponent,
    IndexIRCComponent,
    IrcValidacionComponent,
    ConsultaReportesComponent,
    VerCFDIComponent,
    AltaFielComponent,
    ConsultaFielComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    APP_ROUTING,
    HttpModule,
    DemoMaterialModule,
    ShowHidePasswordModule.forRoot(),
    CKEditorModule,
    MatTooltipModule,
  //  MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
  
  providers: [
    ConsultasService,
    Auth0Service,
    NominaService,
    Auth0ServiceNomina,
    validacionServices,
    indexService,
    sharedService,
    buzonService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DialogInfoIndexComponent
  ]
})
export class AppModule { }
