import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { Auth0Service } from "../components/services/auth0.service";
import {
  Usuario
} from "../components/Interfaces/formulario.comprobante";
import { ConsultasService } from "../components/services/consultas.service";
import { FormGroup, FormControl, Validators, Form } from "@angular/forms";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Auth0ServiceNomina } from "../components/services/auth0ServiceNomina";

import { indexService } from "../components/services/index.Service";
import { RegistroModelo } from "../components/Interfaces/formulario.comprobante";
import { DialogInfoIndexComponent } from '../components/shared/dialog-info-index/dialog-info-index.component';
import { MatDialog } from '@angular/material';
import { GlobalsService } from '../components/services/globalService.service';

declare var $: any;
@Component({
  selector: 'app-index-nuevo',
  templateUrl: './index-nuevo.component.html',
  styleUrls: ['./index-nuevo.component.css']
})
export class IndexNuevoComponent implements OnInit {
  hide = true;
  hide2 = true;
  hide3 = true;
  valorCheck: string = "";
  forma: FormGroup;
  formaNomina: FormGroup;
  emailRecover: FormGroup;
  emailpattern = "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,3}$";
  usuarioLogin: Usuario = {
    usuario: null,
    password: null
  };

  claseValida: string = "form-control";
  claseInValida: string = "form-control";

  formaRegistro: FormGroup;
  frmEnvioContacto: FormGroup;
  user: string;
  pass: string;
  unamePattern = "^[a-zA-Z0-9_-]{1,100}$";
  registroModelo: RegistroModelo;
  isLoadingResults: boolean = false;
  color = "warn";
  boolEnviar: boolean = false;
  btnEnviar: string = "Enviar";
  NAmepatern = "^[a-zA-Z ]{1,40}$"
  receptorPattern = "^[a-z A-Z0-9ñÑ,._-]{1,100}$";
  @ViewChild('modalEntrar') private modalEntrar: ElementRef;
  @ViewChild('modalEntrarNomina') private modalEntrarNomina: ElementRef;


  estilosViejosHref = [
    "https://stackpath.bootstrapcdn.com/bootstrap/4.1.0/css/bootstrap.min.css",
    // "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css",
    // "https://fonts.googleapis.com/css?family=Roboto+Condensed",
    // "https://fonts.googleapis.com/icon?family=Material+Icons",
    // "https://use.fontawesome.com/releases/v5.1.0/css/all.css",
    // "assets/repoboxweb/favicons/favicon-96x96.png",
    // 'https://fonts.googleapis.com/css?family=Roboto:400,300,300italic,400italic,500,500italic,700,700italic',
    // 'https://fonts.googleapis.com/css?family=Raleway:700,400,300',
    // 'https://fonts.googleapis.com/css?family=Pacifico',
    // 'https://fonts.googleapis.com/css?family=PT+Serif',
    // "assets/repoboxweb/plugins/magnific-popup/magnific-popup.css",
    // "assets/repoboxweb/plugins/rs-plugin/css/settings.css",
    // "assets/repoboxweb/plugins/owl-carousel/owl.carousel.css",
    // "assets/repoboxweb/plugins/owl-carousel/owl.transitions.css",
    // "assets/repoboxweb/plugins/hover/hover-min.css",
    // "assets/css/offline-theme-default.css",
    // "assets/css/español.css",
    "https://www.w3schools.com/w3css/4/w3.css"
  ]


  constructor(
    private _consultasService: ConsultasService,
    private router: Router,
    private _Auth0Service: Auth0Service,
    private _Auth0ServiceNomina: Auth0ServiceNomina,
    private indexservice: indexService,
    public _globalService: GlobalsService
  ) {
    this._globalService.usuarioIRC = false;
    this.validaEstilos();
    //Login
    this.forma = new FormGroup({
      email: new FormControl("", [Validators.minLength(5)]),
      Password: new FormControl("", [Validators.minLength(4)])
    });

    //Login Nomina
    this.formaNomina = new FormGroup({
      emailNom: new FormControl("", [Validators.minLength(5)]),
      PasswordNom: new FormControl("", [Validators.minLength(4)])
    });

    //Envio Contacto
    this.frmEnvioContacto = new FormGroup(
      {
        nameMsg: new FormControl("", [Validators.required, Validators.pattern(this.NAmepatern)]),
        empresa: new FormControl("", []),
        telefono: new FormControl("", [Validators.required, Validators.pattern("[0-9]{1,12}$")]),
        emailMsg: new FormControl("", [Validators.required, Validators.pattern(this.emailpattern)]),
        messageMsg: new FormControl("", [Validators.required, Validators.minLength(10)])
      });

    //Restablecer Contraseña
    this.emailRecover = new FormGroup({
      emailRecov: new FormControl("", [Validators.pattern(this.emailpattern)])
    });

    //Registro de usuario
    this.formaRegistro = new FormGroup({
      nombre: new FormControl("", [
        Validators.maxLength(100),
        Validators.required
        // Validators.pattern(this.unamePattern)
      ]),
      razonSocial: new FormControl("", [Validators.required]),
      rfc: new FormControl("", [
        Validators.maxLength(40),
        Validators.minLength(2),
        Validators.pattern(
          "^[A-ZÑ&]{3,4}[0-9]{2}[0-1][0-9][0-3][0-9][A-Z,0-9][A-Z,0-9][0-9A]$"
        )
      ]),
      email: new FormControl("", [
        Validators.pattern("[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-z]{2,3}$")
      ]),
      password: new FormControl("", [Validators.minLength(7)]),
      password2: new FormControl("", [Validators.minLength(7)]),
      claveCiec: new FormControl("", [Validators.required]),
      telefono: new FormControl(""),
      direccion: new FormControl("", [Validators.minLength(10)]),
      codigoPostal: new FormControl("", [
        Validators.minLength(5),
        Validators.maxLength(5)
      ]),
      estado: new FormControl("Sonora"),
      ciudad: new FormControl(""),
      checkbox: new FormControl("", [Validators.required])
    });
  }
  initial: boolean = false;
  ngOnInit() {

    if (localStorage.getItem("tipo") != "undefined") {
      if (localStorage.getItem("tipo") == "esEmpresa" && localStorage.getItem("token") != "undefined") {
        this.router.navigate(["/comprobante/consulta"]);
      }
      else if (localStorage.getItem("tipo") == "esNomina" && localStorage.getItem("token") != "undefined") {
        this.router.navigate(["/comprobante/nomina"]);
      }
    }




    $(document).on("click", "#blockBack", function () {
      $("#frame").hide();
      $("#blockBack").hide();
    });

    $(document).ready(function () {
      $(".scrolly").click(function () {
        var iddiv = $(this).attr("dir");
        $("html,body").animate(
          {
            scrollTop: $("#" + iddiv).offset().top
          },
          1000
        );
      });

      $(".zoomImage").click(function () {
        var ImgenSelecionada = $("#carouselExampleIndicators")
          .children()
          .find("div.carousel-item.active")
          .find("img")
          .attr("src");
        $("#myModalImagenes").show();
        // modal.style.display = "block";
        $("#myModalImagenes #img01").attr("src", ImgenSelecionada);
        // captionText.innerHTML = this.alt;
      });

      $(".close2").click(function () {
        $("#myModalImagenes").hide();
      });

    });

    $(".modal").css({ top: "inherit !important;" });
  }

  ngAfterViewInit() {
    $(document).click(function (e) {
      if (
        !$("#LoginMenu").is(e.target) && // if the target of the click isn't the container...
        $("#LoginMenu").has(e.target).length === 0
        && !$("#con_Nom").is(e.target) && // if the target of the click isn't the container...
        $("#con_Nom").has(e.target).length === 0
        && !$("#con_Alm").is(e.target) && // if the target of the click isn't the container...
        $("#con_Alm").has(e.target).length === 0
        && !$("#dropdownMenuLinks").is(e.target) && // if the target of the click isn't the container...
        $("#dropdownMenuLinks").has(e.target).length === 0

      ) {
        // ... nor a descendant of the container
        $("#LoginMenu").hide();
      }
    });



    $(document).ready(function () {
// debugger
     
    function verificarImagen(url, callback) {
      var img = new Image();
      img.onload = function() {
        callback(true); // La imagen existe
      };
      img.onerror = function() {
        callback(false); // La imagen no existe
      };
      img.src = url;
    }
    
    // Uso del método
    var enlaceImagen = "https://repobox.com.mx/assets/images/Comunicado.png";
    verificarImagen(enlaceImagen, function(existe) {
      if (existe) {
        $("#elClick").click();
      } else {
         console.log("La imagen no existe o no se pudo cargar.");
      }
    
    });


      // let mostrar = localStorage.getItem("anuncio1");
      // if (mostrar !== undefined)
      //   if (!mostrar)
      //    $("#elClick").click(); // #Descomentar esto para que se vea el "anuncio"





      $("#modalregistro").on('hidden.bs.modal', function () {
        // $("html").css('overflow', 'auto');
        $("html").removeAttr("style");
      });
    });

  }

  ischeked: boolean = false
  validaEstilos() {
    var pagina = window.location.pathname;
    var frame = (pagina === '/' ? 'nuevo' : 'viejo');
    var conta = this.estilosViejosHref.length;
    for (var i = 0; i < (conta + 1); i++) {
      try {
        var nume = i + 1;
        if (frame === "viejo") {
          if ($("#viejos" + nume.toString()).attr("href") !== null && $("#viejos" + nume.toString()).attr("href") !== undefined)
            if ($("#viejos" + nume.toString()).attr("href") === ""/*(window.location.origin + "/")*/)
              $("#viejos" + nume.toString()).attr("href", this.estilosViejosHref[i]);
        }
        else {

          if ($("#viejos" + i.toString()).attr("href") !== null && $("#viejos" + i.toString()).attr("href") !== undefined)
            if ($("#viejos" + i.toString()).attr("href") !== "")
              $("#viejos" + i.toString()).attr("href", "");

        }
      }
      catch (error) {

      }
    }
  }


  guardar() {
    $("#modalentrar").hide();
    this.isLoadingResults = true;
    this.usuarioLogin["Usuario"] = this.forma.value["email"];
    this.usuarioLogin["Password"] = this.forma.value["Password"];
    this.isLoadingResults = true;
    if (this.usuarioLogin["Usuario"].toUpperCase() === "IIFE.NACIONAL") {
      swal("Atención", "El usuario que esta intentando acceder no existe", "warning");
      this.modalEntrar.nativeElement.click();
      this.isLoadingResults = false;
    } else {
      this._consultasService
        .LoginValidate(
          this.usuarioLogin["Usuario"],
          this.usuarioLogin["Password"]
        )
        .subscribe(data => {
          let resp = this._Auth0Service.login(data);
          // Guardar

          if (resp == "esEmpresa") {

            localStorage.setItem("tipo", "esEmpresa");
            localStorage.setItem("usuario", JSON.stringify(data.empresa));
            localStorage.setItem("clavePrincipal", data.clavePrincipal);
            if (data.sinCorreo != undefined) {
              localStorage.setItem("sinCorreo", data.sinCorreo);
            } else {
              localStorage.setItem("sinCorreo", "false");
            }
            if (data.prueba != undefined) {
              localStorage.setItem("prueba", data.prueba);
            } else {
              localStorage.setItem("prueba", "false");
            }
            if (data.mensajeToolbar != undefined) {
              this._globalService.mensajeToolbar = data.mensajeToolbar;
              this._globalService.loginIndex = true;
              localStorage.setItem("mensajeToolbar", JSON.stringify(data.mensajeToolbar));
              localStorage.setItem("loginIndex", JSON.stringify(true));
              localStorage.setItem("fechaVencimiento", data.empresa.Vencimiento);
            } else {
              localStorage.setItem("mensajeToolbar", JSON.stringify(false));
              localStorage.setItem("loginIndex", JSON.stringify(false));
            }
            this._globalService.usuarioIRC = false;
            this._Auth0Service.isLogged = true;
            this.modalEntrar.nativeElement.click();
            // this._globalService.usuarioIRC = true;
            localStorage.setItem('usuarioIRC', "false");
            this.router.navigate(["/comprobante/consulta"]);
            this.isLoadingResults = false;

          }

          else {
            this.modalEntrar.nativeElement.click();
            if (data.msg.toString() === "el usuario que esta intentando acceder no existe") {
              swal("Atención", "El usuario o password es incorrecto", "warning");
              this.isLoadingResults = false;
            } else {
              swal("Ha ocurrido un error!", data.msg.toString(), "error");
              this.isLoadingResults = false;
            }

          }

          if (data.empresa == 'undefined' || data.empresa == undefined) {
          }
          else {
            if (data.empresa.EMPRESAxUSUARIO[0] != null) {
              localStorage.setItem(
                "empresaXusuario",
                JSON.stringify(data.empresa.EMPRESAxUSUARIO)
              );
            }
          }

        }, error => {
          console.error(error)
        });
      error => {
        console.error(error)
      }
    }

  }

  guardarNomina() {
    $("#modalentrarNomina").hide();
    this.isLoadingResults = true;
    this.usuarioLogin["Usuario"] = this.formaNomina.value["emailNom"];
    this.usuarioLogin["Password"] = this.formaNomina.value["PasswordNom"];


    this._consultasService
      .LoginValidateNomina(
        this.usuarioLogin["Usuario"],
        this.usuarioLogin["Password"]
      )
      .subscribe(data => {
        let resp = this._Auth0Service.login(data);

        if (resp == "esNomina") {
          localStorage.setItem("tipo", "esNomina");
          this._Auth0ServiceNomina.isLogged = true;
          this._Auth0ServiceNomina.login;
          localStorage.setItem('usuarioIRC', "false");
          if (data.loNomina.TipCve == "2" || data.loNomina.TipCve == "4") {
            this.modalEntrarNomina.nativeElement.click();
            this.router.navigate(["/comprobante/nomina"]);
            this.isLoadingResults = false;
          } else {
            this.modalEntrarNomina.nativeElement.click();
            this.router.navigate(["/comprobante/empleadonomina"]);
            this.isLoadingResults = false;

          }
        } else {
          this.modalEntrarNomina.nativeElement.click();
          if (data.msg.toString() === "el usuario que esta intentando acceder no existe") {
            swal("Atención", "El usuario o password es incorrecto", "warning");
            this.isLoadingResults = false;
          } else {
            swal("Ha ocurrido un error!", data.msg.toString(), "error");
            this.isLoadingResults = false;
          }

        }

        if (data.empresa == 'undefined' || data.empresa == undefined) {
        }
        else {
          if (data.empresa.EMPRESAxUSUARIO[0] != null) {
            localStorage.setItem(
              "empresaXusuario",
              JSON.stringify(data.empresa.EMPRESAxUSUARIO));
          }
        }
      });
    error => console.error(error);
  }

  Registrar() {
    this.registroModelo = {
      psNombre: "",
      psRazonSocial: "",
      psRfc: "",
      psEmail: "",
      psContrasena: "",
      psContrasena2: "",
      psCiec: "",
      psTelefono: "",
      psDireccion: "",
      psPostal: "",
      psEstado: "",
      psCiudad: "",
      psIngreso: "",
      psComentario: ""
    };
    if (this.formaRegistro.valid) {
      this.registroModelo.psNombre = this.formaRegistro.value["nombre"];
      this.registroModelo.psRazonSocial = this.formaRegistro.value["razonSocial"];
      this.registroModelo.psRfc = this.formaRegistro.value["rfc"];
      this.registroModelo.psEmail = this.formaRegistro.value["email"];
      this.registroModelo.psContrasena = this.formaRegistro.value["password"];
      this.registroModelo.psContrasena2 = this.formaRegistro.value["password"];
      this.registroModelo.psCiec = this.formaRegistro.value["claveCiec"];
      this.registroModelo.psTelefono = this.formaRegistro.value["telefono"];
      this.registroModelo.psDireccion = this.formaRegistro.value["direccion"];
      this.registroModelo.psPostal = this.formaRegistro.value["codigoPostal"];
      this.registroModelo.psEstado = this.formaRegistro.value["estado"];
      this.registroModelo.psCiudad = this.formaRegistro.value["ciudad"];
      this.registroModelo.psIngreso = "Ninguno";
      this.registroModelo.psComentario = "";
      this.isLoadingResults = true;
      this.indexservice.Registrar(this.registroModelo).subscribe(params => {
        this.isLoadingResults = false;
        if (params.success == true) {
          swal("Correcto", params.msg, "success");
        } else {
          swal({
            title: "Ocurrio un problema",
            text: params.msg,
            type: "error",
            confirmButtonText: "Entendido"

          });
        }
      }, error => {
        this.isLoadingResults = false;
        swal({
          title: "Ocurrio un problema",
          text: "",
          type: "error",
          confirmButtonText: "Entendido"

        });
      });
    } else {
      this.marcarErrorformulario();
      swal({
        title: "Alerta",
        text: "Formulario invalido",
        type: "warning",
        confirmButtonText: "Entendido"

      });
    }
  }

  marcarErrorformulario() {
    this.claseInValida = "form-control is-invalid";
  }

  closeVideo() {
    $("#frame").hide();
    $("#blockBack").hide();
  }
  recuperarPassword() {

    var email = this.emailRecover.value["emailRecov"];
    var CbEsNomAlma
    if ($("#CBAlmacenamiento").hasClass("mat-radio-checked") == true) {
      CbEsNomAlma = false
    }
    else
      if ($("#CBNomina").hasClass("mat-radio-checked") == true) {
        CbEsNomAlma = true
      }
      else {
        swal("Seleccione tipo de cuenta", 'Es necesario que seleccione almacenamiento o nomina', 'question')
      }


    if (email == "") {
      swal("Atención", "Ingrese un correo", "warning");
    } else {
      this._consultasService.RecuperarPassword(email, CbEsNomAlma).subscribe(data => {

        if (
          data.msg.toString() ==
          "Se le ha enviado un correo con su contraseña, favor de ir a su bandeja de entrada para continuar."
        ) {
          swal("¡Exito!", data.msg.toString(), "success");
        } else {
          swal("Error", data.msg.toString(), "error");
        }
      });
      error => console.error(error);
    }
  }
  desplegarLogin() {
    $("#LoginMenu").show();
  }

  enviarA_Contacto() {
    this.boolEnviar = true;
    this.btnEnviar = "Enviando..."

    var name, email, mensaje, empresa, telefono;

    name = this.frmEnvioContacto.value['nameMsg']
    email = this.frmEnvioContacto.value['emailMsg']
    mensaje = this.frmEnvioContacto.value['messageMsg']
    empresa = this.frmEnvioContacto.value['empresa']
    telefono = this.frmEnvioContacto.value['telefono']
    if (name == "" || name == null && email == "" || email == null && mensaje == "" || mensaje == null && empresa == "" || empresa == null && telefono == "" || telefono == null) {
      swal("Atención", 'No ha ingresado datos para enviar', "warning");
      this.btnEnviar = "Enviar";
      this.boolEnviar = false;
    }
    else {
      this.indexservice.EnviarAContacto(name, email, mensaje, empresa, telefono).subscribe(data => {
        swal("¡Perfecto!", 'Su mensaje ha sido enviado correctamente', "success");
        this.btnEnviar = "Enviar";
        this.boolEnviar = false;
      })
      error => {
        console.error(error)
        this.btnEnviar = "Enviar";
        this.boolEnviar = false;
      };
    }
  }
  ischekedAl: boolean = false
  ischekedNom: boolean = false

  UncheckAlmacenamineto() {
    this.ischekedNom = true
    this.ischekedAl = false
  }
  UncheckNomina() {
    this.ischekedNom = false
    this.ischekedAl = true
  }
  cerrar() {
    // alert($('#cbxMostrar').val());
    if ($('#cbxMostrar').is(":checked")) {
      localStorage.setItem("anuncio1", $('#cbxMostrar').is(":checked"));
    }
    $("#elClick").click();
  }
  gotoRegistro() {
    $("html").css('overflow', 'hidden');
  }

  gotoNominas() {
    $("#modalEleccion").modal('hide');
    $("#modalentrarNomina").modal('show');
  }

}
