import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, MatChipInputEvent } from "@angular/material";
import { MatTableDataSource } from "@angular/material";
import { ConsultasService } from "../../services/consultas.service";
import { ConsultaComprobante, ModelDescargaMasiva } from "../../Interfaces/formulario.comprobante";
import { Auth0Service } from "../../services/auth0.service";
import { Router } from "@angular/router";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "file-saver";
//declare var require: any;
var LigaVerDetalleValidacion: any = require('../../services/Conexion').LigaVerDetalleValidacion;
import * as $ from "jquery";
import { CustomValidators } from "ng4-validators";
import { Observable } from "rxjs";
import swal from "sweetalert2";
import { MatSnackBar } from "@angular/material";
import { Servicios } from "../../Interfaces/ClsEmpresaPermisos";
import { ValidarPermisos } from "./ClasesFacturas/Permisos";
//import { MqttService, IMqttMessage } from "ngx-mqtt";
import { SweetAlertModals } from '../Clases/alert';
import { element } from 'protractor';
import { GlobalsService } from '../../services/globalService.service';
declare var $: any;

export interface Fruit {
  name: string;
}
export interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: "app-consulta-facturas",
  templateUrl: "./consulta-facturas.component.html",
  styleUrls: ["./consulta-facturas.component.css"
  ]
})
//================Indice=======================
//Nombre de metodos con peticiones al servidor
//=============================================
//enviarConsulta -- es la consulta principal de la vista
//DescargarMultiCheckBox --descarga multiple de archivos zip en comprobantes
//DescargarMultiCheckBoxComplemento --descarga multiple de archivos zip en complemento de pago
//GetIdRFC --Obtiene complemento de pago-- actualizado AHORA EL OBJECTO RESIVE FACTURAS Y COMPLEMENTOS DE PAGO
//Pagada -- Sirve para marcar como pagada una factura
//VerificacionDeComprobantePagos -- verifica los complementos de pago para cambiar su estatus y reprocesarlo
//busquedaReceptorTodas--
//mostrarProvedores -- Sirve para mostrar todos los provedores al darle click al check box - Daniel Vega
//modalBusquedaProvedores--Sirve para filtrar los provedores al escribir en el input de busqueda provedores- Daniel Vega
//BusquedaProvedores --filtra los provedores al escribir en el input de busqueda provedores- Daniel Vega
//enviarArchivos --Envia archivos por correo electronico
//DescargarAcuseCancelacion
export class ConsultaFacturasComponent implements OnInit {
  comprobante: any[] = [];
  alertSweet: SweetAlertModals;
  displayedColumns: string[] = [
    "RFC",
    "Cliente",
    "Serie",
    "Folio",
    "Fecha",
    "Subtotal",
    "IVA",
    "Total",
    "Tipo",
    "SAT",
    "Val",
    "Pag",
    "COM",
    "VALIDACION",
    "ZIP",
    "select"
  ];
  selection = new SelectionModel<any>(true, []);
  isLoadingResults = false;
  menuTableFact: boolean = false;
  idContextMenu: number = 0;
  indiceContextMenu: number = 0;
  bloqueoDeCargarMas: boolean = false;

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.recalcularFiltroTabla();
  }
  varC: number = 1;
  permisos: Servicios[]; // variable de tipo permisos
  PermisoInput = "";
  fechaAcuseValidacion: string;
  esPago: string;
  bloqueoDepagos: string = "NO";
  estadoPeticion: any = []

  //==================================
  //ON INITI Y AFETER VIEW INIT
  //==================================
  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll($event) {

  //   this.activarScroll()
  // }
  ngOnInit() {
    //aparece el btn de subir cuado el scroll se baja

    //si el input documento esta seleccionado REP el input pagado es bloqueado
    $("#selecDoc").change(function () {
      if ($(this).val() == 7) {
        $("#InputPagado").prop("disabled", true);
        $("#InputPagado").val(-1);
      } else {
        $("#InputPagado").prop("disabled", false);
      }
    });
  }

  ngAfterViewInit() {
    //==================================
    //JQUERY para controlar eventos del modal varios
    //==================================
    $(document).mouseup(function (e) {
      if (
        !$("#selectVariosProv").is(e.target) && // if the target of the click isn't the container...
        $("#selectVariosProv").has(e.target).length === 0 &&
        $("#selectVariosProv").val() != ""
      ) {
        // ... nor a descendant of the container
        if ($("#selectVariosProv").val() == "") {
          $("#selectVariosProv").val("");
        }
      }
      if ($("#modalVarios").is(":visible")) {
        if (
          !$("#ModalVariosContent").is(e.target) &&
          $("#ModalVariosContent").has(e.target).length === 0 &&
          $("#modalVarios .modal-backdrop, .fade, .show").is(e.target) &&
          $("#modalVarios .modal-backdrop, .fade, .show").has(e.target)
            .length === 0
        ) {
          $("#checkVarios")
            .removeClass("mat-checkbox-checked")
            .addClass("mat-checkbox-unchecked");

          $("#checkVarios-input").click();
          $("#selectVariosProv").val("")
          $("#selectVariosProv").prop("disabled", false);
        }
      }
    });

    $(document).on("click", ".close", function () {
      this.LIGAFINAL = "";
    });
    $(document).on("click", ".modal-backdrop, .fade, .show", function () {
      this.LIGAFINAL = "";
    });

    //fin de la recuperacion
    $("document").ready(function () {
      $("#checkTodos").change(function () {
        $("input:checkbox").prop("checked", $(this).prop("checked,false"));
      });

      // cuando hagamos click, el menú desaparecerá
      $(document).click(function (e) {
        if (e.button == 0) {
          $("#selectProvedoress").css("display", "none");
        }
      });
      $(document).click(function (e) {
        if (e.button == 0) {
          $("#menucontext").css("display", "none");
        }
      });

      //si pulsamos escape, el menú desaparecerá
      $(document).keydown(function (e) {
        if (e.keyCode == 27) {
          $("#selectProvedoress").css("display", "none");
        }
      });
    });

    $("document").ready(function () {
      $("#checkTodos2").change(function () {
        $("input:checkbox").prop("checked", $(this).prop("checked"));
      });
    });
  }

  public activarScroll() {
    // if (
    //   document.body.scrollTop > 20 ||
    //   document.documentElement.scrollTop > 20
    // ) {
    //   document.getElementById("myBtn").style.display = "block";
    // } else {
    //   document.getElementById("myBtn").style.display = "none";
    // }
  }

  public mostrarValidacion(e, id: string, elemento: any) {
    if (elemento.TipoPDF == 4) {

    } else {
      e.preventDefault();
      this.coloRow(e);
      this.menuTableFact = true;

      let columna = $(e.target).parents("tr")[0].id;
      let fecha = $(e.target).parents("tr")[0].title;
      let pago = $(e.target).parents("tr")[0].children[8].innerText;
      let sabe = $(e.target).parents("tr")[0].children;

      this.esPago = (pago == "REP" ? "1" : "0");
      fecha = fecha.split("\n");
      this.fechaAcuseValidacion = fecha[0]
        .replace("Fecha de entrada: ", "")
        .replace("/", "_")
        .replace("/", "_");
      columna = columna.split(",");
      this.idContextMenu = columna[1];

      this.indiceContextMenu = columna[0];

      $("#menucontext").css("display", "block");
      $("#menuTablafact").css({ display: "block", left: e.pageX, top: e.pageY });

    }

  }

  public descargarValidacion() {
    // if(this._globalService.usuarioIRC){
    //   // let ligaVerValidacionIRC = "http://localhost:4200/IRCvalidacion/";
    //   let ligaVerValidacionIRC = "https://repobox.com.mx/IRCvalidacion/";
    //   this.LIGAFINAL = `${ligaVerValidacionIRC}${this.idContextMenu}/${this.fechaAcuseValidacion}/${this.esPago}`;
    //   this.menuTableFact = false;
    //   $("#modalXCOM").modal("show");

    // }else{
    //   this.LIGAFINAL = `${LigaVerDetalleValidacion}${this.idContextMenu}/${this.fechaAcuseValidacion}/${this.esPago}`;
    //   this.menuTableFact = false;
    //   $("#modalXCOM").modal("show");
    // }
    if (this._globalService.usuarioIRC) {
      // let ligaVerValidacionIRC = "http://localhost:4200/IRCvalidacion/";
      let ligaVerValidacionIRC = "https://repobox.com.mx/IRCvalidacion/";
      this.LIGAFINAL = `${ligaVerValidacionIRC}?id=${this.idContextMenu}&fecha=${this.fechaAcuseValidacion.toString().replace(/_/g, '/')}&pago=${this.esPago}`;
      this.menuTableFact = false;
      $("#modalXCOM").modal("show");

    } else {
      this.LIGAFINAL = `${LigaVerDetalleValidacion}?id=${this.idContextMenu}&fecha=${this.fechaAcuseValidacion.toString().replace(/_/g, '/')}&pago=${this.esPago}`;
      this.menuTableFact = false;
      $("#modalXCOM").modal("show");
    } module.exports.ConexionApisRepobox = "https://54.187.107.30:8989/"
  }

  public detalleMenuContext() {
    this.GetIdRFC(this.indiceContextMenu, "");
  }

  subscription: any;
  private WargningInferiorPermanente(
    message: string,
    action: string,
    numeroFacturas: number
  ) {
    //NOMBRE REAL SNACKBAR
    this.barra = 0;
    let intervalo = (numeroFacturas / 100) * 1000;
    var observable = Observable.interval(intervalo);
    this.subscription = observable.subscribe(x => {
      this.barra = x;
    });

    this.snackBar.open(message, action, {
      // duration: 2000,
    });
  }
  dataSource = new MatTableDataSource<any>();

  refresh() {
    return (this.dataSource.data = this.comprobante);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }
  getTotalCost() {
    return 25;
  }
  //==================================
  //variables
  //==================================
  barra: number;
  seConsulto: boolean = false;
  //checkBoxAngularMaterial:boolean=false;
  cantidaFacturas: number = 0;
  Subtotal: any = 0;
  IVA: any = 0;
  Total: any = 0;
  unamePattern = "^[a-zA-Z0-9_-]{1,40}$";
  receptorPattern = "^[a-z A-Z0-9ñÑ,._-]{1,100}$";
  emailpattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";
  inputValue: any = [];
  emailvalue: FormGroup;
  Valor: number;
  nombreUsuario: string;
  nombreUsuario2: string;
  Pagado: any = 0;
  elbuli: boolean = false;
  usuarioPrueba: boolean = false;
  inicial: string = this.getDate1();
  final: string = this.getDate2();
  Saldo: any = 0;
  comprobanteIndividual: any;
  ComplementoDePago: any = [];
  StatusComprobante: any = 0;
  TotalComprobante: any = 0;
  TotalComplemento: any = 0;
  NotaCredito: any = 0;
  usuarioSeleccionado: any;
  UsuarioSelecArray: any = [];
  IndexID: number;
  MensajeDelModal: string = "";
  //Busqueda provedor cliente
  resultadoProvCliany: any[] = [0];
  SeleccionadoProCliany: any[] = [];
  BusquedaProCliany: any[] = [];
  //------------------
  tipoComprobante: number = 0;
  ocultarBotones: boolean = false;
  comprobanteTemporal: any[] = [];
  comprobanteFinal: any[] = [];
  forma: FormGroup;
  consultaComprobante: ConsultaComprobante = {
    tipocomprobante: 0,
    initialDateTime: null,
    finalDateTime: null,
    documento: -1,
    pagado: -1,
    receptor: "0",
    index: 1,
    Validacion: -1,
    Status: -1,
    Articulo: "",
    sucursal: -1,
    Folio: "",
    Serie: "",
    Todos: "",
    Irc: false,
    tipoReporte: -1,
    proveedor: ""
  };
  permisosUsuarios: ValidarPermisos;
  //==================================
  //constructor
  //==================================
  constructor(
    private _consultasService: ConsultasService,
    private router: Router,
    public sanitizer: DomSanitizer,
    public _Auth0Service: Auth0Service,
    public snackBar: MatSnackBar,
    public _globalService: GlobalsService
    //private _mqttService: MqttService
  ) {
    // _globalService.consultarUsuarioIRC();
    this.permisosUsuarios = new ValidarPermisos();
    let usuarioEmpresa = JSON.parse(localStorage.getItem("usuario"));
    let EmpresaXusuario = JSON.parse(localStorage.getItem("empresaXusuario"));
    this.permisosUsuarios.consultarPermisos(usuarioEmpresa.Servicios);

    let usuPrueba = JSON.parse(localStorage.getItem("prueba"));
    this.usuarioPrueba = usuPrueba;

    this.nombreUsuario = this._Auth0Service.usuarioNamePagina();
    this.nombreUsuario2 = this._Auth0Service.usuarioNamePagina2();
    this.forma = new FormGroup({
      fechaInicial: new FormControl(
        this.inicial,
        CustomValidators.minDate("2005-01-01")
      ),
      fechaFinal: new FormControl(
        this.final,
        CustomValidators.minDate("2005-01-01")
      ),
      folio: new FormControl("", [
        Validators.maxLength(40),
        Validators.minLength(1),
        Validators.pattern(this.unamePattern)
      ]),
      serie: new FormControl("", [
        Validators.maxLength(15),
        Validators.minLength(1),
        Validators.pattern(this.unamePattern)
      ]),
      documento: new FormControl(-1),
      pagado: new FormControl(-1),
      estatus: new FormControl(-1),
      Art69: new FormControl("-1"),
      Validacion: new FormControl(-1),
      Ingresos: new FormControl("-1"),
      Egresos: new FormControl("-1"),
      receptor: new FormControl("", Validators.pattern(this.receptorPattern))
    });
    this.emailvalue = new FormGroup({
      email: new FormControl("", [Validators.pattern(this.emailpattern)])
    });
    //this.initMqtt(localStorage.getItem("nombreUsu")+"_RepoBoxWeb");
    this.alertSweet = new SweetAlertModals();
  }

  //obtiene la fecha actual
  private getDate1() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 1 ? "0" + month : month;

    var day = new Date(now.getFullYear(), now.getMonth(), 1);
    var today =
      now.getFullYear() +
      "-" +
      this.conseguirmes(mesFinal) +
      "-" +
      "0" +
      day.getDate();

    return (this.inicial = today.toString());
  }
  conseguirdia(dia) {
    if (dia.toString().length == 3) return dia.substring(1, 3);
    else return dia;
  }
  conseguirmes(mes) {
    if (mes.toString().length == 3) return mes.substring(1, 3);
    else return mes;
  }

  //obtiene la fecha actual
  private getDate2() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let dia = "0" + now.getDate().toString();

    // let diaBueno =dia.toString.length>=2? dia.toString().replace('0',''):dia.toString() ;
    let today =
      now.getFullYear() +
      "-" +
      this.conseguirmes(mesFinal) +
      "-" +
      this.conseguirdia(dia);

    return (this.final = today);
  }
  //Obtiene el Numero de la columna seleccionada en el checkbox de la tabla comprobantes
  private fixArrayGetROW() {
    var files = $('input[name="files"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].id.replace("-input", "");
    }

    return files_names;
  }

  //Obtiene el Numero de la columna seleccionada en el checkbox de la tabla complemento de pago
  private fixArrayGetROW2() {
    var files = $('input[name="files2"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].id;
    }

    return files_names;
  }

  //Obtiene el value del checkbox  de la tabla comprobantes
  private fixArrayID() {
    var files = $('input[name="files"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value;
    }

    return files_names;
  }
  //Obtiene el value del checkbox  de la tabla comprobantes al enviar por correo electronico
  private fixArrayIDCorreos() {
    var files = $('input[name="files"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value;
    }

    return files_names;
  }

  //Obtiene el value del checkbox  de la tabla complemento de pago
  private fixArrayID2() {
    var files = $('input[name="files2"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value.replace("Archivos", "BUCKET");
    }

    return files_names;
  }
  //Obtiene el value del checkbox para recibo electronico de pagos
  private fixArrayID3() {
    var files = $('input[name="files"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      var buck = files[i].value.replace("BUCKET", "BUCKET/ComplementosDePagos");
      files_names[i] = buck.replace(".xml", "_ComplementoPago.xml");
    }

    return files_names;
  }
  SonRecibosPagos: boolean = false;
  SeConsulto: boolean = false;
  public guardarEgresos() {
    this.consultaComprobante.initialDateTime = this.forma.value["fechaInicial"];
    this.consultaComprobante.finalDateTime = this.forma.value["fechaFinal"];
    this.consultaComprobante.Folio = this.forma.value["folio"];
    this.consultaComprobante.Serie = this.forma.value["serie"];
    this.consultaComprobante.Articulo = this.forma.value["Art69"];
    this.consultaComprobante.documento = this.forma.value["documento"];
    this.consultaComprobante.pagado = this.forma.value["pagado"];
    this.consultaComprobante.Status = this.forma.value["estatus"];
    this.consultaComprobante.Validacion = this.forma.value["Validacion"];
    this.consultaComprobante.Irc = this._globalService.usuarioIRC;

    if (this.consultaComprobante.documento == 7) {
      this.SonRecibosPagos = true;
    } else {
      this.SonRecibosPagos = false;
    }
    if (this.mensajito == "Varios")
      // esto sirve para los filtros de busqueda provedor/Cliente daniel vega 24 julio
      this.consultarVarios();
    if (!this.consultaComprobante.receptor.includes("Con_")) {
      this.consultaComprobante.receptor = "0"; //  esto se ejecuta cuando no se selecciono un cliente en la busqueda
    }
    this.paginadorVisible = false;
    this.selection.clear();
    this.bloqueoDeCargarMas = false;
    this.enviarConsulta(this.consultaComprobante, "");
  }

  private consultarVarios() {
    this.consultaComprobante.receptor = "";
    for (let index = 0; index < this.SeleccionadoProCliany.length; index++) {
      this.consultaComprobante.receptor +=
        "Con_" + this.SeleccionadoProCliany[index].Clave;
    }
  }
  SizeVal: number;
  public busquedaProv(objEmpresa) {
    $("#selectProvedoress").hide();

    this.consultaComprobante.receptor =
      "Con_" + this.BusquedaProCliany[objEmpresa].Clave;
    this.mensajito = this.BusquedaProCliany[objEmpresa].Empresa;
    $("#selectVariosProv").val(this.mensajito);
    this.BusquedaProCliany = null;
    //this.forma.value.receptor=objEmpresa.Empresa
  }

  returned() {
    return this.PermisoInput;
  }
  private enviarConsulta(comp, index) {
    let a = this.ModalError(this.consultaComprobante.initialDateTime, this.consultaComprobante.finalDateTime);
    if (a != true) {
      this.isLoadingResults = true; // Comienza spinner modal
      this._consultasService.consultaComproba(comp, index).subscribe(data => {

        if (data.success == false) {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
            return;
          }
          else {
            this.WarningPerzonalizado("Atencion", data.msg);
            this.isLoadingResults = false; // termina spinner modal
          }
        }
        else if (data.success == true) {
          //RENUEVA TOKEN
          if (index == "1000") {
            this.paginadorVisible = true;
          }

          localStorage.removeItem("Token");
          localStorage.setItem("Token", data.token[0] + "," + data.token[1]);
          // FIN RENOVACION
          this.isLoadingResults = false; // termina spinner modal
          this.comprobante = data.comprobantes;

          if (data.comprobantes.length == 0) {
            this.WarningConsultasVacias();
          } else {
            this.maxPaginas = data.comprobantes[0].PaginaSize;
            if (this.consultaComprobante.index == 1) {
              $("#Pag1").css("background-color", "rgb(191, 199, 224)");
            }
          }
          if (this.iniciarCarga == true) {
            for (let index = 0; index < this.comprobante.length; index++) {
              this.comprobanteTemporal.push(data.comprobantes[index]);
            }
            this.iniciarCarga = false;
            this.comprobante = this.comprobanteTemporal;
          }
          this.comprobante = this.refresh();
          this.funcionesFooter();
        }
      });
      // this.consultaComprobante.receptor = "0";
      error => console.error(error);
    }
  }
  UrlLiga: string = "";
  LIGAFINAL: string;
  //Obtiene la liga del pdf de la factura
  public verComprobante(id: any, elemento: any) {
    if (elemento.TipoPDF == 4) {

    } else {
      if (this.SonRecibosPagos == true) {
        this.verCOMReciboPagos(id);
      } else {
        this.LIGAFINAL = "";

        this._consultasService
          .setPDF(id.replace(".xml", ".pdf"))
          .subscribe(data => {
            if (data.session == 1) {
              this.CerrarSesion(data.msg);
            } else {
              this.LIGAFINAL = data.Liga;
              $("#modalXCOM").modal("show");
            }
          });
        error => console.error(error);
        id = null;
      }
    }
  }

  //Obtiene la liga del pdf del complemento de pago
  public verComprobanteComplemento(id: any) {
    this.LIGAFINAL = "";
    this._consultasService
      .setPDFComplemento(
        this.ComplementoDePago[id].Ruta +
        "/" +
        this.ComplementoDePago[id].NombreArchivo.replace(".xml", ".pdf")
      )
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          var ligatemp = data.Liga;
          this.LIGAFINAL = data.Liga;
          $("#modalXCOM").modal("show");
        }
      });
    error => console.error(error);
    id = null;
  }
  public verCOMReciboPagos(id: any) {
    this.LIGAFINAL = "";
    let ruta = id.replace("BUCKET", "Archivos/ComplementosDePagos");
    this._consultasService
      .setPDFComplemento(ruta.replace(".xml", "_ComplementoPago.pdf"))
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          var ligatemp = data.Liga;
          this.LIGAFINAL = data.Liga;
          $("#modalXCOM").modal("show");
        }
      });
    error => console.error(error);
    id = null;
  }

  public verVALReciboPagos(id: any) {
    this.LIGAFINAL = "";

    let ruta = id.replace("BUCKET", "Archivos/ComplementosDePagos");
    this._consultasService
      .setPDFComplemento(ruta.replace(".xml", "_ComplementoPago_VAL.pdf"))
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          var ligatemp = data.Liga;
          this.LIGAFINAL = data.Liga;
          $("#modalXCOM").modal("show");
        }
      });
    error => console.error(error);
    id = null;
  }
  //Obtiene la liga del pdf de Validacion en comprobantes
  public verValidacion(validacionPDF: any, elemento: any) {
    if (elemento.TipoPDF == 4) {

    } else {
      if (this.SonRecibosPagos == true) {
        this.verVALReciboPagos(validacionPDF);
      } else {
        this.LIGAFINAL = "";
        this._consultasService
          .setPDF(validacionPDF.replace(".xml", "_VAL.pdf"))
          .subscribe(data => {
            if (data.session == 1) {
              this.CerrarSesion(data.msg);
            } else {
              this.LIGAFINAL = data.Liga;
              $("#modalXCOM").modal("show");
            }
          });
        error => console.error(error);
        validacionPDF = null;
      }

    }

  }

  //Obtiene la liga del pdf de Validacion en complemento de pago
  public verValidacionComplemento(id: any) {
    this.LIGAFINAL = "";

    this._consultasService
      .setPDFComplemento(
        this.ComplementoDePago[id].Ruta +
        "/" +
        this.ComplementoDePago[id].NombreArchivo.replace(".xml", "_VAL.pdf")
      )
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          var ligatemp = data.Liga;
          this.LIGAFINAL = data.Liga;
          $("#modalXCOM").modal("show");
        }
      });
    error => console.error(error);
    id = null;
  }

  //descarga el arrchivo zip de la factura
  public DercargarZip(nombre, fichero: any, elemento: any) {
    if (elemento.TipoPDF == 4) {

    } else {
      let splitFich = fichero.split("/");
      let folioFiscal = splitFich[3].toString();
      let extencion = folioFiscal.split(".");
      let nombreFolio = extencion[0];
      if (this.SonRecibosPagos == true) {
        this.DescargarReciboPagos(nombre, fichero);
      } else {
        this.WargningInferior(
          "Ha comenzado la descarga del cliente\n" + nombre,
          "Espere un momento.",
          2000
        );
        this._consultasService.ZipService(fichero).subscribe(response => {
          var blob = new Blob([response], { type: "application/zip" });
          saveAs(
            blob,
            "RepoBox_" + this.nombreUsuario2 + "_" + nombreFolio + "_" + this.final + ".zip"
          );

          // var urlToOpen = URL.createObjectURL(blob);

          // window.open(urlToOpen);
        }),
          error => console.error(error);
      }

    }

  }
  public DescargarReciboPagos(nombre, id: any) {
    let ruta = id.replace("BUCKET", "BUCKET/ComplementosDePagos");
    this.WargningInferior(
      "Ha comenzado la descarga del cliente\n" + nombre,
      "Espere un momento.",
      2000
    );
    this._consultasService
      .ZipService(ruta.replace(".xml", "_ComplementoPago.xml"))
      .subscribe(response => {
        var blob = new Blob([response], { type: "application/zip" });
        saveAs(
          blob,
          "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
        );

        // var urlToOpen = URL.createObjectURL(blob);

        // window.open(urlToOpen);
      }),
      error => console.error(error);
  }

  //descarga el archivo zip de complemento de pago
  public DercargarZipComplemento(id: any) {
    this._consultasService
      .ZipService(
        this.ComplementoDePago[id].Ruta.replace("Archivos", "BUCKET") +
        "/" +
        this.ComplementoDePago[id].NombreArchivo
      )
      .subscribe(response => {
        var blob = new Blob([response], { type: "application/zip" });
        saveAs(
          blob,
          "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
        );

        // var urlToOpen = URL.createObjectURL(blob);

        // window.open(urlToOpen);
      }),
      error => console.error(error);
  }

  //descarga multiple de archivos zip en comprobantes
  public DescargarMultiCheckBox() {

    var elzip = this.fixArrayID().toString();
    let numeroZip = elzip.split(",");

    if (numeroZip.length > 999) {
      this.modalDescargaMasiva();
    }
    else if (numeroZip.length > 199) {
      this.descargaMasivaComprobantes(false);
    }
    else if (this.SonRecibosPagos == true) {
      this.DescargarMultiCheckBoxReciboPagos();
    }
    else if (elzip != "") {
      this.WargningInferiorPermanente(
        "Ha comenzado la descarga de " + numeroZip.length + " documento(s). ",
        "Espere un momento.",
        numeroZip.length
      );
      //Comienza el servicio
      this._consultasService.ZipService(elzip.toString())
        .subscribe(response => {
          var blob = new Blob([response], { type: "application/zip" });
          saveAs(
            blob,
            "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
          );

          this.snackBar.dismiss();
          this.subscription.unsubscribe();
          this.barra = 100;
        }),
        error => console.error(error);
    } else if (this.comprobante.toString() == "") {
      this.WarningRealizeUnaConsulta();
    } else {
      this.WarningSeleccioneFacturas();
    }

  }

  //descarga multiple de archivos zip en complemento de pago
  public DescargarMultiCheckBoxComplemento() {
    var elzip = this.fixArrayID2().toString();
    if (elzip != "") {
      let numeroZip = elzip.split(",");
      this.WargningInferior(
        "Ha comenzado la descarga de " + numeroZip.length + " documento(s). ",
        "Espere un momento.",
        2000
      );
      this._consultasService
        .ZipService(elzip.toString())
        .subscribe(response => {
          var blob = new Blob([response], { type: "application/zip" });
          saveAs(
            blob,
            "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
          );
        }),
        error => console.error(error);
    } else {
      this.WarningSeleccioneFacturas();
    }
  }
  public DescargarMultiCheckBoxReciboPagos() {
    var elzip = this.fixArrayID3().toString();
    if (elzip != "") {
      let numeroZip = elzip.split(",");
      this.WargningInferior(
        "Ha comenzado la descarga de " + numeroZip.length + " documento(s). ",
        "Espere un momento.",
        2000
      );
      this._consultasService
        .ZipService(elzip.toString())
        .subscribe(response => {
          var blob = new Blob([response], { type: "application/zip" });
          saveAs(
            blob,
            "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
          );
        }),
        error => console.error(error);
    } else {
      this.WarningSeleccioneFacturas();
    }
  }
  fechacancelacion: String = "";
  rutaAcuse: string = "";
  //Obtiene complemento de pago
  public GetIdRFC(id: any, elemento: any) {
    //Este es el estatus de la factura
    if (elemento.TipoPDF == 4) {
      swal
    } else {
      this.StatusComprobante = this.comprobante[id].Status;
      this.TotalComprobante = this.comprobante[id].Total;
      this._consultasService
        .EnviarID(this.comprobante[id].Clave, this.SonRecibosPagos) // AHORA EL OBJECTO RESIVE FACTURAS Y COMPLEMENTOS DE PAGO
        .subscribe(data => {
          debugger;
          this.estadoPeticion = data.ListResult
          console.log(data);
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
            this.ComplementoDePago = data.resultado;
            console.log("escomeplemento");
            for (let index = 0; index < this.ComplementoDePago.length; index++) {
              if (this.ComplementoDePago[index].Folio == null) {
                this.ComplementoDePago.splice(index, index);
              }
            }

            var totalNotas = 0;
            var total = 0;
            for (let index = 0; index < this.ComplementoDePago.length; index++) {

              var element = this.ComplementoDePago[index].ImpPagadoDet;
              // total = total + element;

              if ((this.ComplementoDePago[index].TipoComprobante == 2 || this.ComplementoDePago[index].TipoComprobante == 6) && (this.ComplementoDePago[index].Status == 2 || this.ComplementoDePago[index].Status == 4)) {
                var element = this.ComplementoDePago[index].ImpPagadoDet;
                totalNotas = totalNotas + element;
              }

              if (this.ComplementoDePago[index].TipoComprobante == 7 && this.ComplementoDePago[index].Status == 2) {
                var element = this.ComplementoDePago[index].ImpPagadoDet;
                total = total + element;
              }
            }
            // for (let index = 0; index < this.ComplementoDePago.length; index++) {


            // }
            this.NotaCredito = totalNotas;

            this.rutaAcuse = this.ComplementoDePago[0].RutaAcuse + "/" + this.ComplementoDePago[0].NombreArchivoAcuse
            //Este es el total de la factura
            this.Pagado = total;

            if (this.Pagado == -1) {
              this.Pagado = 0;
            }

            //Saldo:  este es el total de la factura menos lo pagado
            this.Saldo = this.TotalComprobante - (this.NotaCredito + this.Pagado);  //ComplementoDePago.Total es un valor de la factura normal

            //Factura: este es el total de la factura
            this.TotalComplemento = this.ComplementoDePago[0].Total;



            // Validacion si la factura contiene complemento de pago

            if (
              this.ComplementoDePago[0].FechaCancelacion != 0 ||
              this.ComplementoDePago[0].FechaCancelacion != null
            ) {
              this.fechacancelacion = this.ComplementoDePago[0].FechaCancelacion;
            }

            if (this.ComplementoDePago[0].Errores == -1)
              if (this.ComplementoDePago[0].IDPago == 0) {
                this.ComplementoDePago = null;
              }

          }
        });
      error => console.error(error);

      this.comprobanteIndividual = this.comprobante[id];

    }


  }
  //CAMBIAR LABEL CLIENTE/PROVEEDOR
  public show: boolean = false;
  ColorIngresos: string = "off";
  ColorEgresos: string = "on";
  clienteProvedor: string = "Proveedor";
  toggle(btnTipo) {
    if (btnTipo == "ingresos") {
      this.cambiarColorHeaderTabla(btnTipo);
      this.ColorIngresos = "on";
      this.ColorEgresos = "off";
      //this.tipoComprobante = 1;
      this.consultaComprobante.tipocomprobante = 1;
    } else {
      this.cambiarColorHeaderTabla(btnTipo);
      this.ColorIngresos = "off";
      this.ColorEgresos = "on";
      //this.tipoComprobante = 0;
      this.consultaComprobante.tipocomprobante = 0;
    }
    this.bloqueoDeCargarMas = false;
  }
  private cambiarColorHeaderTabla(tipo) {
    if (tipo == "ingresos") {
      //header tabla complemento
      this.dataSource.data = [];
      this.comprobante = [];
      this.resultadoProvCliany = [];
      this.SeleccionadoProCliany = [];
      this.funcionesFooter();
      this.clienteProvedor = "Cliente";
    } else {
      //header tabla complemento
      this.dataSource.data = [];
      this.comprobante = [];
      this.resultadoProvCliany = [];
      this.SeleccionadoProCliany = [];
      this.funcionesFooter();
      this.clienteProvedor = "Proveedor";
    }
  }
  //Sirve para marcar como pagada una factura
  public Pagada(id: any, pagada: any, documento: any) {
    if (documento.TipoPDF == 4) {

    } else {
      if (documento.Status == 3) {
        this.WarningPerzonalizado(
          "¡Atención!",
          "No se puede marcar como pagada un comprobante con el estatus cancelada."
        );
      } else {
        this._consultasService.pagada(id, pagada).subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else if (data.success == false) {
            this.WargningInferior(data.mgs, "Aceptar", 4000);
          } else {
            if (pagada == 0) {
              // modifica el estatus de pagado en el objecto
              for (var _i = 0; _i < this.comprobante.length; _i++) {
                if (this.comprobante[_i].Clave == documento.Clave) {
                  this.comprobante[_i].Pagado = 1;
                }
              }
              this.WargningInferior(
                "El comprobantes se ha marcado como pagada sastifactoriamente.",
                "Aceptar",
                2000
              );
            } else {
              // modifica el estatus de pagado en el objecto
              for (var _i = 0; _i < this.comprobante.length; _i++) {
                if (this.comprobante[_i].Clave == documento.Clave) {
                  this.comprobante[_i].Pagado = 0;
                }
              }
              this.WargningInferior(
                "El comprobantes se ha desmarcado como pagada sastifactoriamente.",
                "Aceptar",
                2000
              );
            }
          }
        });
        error => console.error(error);
      }

    }

  }

  //verifica los comprobantes para cambiar su estatus y reprocesarla
  public VerificacionDeComprobante() {
    var row = [];

    if (this.SonRecibosPagos == true) {
      this.VerificacionDeReciboPagos();
    } else {
      var elzip = this.fixArrayGetROW().toString();
      if (elzip != "") {
        var arrayids = elzip.split(",");
        for (let index = 0; index < arrayids.length; index++) {
          if (this.comprobante[arrayids[index]].Status != 6) {
            row.push(this.comprobante[arrayids[index]].Clave);
          }
          this.comprobante[arrayids[index]].Status = 6;
        }
        if (row.length != 0) {
          this._consultasService
            .VerificarComprobantes(row.toString())
            .subscribe(data => {
              if (data.session == 1) {
                this.CerrarSesion(data.msg);
              } else {
                this.mensajeExistoso(
                  "Se ha iniciado la verificación",
                  "En un momento se actualizará el status del comprobante."
                );
              }
            });
          error => console.error(error);
        } else {
          this.WarningSeleccionFacturaYaReprocesada();
        }
      } else {
        this.WarningSeleccioneFacturas();
      }
    }
  }
  private mensajeExistoso(mensajeEncabezado, subMensaje) {
    swal(mensajeEncabezado, subMensaje, "success");
  }
  private WarningSeleccionFacturaYaReprocesada() {
    swal({
      title: "Seleccione comprobantes",
      text:
        "Los comprobantes seleccionadas ya se encuentran en proceso de verificación.",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }

  public VerificacionDeReciboPagos() {
    var row = [];

    var elzip = this.fixArrayGetROW().toString();
    if (elzip != "") {
      var arrayids = elzip.split(",");
      for (let index = 0; index < arrayids.length; index++) {
        if (this.comprobante[arrayids[index]].Status != 6) {
          row.push(this.comprobante[arrayids[index]].Clave);
        }
        this.comprobante[arrayids[index]].Status = 6;
      }
      if (row.length != 0) {
        this._consultasService
          .VerificarComprobantesPagos(row.toString())
          .subscribe(data => {
            if (data.session == 1) {
              this.CerrarSesion(data.msg);
            } else {
              this.mensajeExistoso(
                "Se ha iniciado la verificación",
                "En un momento se actualizará el status del comprobante."
              );
            }
          });
        error => console.error(error);
      } else {
        this.WarningSeleccionFacturaYaReprocesada();
      }
    } else {
      this.WarningSeleccioneFacturas();
    }
  }

  //verifica los complementos de pago para cambiar su estatus y reprocesarlo
  public VerificacionDeComprobantePagos() {
    var row = [];

    var elzip = this.fixArrayGetROW2().toString();
    if (elzip != "") {
      var arrayids = elzip.split(",");
      for (let index = 0; index < arrayids.length; index++) {
        this.ComplementoDePago[arrayids[index]].Status = 6;
        row.push(this.ComplementoDePago[arrayids[index]].IDPago);
      }

      this._consultasService
        .VerificarComprobantesPagos(row.toString())
        .subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
          }
        });

      error => console.error(error);
    } else {
      this.WarningSeleccioneFacturas();
    }
  }
  public busquedaReceptorTodas() {
    debugger
    this._consultasService
      .BusquedaReceptorTodas(
        this.consultaComprobante.Todos,
        this.consultaComprobante.tipocomprobante
      )
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
        }
      });
    error => console.error(error);
  }

  //este es el mensaje que proporciona el resultado de la validacion
  private ModalError(inicial, final) {
    var a = new Date(inicial);
    var b = new Date(final);

    var day = a.getFullYear();
    var day2 = b.getFullYear();

    if (+a > +b) {
      //   "La fecha inicial no puede ser mayor a la fecha final";

      swal({
        title: "Error de fechas",
        text: "La fecha inicial no puede ser mayor a la fecha final ",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    } else if (inicial == "") {
      // this.MensajeDelModal = "Especifique una fecha correcta";
      swal({
        title: "Error de fecha inicial",
        text: "El campo de fecha inicial ingresado es invalido ",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    } else if (final == "") {
      // this.MensajeDelModal = "Especifique una fecha correcta";
      swal({
        title: "Error de fecha final",
        text: "El campo de fecha final igresado es invalido",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    }
    return false;
  }
  private WarningConsultasVacias() {
    // this.MensajeDelModal = "No se encontraron facturas";
    swal({
      title: "No se encontraron comprobantes",
      text: "Intente realizar una consulta distinta",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }

  private WarningSeleccioneFacturas() {
    swal({
      title: "Seleccione comprobantes",
      text: "Intente seleccionando al menos un comprobante.",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  private WarningRealizeUnaConsulta() {
    swal({
      title: "Realice una consulta",
      text: "Intente seleccionando al menos un comprobante.",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  iniciarCarga: boolean = false;
  Contador: number;

  //esta te carga 50 facturas mas
  public CargarMas() {
    if (this.comprobante.length == 0) {
      this.WarningPerzonalizado(
        "Realice una consulta",
        "Intente realizando una consulta previamente."
      );
    } else {
      this.iniciarCarga = true;
      this.comprobanteTemporal = this.comprobante;
      this.consultaComprobante.index = this.consultaComprobante.index + 1;
      this.enviarConsulta(this.consultaComprobante, "");
    }
  }

  //esta te carga todas las facturas
  public CargarTodo() {
    debugger
    if (this.comprobante.length == 0) {
      this.WarningPerzonalizado(
        "Realice una consulta",
        "Intente realizando una consulta previamente."
      );
    } else {
      this.consultaComprobante.Todos = "Todo";
      this.enviarConsulta(this.consultaComprobante, "");

      this.consultaComprobante.Todos = "";
      this.bloqueoDeCargarMas = true;
    }
  }

  //Sirve para mostrar todos los provedores al darle click al check box - Daniel Vega
  public mostrarProvedores(e, valor) {
    //!valor==true?this.checkBoxAngularMaterial=true:this.checkBoxAngularMaterial=false; // sirve para tener el estado del check box
    let tipo = this.consultaComprobante.tipocomprobante;

    if (!valor == true) {
      $("#modalVarios").modal("show"); //Open Modal
      /// Validacion echa por el practicante german ?
      if (this.elbuli != true) {
        this._consultasService
          .BusquedaReceptorTodas("todos", tipo)
          .subscribe(data => {
            if (data.session == 1) {
              this.CerrarSesion(data.msg);
            } else {
              this.resultadoProvCliany = data.Empresas;
              this.elbuli = true;
            }
          });


      }

      $("#selectVariosProv").val("Varios")
      $("#selectVariosProv").prop("disabled", true);



      error => console.error(error);
    } else {
      // vuelve al estado original (todos) el input de busqueda de provedores si el checked esta en false
      this.consultaComprobante.receptor = "0";
      this.mensajito = "Todos";
      this.forma.value["receptor"] = "Todos";
      $("#selectVariosProv").val("")
      $("#selectVariosProv").prop("disabled", false);
    }
  }

  //Sirve para filtrar los provedores al escribir en el input de busqueda provedores- Daniel Vega
  public modalBusquedaProvedores(e, valor) {
    let tipo = this.consultaComprobante.tipocomprobante;

    if (valor.length < 1) {
      valor = "todos";
    }
    this._consultasService
      .BusquedaReceptorTodas(valor, tipo)
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          this.resultadoProvCliany = data.Empresas;
        }
      });
    error => console.error(error);
  }
  //Sirve para filtrar los provedores al escribir en el input de busqueda provedores- Daniel Vega
  public BusquedaProvedores(e, valor) {
    $("#selectProvedoress").removeAttr("style");
    //  $("#lblProvedores").show(); // abre el mini modal de busqueda
    let tipo = this.consultaComprobante.tipocomprobante;
    if (valor.length < 3) {
      this.BusquedaProCliany = null;
      this.consultaComprobante.receptor = "0";
      //this.mensajito = "Todos";
      // this.forma.value["receptor"] = "Todos";
    }
    if (valor.length >= 2) {
      this._consultasService.BusquedaReceptor(valor, tipo).subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          this.BusquedaProCliany = data.Empresas;
          if (this.BusquedaProCliany.length === 0) {
            this.BusquedaProCliany = null;
            this.consultaComprobante.receptor = "0";
          } else {
            if (this.BusquedaProCliany.length >= 4) {
              this.SizeVal = 4;
            } else if (this.BusquedaProCliany.length == 1) {
              this.SizeVal = 2;
            } else {
              this.SizeVal = this.BusquedaProCliany.length;
            }
          }

        }
      }, error => {
        console.error(error);
      });
      // error => console.error(error);
    }
  }

  private seleccionarEmpresa(valor, index) {
    // if (this.SeleccionadoProCliany.indexOf(valor,0) > -1) {
    //   return;
    // }

    for (var i = 0; i < this.SeleccionadoProCliany.length; i++) {
      if (valor.Clave == this.SeleccionadoProCliany[i].Clave) {
        return false;
      }
    }
    this.SeleccionadoProCliany.push(valor);
    this.resultadoProvCliany.splice(index, 1);
  }

  private desSeleccionarEmpresa(valor, index) {
    this.resultadoProvCliany.push(valor);
    this.SeleccionadoProCliany.splice(index, 1);
  }

  mensajito: string = "Todos";

  public borrarLista() {
    this.SeleccionadoProCliany.length = 0;
    this.elbuli = false;
    this.mensajito = "todos";
    this.consultaComprobante.receptor = "0";
  }

  public cambiarInput() {
    if (this.SeleccionadoProCliany.length == 0) {
      // se comprueba si se selecciono un  cliente del modal
      this.mensajito = "Todos";
      this.consultaComprobante.receptor = "0";
    } else {
      // esto se ejecuta cuando se selecciono un cliente
      this.mensajito = "Varios";
    }
  }
  private splitFechaCancelacion() {
    var splitfechas = [];
    for (let index = 0; index < this.comprobante.length; index++) {
      var df = this.comprobante[index].FechaCreacion.replace("|", "\n");

      this.comprobante[index].FechaCreacion = df.replace("|", "\n");
    }
  }
  //Envia archivos por correo electronico
  public enviarArchivos() {
    debugger
    if (this.SonRecibosPagos == true) {
      var elzip = this.fixArrayID3().toString();
    } else {
      var elzip = this.fixArrayIDCorreos().toString();
    }
    if (elzip == "") {
      this.WarningSeleccioneFacturas();
    } else if (this.sendValue[0] != null) {
      $("#modalWait").modal("show");

      this._consultasService
        .EnviarArchivos(elzip.toString(), this.sendValue.toString())
        .subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
            $("#modalWait").modal("hide");
            this.mensajeExistoso(
              "Los comprobantes se han enviado con éxito",
              "Si no ves el mensaje en la Bandeja de entrada, busca en la carpeta de correo no deseado o vuelve a comprobar el correo más tarde."
            );
          }
        });
      error => console.error(error);

      this.inputValue.lenght = 0;
    } else {
      $("#modalWait").modal("hide");
      this.WarningPerzonalizado(
        "Aun no ha agregado ningun correo",
        "Si ya escribio un correo presione la tecla enter para confirmarlo"
      );
    }
  }

  public EliminarEmail(index) {
    this.inputValue.splice(index, 1);
  }

  private subtotal() {
    this.Subtotal = 0;

    for (let index = 0; index < this.comprobante.length; index++) {
      /** estatus */
      if (`${this.forma.value.estatus}` !== "3") {
        if (this.comprobante[index].Status !== 3)
          this.Subtotal = this.Subtotal + this.comprobante[index].SubTotal;
      } else {
        this.Subtotal = this.Subtotal + this.comprobante[index].SubTotal;
      }

    }
  }
  private iva() {
    this.IVA = 0;

    for (let index = 0; index < this.comprobante.length; index++) {
      if (`${this.forma.value.estatus}` !== "3") {
        if (this.comprobante[index].Status !== 3)
          this.IVA = this.IVA + this.comprobante[index].IVA;
      } else {
        this.IVA = this.IVA + this.comprobante[index].IVA;
      }
    }
  }
  // EMAIL
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  sendValue: any = [];
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      if (value.indexOf("@", 0) > -1) {
        //SIRVE PARA LA VALIDACION DEL @ EN EL CORREO ELECTRONICO
        this.inputValue.push({ name: value.trim() });
        this.sendValue.push(value);
      }
    }

    // Reset the input value
    if (input) {
      if (value.indexOf("@", 0) > -1) {
        input.value = "";
      }
    }
  }
  // FIN EMAIL
  remove(fruit: any = []): void {
    const index = this.inputValue.indexOf(fruit);

    if (index >= 0) {
      this.inputValue.splice(index, 1);
      this.sendValue.splice(index, 1);
    }
  }

  public abrirModal() {
    $("#exampleModalCorreo").modal("show");
  }

  private total() {
    this.Total = 0;

    for (let index = 0; index < this.comprobante.length; index++) {
      if (`${this.forma.value.estatus}` !== "3") {
        if (this.comprobante[index].Status !== 3)
          this.Total = this.Total + this.comprobante[index].Total;
      } else {
        this.Total = this.Total + this.comprobante[index].Total;
      }
    }
  }

  private totalMostradoFacturas() {
    this.cantidaFacturas = this.dataSource.data.length;
  }

  private funcionesFooter() {
    this.subtotal();
    this.iva();
    this.total();
    this.totalMostradoFacturas();
    this.splitFechaCancelacion();
  }

  public coloRow(i) {
    $("#tblTabla tr").removeClass("Seleccionado");
    $(i.currentTarget).addClass("Seleccionado");
  }
  private recalcularFiltroTabla() {
    this.Total = 0;
    for (let index = 0; index < this.dataSource.filteredData.length; index++) {
      this.Total = this.Total + this.dataSource.filteredData[index].Total;
    }
    this.Subtotal = 0;

    for (let index = 0; index < this.dataSource.filteredData.length; index++) {
      this.Subtotal =
        this.Subtotal + this.dataSource.filteredData[index].SubTotal;
    }
    this.IVA = 0;
    for (let index = 0; index < this.comprobante.length; index++) {
      this.IVA = this.IVA + this.dataSource.filteredData[index].IVA;
    }
  }
  private WargningInferior(message: string, action: string, duracion: number) {
    //NOMBRE REAL SNACKBAR
    this.snackBar.open(message, action, {
      duration: duracion
    });
  }

  CerrarSesion(dataMensaje: string) {
    this.WarningPerzonalizado("Atencion!", dataMensaje);
    this._Auth0Service.logout();
  }
  public ocultarBoton() {
    if (this.ocultarBotones == false) this.ocultarBotones = true;
    else this.ocultarBotones = false;
  }
  trackByIndex(index, item) {
    return index;
  }
  //==================================
  //SERVICIO NOTIFICAICON
  //==================================
  // initMqtt(userId: string) {    
  //   this.subscription = this._mqttService
  //     .observe("/" + userId)
  //     .subscribe((message: IMqttMessage) => {

  //       let msg = JSON.parse(message.payload.toString());
  //       this.snackBar.open(msg.message, "Cerrar", {
  //         duration: 3000,
  //         verticalPosition: "bottom",
  //         horizontalPosition: "center"
  //       });
  //       //  alert('Mensaje mqtt '+ this.message);
  //     });
  // }
  //sirve para el check box principal
  cerrarV() {
    $("#checkVarios")
      .removeClass("mat-checkbox-checked")
      .addClass("mat-checkbox-unchecked");
    $("#checkVarios-input").click();
    $("#selectVariosProv").val("")
    $("#selectVariosProv").prop("disabled", false);
  }
  cambiarDOcT() {
    this.bloqueoDepagos = "SI";
  }
  cambiarDOcF() {
    this.bloqueoDepagos = "NO";
  }

  paginadorVisible: boolean = false;
  HideCargar() {
    this.EnviarConsultaFiltrada("Pag1", this.Pag1);
  }

  Pag1: number = 1;
  Pag2: number = 2;
  Pag3: number = 3;
  Pag4: number = 4;
  Pag5: number = 5;

  maxPaginas: number = 0;
  AvanzarUno() {
    if (this.Pag5 < this.maxPaginas) {
      this.Pag1 = this.Pag1 + 1;
      this.Pag2 = this.Pag2 + 1;
      this.Pag3 = this.Pag3 + 1;
      this.Pag4 = this.Pag4 + 1;
      this.Pag5 = this.Pag5 + 1;
    }
  }

  RetrocederUno() {
    if (this.Pag1 > 1) {
      this.Pag1 = this.Pag1 - 1;
      this.Pag2 = this.Pag2 - 1;
      this.Pag3 = this.Pag3 - 1;
      this.Pag4 = this.Pag4 - 1;
      this.Pag5 = this.Pag5 - 1;
    }
  }

  indice: number = 0;
  EnviarConsultaFiltrada(name, index) {
    this.indice = index;

    $("#Pag1 ,#Pag2 , #Pag3 , #Pag4 , #Pag5 , #PagFinal").css(
      "background-color",
      "#FFFFFF"
    );
    $("#" + name).css("background-color", "rgb(191, 199, 224)");

    this.consultaComprobante.index = index;
    this.enviarConsulta(this.consultaComprobante, "1000");
  }

  FuncionSubir() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  public DescargarAcuseCancelacion() {
    this._consultasService.ZipService(this.rutaAcuse).subscribe(response => {
      var blob = new Blob([response], { type: "application/zip" });
      saveAs(
        blob,
        "RepoBox_" + this.nombreUsuario + "_" + this.final + ".zip"
      );
    }),
      error => console.error(error);
  }
  public descargaMasivaComprobantes(descargarTodos: boolean) {
    let rutasSeleccinadas: string[] = [];
    if (descargarTodos == false && this.consultaComprobante.documento != 7) {
      let elzip = this.fixArrayID().toString();
      rutasSeleccinadas = elzip.split(",");
    }
    else if (descargarTodos == false) {
      let elzip = this.fixArrayID3().toString();
      rutasSeleccinadas = elzip.split(",");
    }
    let parametrosConsulta: ModelDescargaMasiva = { parametrosConsulta: this.consultaComprobante, listaRutasComprobantes: rutasSeleccinadas.toString() };

    this._consultasService.descargaMasivaCFDI(parametrosConsulta).subscribe(response => {

      $('#modaDescargaMasiva').modal('hide');
      this.alertSweet.ModalSuccess("Descarga solicitada con éxito ", "Tu petición sera atendida en breve, por favor visita el apartado de descargas para ver el progreso de la misma.");
    }),
      error => console.error(error);
  }
  //Sirve para el conteo de comprobantes
  ngComprobantesSelecionados: number;
  public modalDescargaMasiva() {
    this.ngComprobantesSelecionados = this.fixArrayID().toString().split(",").length;
    $('#modaDescargaMasiva').modal('show'); // abrir
  }
}
