

export class ComprobanteWeb {
   public ModeloXmlValidacion:ModeloXmlValidacion;
    public comprobanteValidacionWeb: comprobanteValidacionWeb;
}

export interface IComprobanteWeb {
    ModeloXmlValidacion:ModeloXmlValidacion;
    comprobanteValidacionWeb: comprobanteValidacionWeb;
}

export interface  ModeloXmlValidacion{
    Fecha_Validacion:string; 
    Total_Observaciones:string
    Total_Errores:string 
    Sello_PSGECFDI:string 
    Estatus_UUID:string
    Sello_SAT_Timbre_Validado:string 
    Sello_Emisor_Validado:string
    Folio_Validado_Listas_SAT:string   
    VersionCFD:string
    Nombre_Archivo_Validado:string
    Detalle:Detalle[];
}
    
  export  interface Detalle{
    Estatus:string
    Valor:string
    Atributo:string
    Nodo:string
    Tipo:string
    }

    export  interface comprobanteValidacionWeb{
          FolioValidacion : string;    
          Emisor : string; 
          RFCEMISOR : string; 
          RECEPTOR : string; 
          RFCRECEPTOR : string; 
          FOLIOFAC : string; 
          SERIEFAC : string; 
          UUID : string; 
          FoliodEnvío : string; 
          FechaAcuse : string; 
          FechadeRecepcion : string; 
          Errores : number;


    }