import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { sharedService } from '../../services/shared.service';
import { saveAs } from "file-saver";
@Component({
  selector: 'app-downloads-file',
  templateUrl: './downloads-file.component.html',
  styleUrls: ['./downloads-file.component.css']
})
export class DownloadsFileComponent  {
  id;
  fecha:string;
  myVal:any=null;
  ngDescargaIniciada :boolean = false;
  ngcargaProgress : string = "indeterminate";
      constructor(private activatedRoute:ActivatedRoute,private _sharedService:sharedService){ 
        this.activatedRoute.params.subscribe(params=>{
          
          this.id= params['id'];
          this.id = atob(this.id).split("|");
        })
  
   this._sharedService.DescargarCFDIEmail(this.id[0]).subscribe(response=>{
     
    var blob = new Blob([response], { type: "application/zip" });
    saveAs(blob, "Facturas" + ".zip");
    this.ngDescargaIniciada = true;
    this.ngcargaProgress = "determinate";
   })
 }
}



