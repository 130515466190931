import { SelectionModel } from "@angular/cdk/collections";
import { Component } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { ConsultaNomina, ConsultaComprobante } from "../../Interfaces/formulario.comprobante";
import { FormGroup, FormControl } from "@angular/forms";
import { NominaService } from "../../services/nomina.service";
import { ConsultasService } from "../../services/consultas.service";

import { DomSanitizer} from "@angular/platform-browser";
import { saveAs } from "file-saver";
import * as $ from "jquery";
import { CustomValidators } from "ng4-validators";
import swal from "sweetalert2";

import { MatSnackBar } from "@angular/material";
import { Observable } from "rxjs";
import { Auth0Service } from "../../services/auth0.service";

export interface PeriodicElement {
  nombre: string;
  rfc: string;
  serie: number;
  folio: string;
}

declare var jQuery: any;
declare var $: any;
@Component({
  selector: "app-consulta-nomina",
  templateUrl: "./consulta-nomina.component.html",
  styleUrls: ["./consulta-nomina.component.css"]
})
export class ConsultaNominaComponent {
  formaNomina: FormGroup;
  Nomina: any = [];
  displayedColumns: string[] = [
    "rfc",
    "nombre",
    "serie",
    "folio",
    "fecha",
    "fechaInicioFin",
    "pago",
    "percepciones",
    "deducciones",
    "isr",
    "total",
    "sat",
    "pdf",
    "visto",
    "descargado",
    "zip",
    "select"
  ];
  displayedColumns2: string[] = ["rfc", "nombre", "serie", "select"];
  isLoadingResults: boolean;
  UrlLiga: string = "";
  // inicial: Date = new Date(this.getDate1())
  // final: Date = new Date(this.getDate2())
  inicial: string = this.getDate1();
  final: string = this.getDate2();
  UsuarioSelected: string = "Todos";
  empleadoTemp: any = [];
  chartz: any = [];
  offInput: string;
  iniciarCarga: boolean;
  NominaTemporal: any = [];
  contadorIndex: number = 1;
  nombreUsuario: string;
  nombreUsuario2: string;

  selection = new SelectionModel<PeriodicElement>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  consultaNomina: ConsultaNomina = {
    lsFechaIni: "",
    lsFechaFin: "",
    receptor: "0",
    index: "1",
    ClaveAgrupadora: "",
    empleado : "",
    tipoFecha : 1
  };

  constructor(
    private _consultaService: NominaService,
    private _consultaServiceComprobante: ConsultasService,
    public sanitizer: DomSanitizer,
    public snackBar: MatSnackBar,
    private _Auth0Service: Auth0Service
  ) {
    this.nombreUsuario = this._Auth0Service.usuarioNamePagina();
    this.nombreUsuario2 = this._Auth0Service.usuarioNamePagina2();
    this.formaNomina = new FormGroup({
      lsFechaInicial: new FormControl(
        this.inicial,
        CustomValidators.minDate("2005-01-01")
      ),
      lsFechaFinal: new FormControl(
        this.final,
        CustomValidators.minDate("2005-01-01")
      ),
      lsCveAgrupadora: new FormControl(""),
      tipoFecha: new FormControl("1")
    });
  }

  ngOnInit() {
    $(document).mouseup(function(e) {
      if (
        !$("#empleadosList").is(e.target) && // if the target of the click isn't the container...
        $("#empleadosList").has(e.target).length === 0 
    
      ) {
        var words = $('#empleado').val().split(' ');
        if(words.length>=3)
        {
          $("#empleadosList").hide()
        }
        else{
          $("#empleado").val("")
          $("#empleadosList").hide()
        }
 
       
        
      }
  
    });
    $(document).on("click",".close", function () {
      this.LIGAFINAL="";

  
    });
    $(document).on("click",".modal-backdrop, .fade, .show", function () {
      this.LIGAFINAL="";

  
    });
  }

  dataSource = new MatTableDataSource<any>();

  refresh() {
    return (this.dataSource.data = this.Nomina);
  }

  public guardarNomina() {
    this.consultaNomina.lsFechaIni = this.formaNomina.value["lsFechaInicial"];
    this.consultaNomina.lsFechaFin = this.formaNomina.value["lsFechaFinal"];
    this.consultaNomina.tipoFecha = this.formaNomina.value["tipoFecha"];

    this.consultaNomina.ClaveAgrupadora = this.formaNomina.value[
      "lsCveAgrupadora"
    ];
    this.contadorIndex = 1;
    var con = $("#empleado").val();
    this.consultaNomina.index = "1";
    if (con == "") {
      this.consultaNomina.receptor = "0";
    }

    if (this.consultaNomina.lsFechaIni == null) {
      this.WarningErrorFechaInicial();
    } else if (this.consultaNomina.lsFechaFin == null) {
      this.WarningErrorFechaFinal();
    } else {
      this.enviarConsultaNomina(this.consultaNomina);
    }
    this.selection.clear();
  }

  enviarConsultaNomina(nom) {
    
    var Ini = this.formaNomina.value.lsFechaInicial;
    var Fin = this.formaNomina.value.lsFechaFinal;
    var a = this.ModalError(Ini, Fin);

    if (a != true) {
 
      this.isLoadingResults = true;
      this._consultaService.consultaNomina(nom).subscribe(data => {
   
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          this.Nomina = data.Nomina;
       

          this.validaciones(data.msg);

          if (this.iniciarCarga == true) {
            for (let index = 0; index < this.Nomina.length; index++) {
              this.NominaTemporal.push(data.Nomina[index]);
            }
            this.iniciarCarga = false;
            this.Nomina = this.NominaTemporal;

          }
          this.Nomina = this.refresh();


          this.isLoadingResults = false;
        }
      });
    } else {
    
    }
  }

  private ModalError(inicial, final) {
    var a = new Date(inicial);
    var b = new Date(final);

    var day = a.getFullYear();
    var day2 = b.getFullYear();
    
    if (+a > +b) {
      // this.MensajeDelModal =
      //   "La fecha inicial no puede ser mayor a la fecha final";
      // $("#modalError").modal("show"); //Open Modal
      swal({
        title: "Error de fechas",
        text: "La fecha inicial no puede ser mayor a la fecha final ",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    } else if (inicial == "") {
      // this.MensajeDelModal = "Especifique una fecha correcta";
      // $("#modalError").modal("show"); //Open Modal
      swal({
        title: "Error de fecha inicial",
        text: "El campo de fecha inicial ingresado es invalido ",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    } else if (final == "") {
      // this.MensajeDelModal = "Especifique una fecha correcta";
      // $("#modalError").modal("show"); //Open Modal
      swal({
        title: "Error de fecha final",
        text: "El campo de fecha final igresado es invalido",
        type: "error",
        confirmButtonText: "Entendido"
      });
      return true;
    }
    return false;
  }

  LIGAFINAL: string = "";
  public ObtenerPdfNomina(row) {
    

    this._consultaServiceComprobante
      .setPDF(this.Nomina[row].RutaFactura + ".pdf")
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          
          this.LIGAFINAL = data.Liga;
          $("#modalnomina").modal("show");
        }
      });
    error => console.log(error);

    row = null;
  }

  // expExel = true;
  // exportarExel(exel){
  //   this._consultaServiceComprobante.setPDF(exel).subscribe( data2 => {
  //     if (data2.session == 1) {
  //       this.CerrarSesion(data2.msg);
  //     } else {
        
  //       this.LIGAFINAL = data2.Liga;
  //       $("#modalnomina").modal("show");
  //     }
  //   });
  // }

  //SEGURIDAD URL
  getEmbedUrl() {
    //return this.sanitizer.bypassSecurityTrustResourceUrl('https://repobox.s3-us-west-2.amazonaws.com/Archivos/TGM110214QK0/2017-11/03709DD3-AEDD-4432-8156-22D774F87786.pdf?AWSAccessKeyId=AKIAI5YLRHV7QTYOX2QA&Expires=1528328193&Signature=OD8YLJwEfcnTe3S%2F3qnY7RGcbIU%3D');
  }

  //descarga el arrchivo zip de la factura
  public DercargarZip(id: any) {
    this.WargningInferior(
      "Ha comenzado la descarga del empleado " + this.Nomina[id].Receptor,
      "Espere un momento."
    );
    let folioFiscal = this.Nomina[id].FolioFiscal;
    let asd =  folioFiscal;
    this._consultaService
      .DescargaZipNomina(this.Nomina[id].RutaFactura)
      .subscribe(response => {
        var blob = new Blob([response], { type: "application/zip" });
        // saveAs(blob, "RepoBox_" + this.nombreUsuario + "_" + this.final+".zip");
        saveAs(
          blob,
          "RepoBox_" + this.nombreUsuario2 + "_" + folioFiscal + "_" + this.final + ".zip"
        );

    
      }),
      error => console.error(error);
  }

  public DercargarZipMultiple() {
    var arrayRow = this.fixArrayGetValue().toString();
    let numeroZip = arrayRow.split(",");
    if (arrayRow != "") {
      this.WargningInferiorPermanente(
        "Ha comenzado la descarga de " + numeroZip.length + " documento(s). ",
        "Espere un momento.",
        numeroZip.length
      );

      this._consultaService.DescargaZipNomina(arrayRow).subscribe(response => {
        var blob = new Blob([response], { type: "application/zip" });
        saveAs(blob, "RepoBox_" + this.nombreUsuario2 + "_"+ this.final);

        this.snackBar.dismiss();
        this.subscription.unsubscribe();
      }),
        error => console.error(error);
    } else {
      this.WarningSeleccione();
    }
  }

  //obtiene el id de la columna
  private fixArrayGetROW() {
    var files = $('input[name="filess"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].id.replace("-input", "");
    }

    return files_names;
  }

  //obtine el valor de la columna
  private fixArrayGetValue() {
    var files = $('input[name="filess"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value;
    }

    return files_names;
  }

  //obtiene la fecha actual
  private getDate1() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    var day = new Date(now.getFullYear(), now.getMonth(), 1);
    var today = now.getFullYear() + "-" + this.conseguirmes(mesFinal) + "-" + "0" + day.getDate();
    return (this.inicial = today.toString());
  }
  conseguirdia(dia) {
    
    if (dia.toString().length == 3) return dia.substring(1, 3);
    else return dia;
  }
  conseguirmes(mes) {
    
    if (mes.toString().length == 3) return mes.substring(1, 3);
    else return mes;
  }

  // conseguirdia(dia)
  // {
  //   
  //   if(dia.toString().length==1)
  //     return "0"+dia
  //   else
  //   return dia
  // }
  //obtiene la fecha actual
  private getDate2() {
    
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let dia = "0" + now.getDate().toString();

    // let diaBueno =dia.toString.length>=2? dia.toString().replace('0',''):dia.toString() ;
    let today =
      now.getFullYear() + "-" + this.conseguirmes(mesFinal) + "-" + this.conseguirdia(dia);

    return (this.final = today);
  }

  //DATEPICKER MATERIAL
  //  //obtiene la fecha actual
  // private getDate1() {
  //   let now = new Date();
  //   let month = (now.getFullYear(), now.getMonth()+1);
  //   let mesFinal = month.toString().length < 2 ?+month:month + "-"
  //   var day = new Date(now.getFullYear(), now.getMonth(), 1);
  //   let  today= new Date(now.getFullYear() + "-" +  mesFinal + "-" + day.getDate());
  //   console.log(today);
  //   return (this.inicial = today);
  // }
  // //obtiene la fecha actual
  // private getDate2() {
  //   let now = new Date();
  //   let dia = new Date();
  //    let month = (now.getFullYear(), now.getMonth()+1);
  //    let mesFinal = month.toString().length < 2 ?"0"+month:month + "-"
  //   // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  //   let  today= new Date(now.getFullYear() + "-" +  mesFinal + "-" + (dia.getDate()+1));

  //    console.log(today);
  //   return (this.final = today);
  // }

  //obtinene mas usuarios
  public CargarMas() {
    // if (

    // )

    if (this.Nomina[0] != null) {
      this.iniciarCarga = true;
      this.NominaTemporal = this.Nomina;
      this.contadorIndex = this.contadorIndex + 1;
      this.consultaNomina.index = this.contadorIndex.toString();
      this.enviarConsultaNomina(this.consultaNomina);
    } else {
      this.WarningConsulte();
    }


  }

  //obtine el filtrado de empleados
  SizeVal:number
  CargarEmpleados(e, value) {
   
    $("#lblProvedores").show(); // abre el mini modal de busqueda
    if (value == "") {
      this.offInput = "off";
    } else {
      if (value.length > 3) {
        this._consultaService.ConsultarEmpleados(value).subscribe(data => {
          if (data.session == 1) {
            this.CerrarSesion(data.msg);
          } else {
          
            this.empleadoTemp = data.UsuNomina;
            this.offInput = "on";
            $("#empleadosList").show()
            if(this.empleadoTemp.length>=4)
            {
              this.SizeVal=4
            }
            else if(this.empleadoTemp.length==1)
            {
              this.SizeVal=2
            }
            else{
              this.SizeVal=this.empleadoTemp.length
            }
  
          }
        });
        error => console.log(error);
      }
    }
  }

  //selecciona un empleado y lo coloca sobre el input
  selecUsu(id) {
  
    this.UsuarioSelected = this.empleadoTemp[id].Nombre;
    this.consultaNomina.receptor = this.empleadoTemp[id].Clave;
    this.offInput = "off";
    $("#empleadosList").hide()
  }

  descargarReporteExcel(){
    this.consultaNomina.lsFechaIni = this.formaNomina.value["lsFechaInicial"];
    this.consultaNomina.lsFechaFin = this.formaNomina.value["lsFechaFinal"];

    this.consultaNomina.ClaveAgrupadora = this.formaNomina.value[
      "lsCveAgrupadora"
    ];
    this.contadorIndex = 1;
    var con = $("#empleado").val();
    this.consultaNomina.index = "1";
    this.consultaNomina.empleado = con;
    if (con == "") {
      this.consultaNomina.receptor = "0";
    }

    if (this.consultaNomina.lsFechaIni == null) {
      this.WarningErrorFechaInicial();
    } else if (this.consultaNomina.lsFechaFin == null) {
      this.WarningErrorFechaFinal();
    } else {
      var Ini = this.formaNomina.value.lsFechaInicial;
    var Fin = this.formaNomina.value.lsFechaFinal;
    var a = this.ModalError(Ini, Fin);

    if (a != true) {
 
      this.isLoadingResults = true;
      this._consultaService.consultaReporteNomina(this.consultaNomina).subscribe(data => {
        this.isLoadingResults = false;
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          if(data.success){
            if(data.reporteBase64 === "Sin Comprobantes"){
              this.WarningConsultasVacias();
            }else{
              let fechaHoy = new Date();
              let fecha = (fechaHoy.getDate().toString() + "/" + ((fechaHoy.getMonth() + 1) < 10 ? "0" + (fechaHoy.getMonth() + 1) : fechaHoy.getMonth() + 1).toString() + "/" + fechaHoy.getFullYear().toString())
  
              let combinacion = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const linkSource = 'data:application/' + combinacion + ';base64,' + data.reporteBase64;
              const downloadLink = document.createElement("a");
              const fileName = "RepoBox_" + this.nombreUsuario2  + "_" + fecha +".xlsx";
        
              downloadLink.href = linkSource;
              downloadLink.download = fileName;
              downloadLink.click();
                // localStorage.removeItem("Token");
                // localStorage.setItem("Token", data.token[0] + "," + data.token[1]);
            }
          }else{
            if(data.permiso == 1){
              this.WarningMensajePersonaliazado("Sin permiso",data.msg);
            }else{
              this.WarningMensajePersonaliazado("Error",data.msg);

            }
            
          }
        }
      }, error =>{

      });
    } else {
    
    }
    }
  }

  private;
  validaciones(msg) {
    if (msg == "No se ha encontrado ningun comprobante de nomina") {
      this.WarningConsultasVacias();
    } else if (msg == "fechas") {
    }
  }

  private WarningMensajePersonaliazado(titulo : string, msg : string) {
    // this.MensajeDelModal = "No se encontraron facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: titulo,
      text: msg,
      type: "warning",
      confirmButtonText: "Entendido"
    });
    this.isLoadingResults = false;
  }
  private WarningConsultasVacias() {
    // this.MensajeDelModal = "No se encontraron facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "No se encontraron nominas",
      text: "Intente realizar una consulta distinta",
      type: "error",
      confirmButtonText: "Entendido"
    });
    this.isLoadingResults = false;
  }

  private WarningFechaIniMayor() {
    swal({
      title: "Error de fechas",
      text: "La fecha inicial no puede ser mayor a la fecha final ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningSeleccione() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Seleccione facturas",
      text: "Intente seleccionando al menos una nomina",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }

  private WarningErrorFechaInicial() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Error de fecha inicial",
      text: "Se debe ingresar un campo de fecha inicial ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningErrorFechaFinal() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Error de fecha final",
      text: "Se debe ingresar un campo de fecha final ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  public coloRow(i) {
    $("#tblTabla tr").removeClass("Seleccionado");
    $(i.currentTarget).addClass("Seleccionado");
  }
  private WarningConsulte() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "No se pueden cargar mas",
      text: "Intente realizando una consulta",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  private WargningInferior(message: string, action: string) {
    //NOMBRE REAL SNACKBAR
    this.snackBar.open(message, action, {
      duration: 2000
    });
  }
  subscription: any;
  barra: any;
  private WargningInferiorPermanente(
    message: string,
    action: string,
    numeroFacturas: number
  ) {
    //NOMBRE REAL SNACKBAR
    this.barra = 0;
    let intervalo = (numeroFacturas / 100) * 1000;
    var observable = Observable.interval(intervalo);
    this.subscription = observable.subscribe(x => {
      this.barra = x;
    });

    this.snackBar.open(message, action, {
      // duration: 2000,
    });
  }

  CerrarSesion(dataMensaje: string) {
    this.WarningPerzonalizado("Atencion!", dataMensaje);
    this._Auth0Service.logout();
  }
  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
}
