import { Component, OnInit, Input} from '@angular/core';
import { ComprobanteWeb, IComprobanteWeb, comprobanteValidacionWeb, ModeloXmlValidacion, Detalle } from '../../../components/Interfaces/modeloXmlValidacion';

@Component({
  selector: 'app-resultado-validacion-cfd',
  templateUrl: './resultado-validacion-cfd.component.html',
  styleUrls: ['./resultado-validacion-cfd.component.css']
})

export class ResultadoValidacionCFDComponent implements OnInit {
  @Input() ComprobanteWeb: ComprobanteWeb;
   //detalles:Detalle[]=this.ComprobanteWeb.ModeloXmlValidacion.Detalle;
  constructor() {
    this.ComprobanteWeb= new ComprobanteWeb();
  }
  ngOnInit() {

  }
}
