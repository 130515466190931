import { Component, OnInit } from '@angular/core';
import * as $ from "jquery"


@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.css']
})
export class PagoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    this.PagosOnline();

  }

  PagosOnline() { 

    $('.firstfour').change(function() {
      $('.cardImg').removeClass('active');
      var card = $('.firstfour').val();
      var firstNumber = card.substr(0,1);
      switch(firstNumber) {
        case '4': $('.visa').addClass('active');
            break;
        case '3': $('.amex').addClass('active');
            break;
        case '5': $('.mastercard').addClass('active');
            break;
        case '6':  $('.discover').addClass('active');
            break;
      }
  });
  $('.signIn').click(function(){
    $('.theCard').toggleClass('flipped');
  });
  
  
  $(document).ready(function () {
      var navListItems = $('div.setup-panel div a'),
          allWells = $('.setup-content'),
          allNextBtn = $('.nextBtn'),
          allPrevBtn = $('.prevBtn');
  
      allWells.hide();
  
      navListItems.click(function (e) {
          e.preventDefault();
          
          var $target = $($(this).attr('href')),
              $item = $(this);
  
          if (!$item.hasClass('disabled')) {
              navListItems.removeClass('btn-indigo').addClass('btn-default');
              $item.addClass('btn-indigo');
              allWells.hide();
              $target.show();
              $target.find('input:eq(0)').focus();
          }
          
      });
  
      allPrevBtn.click(function(){
          var curStep = $(this).closest(".setup-content"),
              curStepBtn = curStep.attr("id"),
              prevStepSteps = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().prev().children("a");
  
              prevStepSteps.removeAttr('disabled').trigger('click');
      });
  
      allNextBtn.click(function(){
          var curStep = $(this).closest(".setup-content"),
              curStepBtn = curStep.attr("id"),
              nextStepWizard = $('div.setup-panel div a[href="#' + curStepBtn + '"]').parent().next().children("a"),
              curInputs = curStep.find("input[type='text'],input[type='url']"),
              isValid = true;
  
          $(".form-group").removeClass("has-error");
          for(var i=0; i< curInputs.length; i++){
              if (!curInputs[i].validity.valid){
                  isValid = false;
                  $(curInputs[i]).closest(".form-group").addClass("has-error");
              }
          }
  
          if (isValid)
              nextStepWizard.removeAttr('disabled').trigger('click');
      });
  
      $('div.setup-panel div a.btn-indigo').trigger('click');
  });
  }

  }

