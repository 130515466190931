import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { validacionServices } from '../../services/validacion.service';

@Component({
  selector: 'app-irc-validacion',
  templateUrl: './irc-validacion.component.html',
  styleUrls: ['./irc-validacion.component.css']
})
export class IrcValidacionComponent {

  id:number;
  fecha:string;
  esPago:number;
  myVal:any=null;
  constructor(private activatedRoute:ActivatedRoute,private _validacionService:validacionServices) { 
    this.activatedRoute.params.subscribe(params=>{
      this.id= params['id'];
      this.fecha=params['fecha'];
      this.esPago=params['esPago'];
    })

      this._validacionService.validacion(this.id,this.fecha,this.esPago).subscribe(respuesta=>{
       if(respuesta!=null)
       this.myVal=respuesta;
      })
    }

}
