import { Component, OnInit ,Inject} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DialogData } from '../navbar/navbar.component';
@Component({
  selector: 'app-dialog-info-index',
  templateUrl: './dialog-info-index.component.html',
  styleUrls: ['./dialog-info-index.component.css']
})
export class DialogInfoIndexComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogInfoIndexComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit() {
  }

}
