import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { validacionServices } from '../../services/validacion.service';
import * as $ from 'jquery';
import swal from "sweetalert2";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ConsultasService } from '../../services/consultas.service';
import { saveAs } from "file-saver";
import { MatChipInputEvent } from '@angular/material';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
declare var $: any;

@Component({
  selector: 'app-ver-cfdi',
  templateUrl: './ver-cfdi.component.html',
  styleUrls: ['./ver-cfdi.component.css']
})
export class VerCFDIComponent implements OnInit {
  algo: string;
  uuid: string;
  fichero: string;
  rfcEmisor: string;
  rfcReceptor: string;
  myVal: any ;
  mostrarOpciones : boolean = false;
  isLoadingResults:boolean=false;
  emailvalue: FormGroup;
  emailpattern = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$";
  inputValue: any = [];
  constructor(
    private activatedRoute:ActivatedRoute,
    private _validacionService:validacionServices, 
    private _consultasService: ConsultasService,
    private router:Router) {
    this.validaEstilosViejos();
   }

  ngOnInit() {
    this.inicializarForms();
    this.decodificarParametro();
  }

  decodificarParametro(){
    this.isLoadingResults = true;
    this.activatedRoute.queryParams.filter(params => params.ID).subscribe(params =>{
      let base64 = params.ID;
      this.uuid = atob(base64);
      this.isLoadingResults = false;
    }, error =>{
      this.isLoadingResults = false;
    })
  }

  onUpload(){
    if(this.reactiveForm.valid){
      this.isLoadingResults = true;
      this._validacionService.validacionXml(this.uuid).subscribe(data =>{
        this.isLoadingResults = false;
        if(!isNullOrUndefined(data)){
          this.myVal = data.Cuerpo;
          this.fichero = data.Fichero
          this.rfcEmisor = data.RfcEmisor
          this.rfcReceptor = data.RfcReceptor
          this.mostrarOpciones = true;
        }
      }, error =>{
        this.isLoadingResults = false;
        console.log(error);
      })
    }
  }

  descargarZip(){
    this.isLoadingResults = true;
    this._consultasService.ZipServiceSinToken(this.fichero).subscribe( data =>{
      console.log(data);
      this.isLoadingResults = false;
      var blob = new Blob([data], { type: "application/zip" });
        saveAs(
          blob,
          "RepoBox_" +  this.uuid  + ".zip"
        );
      
    }, error=>{
      this.isLoadingResults = false;
      console.log(error);
    })
  }

  descargarPdf(){
    this.isLoadingResults = true;
    this._consultasService.GetFilePdfSinToken(this.fichero).subscribe( data =>{
      // console.log(data);
      this.isLoadingResults = false;
      // var blob = new Blob([data.archivoByte], { type: "application/pdf" });
      //   saveAs(
      //     blob,
      //     "RepoBox_" +  this.uuid  + ".pdf"
      //   );

      let combinacion =  "data";
      const linkSource = 'data:application/' + combinacion + ';base64,' + data.archivoByte;
      const downloadLink = document.createElement("a");
      const fileName = "Reporte_" + this.uuid +  ".pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }, error=>{
      this.isLoadingResults = false;
      console.log(error);
    })
  }

  enviarArchivos(){
    this.isLoadingResults = true;
    if (this.sendValue[0] != null) {
      $("#modalWait").modal("show");
      this._consultasService
        .EnviarArchivosSinToken(this.fichero, this.sendValue.toString(), this.rfcEmisor, this.rfcReceptor)
        .subscribe(data => {
          this.isLoadingResults = false;
            $("#modalWait").modal("hide");
            this.mensajeExistoso(
              "Los comprobantes se han enviado con éxito",
              "Si no ves el mensaje en la Bandeja de entrada, busca en la carpeta de correo no deseado o vuelve a comprobar el correo más tarde."
            );
        }, error =>{
          // error => console.error(error);
          this.isLoadingResults = false;
          this.inputValue.lenght = 0;
          this.WarningPerzonalizado(
            "Error",
            "Ocurrio un error"
          );
        });
      
    } else {
      $("#modalWait").modal("hide");
      this.WarningPerzonalizado(
        "Aun no ha agregado ningun correo",
        "Si ya escribio un correo presione la tecla enter para confirmarlo"
      );
    }
  }

  enviarPorEmail(){
      $("#exampleModalCorreo").modal("show");
  }

  public reactiveForm: FormGroup = new FormGroup({
    recaptchaReactive: new FormControl(null, [Validators.required])
    
  });

  inicializarForms(){
    this.emailvalue = new FormGroup({
      email: new FormControl("", [Validators.pattern(this.emailpattern)])
    });
    
  }
  
  asd(){
    let selected = this.algo ? this.algo :null
    this.router.navigate(['/CFDI',{ queryParams: { ID: selected}}])
  }

  public EliminarEmail(index) {
    this.inputValue.splice(index, 1);
  }

  // EMAIL
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  sendValue: any = [];
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || "").trim()) {
      if (value.indexOf("@", 0) > -1) {
        //SIRVE PARA LA VALIDACION DEL @ EN EL CORREO ELECTRONICO
        this.inputValue.push({ name: value.trim() });
        this.sendValue.push(value);
      }
    }

    // Reset the input value
    if (input) {
      if (value.indexOf("@", 0) > -1) {
        input.value = "";
      }
    }
  }
  // FIN EMAIL
  remove(fruit: any = []): void {
    const index = this.inputValue.indexOf(fruit);

    if (index >= 0) {
      this.inputValue.splice(index, 1);
      this.sendValue.splice(index, 1);
    }
  }

  paginaInicio(){
    window.location.href = "https://www.repobox.com.mx/#accede";
  }

  private mensajeExistoso(mensajeEncabezado, subMensaje) {
    swal(mensajeEncabezado, subMensaje, "success");
  }

  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }

  validaEstilosViejos(){
    $("#nuevo18" ).attr("href", "");
  }

}
