import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Auth0ServiceAdminService } from '../../services/auth0-service-admin.service';
import { AdminService } from '../../services/admin.service';
import swal from "sweetalert2";

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.css']
})
export class LoginAdminComponent implements OnInit {

  hide =true;
  forma: FormGroup;
  constructor(private router: Router, private _Auth0ServiceAdmin : Auth0ServiceAdminService, private adminService: AdminService) { }

  ngOnInit() {
    this.forma = new FormGroup({
      email: new FormControl("", [Validators.minLength(5)]),
      Password: new FormControl("", [Validators.minLength(4)])
    });
  }

  guardar(){
    if(this.forma.valid){
      let email = this.forma.value["email"];
      let password = this.forma.value["Password"];
      this.adminService.Login(email, password).subscribe( data => {
        let resp = this._Auth0ServiceAdmin.login(data);
        if(resp == "esAdmin"){
          localStorage.setItem("tipo", "esAdmin");
          this.router.navigate(["/admin/LicenciaUsuario"]);
        }else{
          swal({
            title: data.msg,
            text: "Favor de verificar sus datos",
            type: "question",
            confirmButtonText: "Entendido"
          });
        }
      })
    }else{
      console.log("Error al ingresar");
    }
  }
}
