import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import * as $ from "jquery";
import { ConsultasService } from '../../../../../../services/consultas.service';
import { SweetAlertModals } from '../../../../../Clases/alert';
declare var jQuery: any;
declare var $: any;
import { saveAs } from "file-saver";
/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-consulta-certificado',
  templateUrl: './consulta-certificado.component.html',
  styleUrls: ['./consulta-certificado.component.css']
})

export class ConsultaCertificadoComponent implements OnInit {
  displayedColumns: string[] = ['Serie','FechaAlta' ,'Vencimiento','Editar'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  Alerts:SweetAlertModals;
  ngDataCertificado : any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private _ConsultasService : ConsultasService){}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.Alerts= new SweetAlertModals();
  }
  ngAfterViewInit(): void {
    this.ConsultarCertificado();
  }

  mostrarModalCer(){
    $("#modalConsultaCertificado").modal("hide"); 
    $('#modalAltaFiel').modal('show');
  }

  public ConsultarCertificado(){
    let rfc: string  =  localStorage.getItem("nombreUsu");
    this._ConsultasService.ConsultarCertificado(rfc).subscribe(data =>{
        if(data.success !=true){
          this.Alerts.ModalWarning("Ocurrio un problema",data.msg)
        }            
        if(data.certificado == true){
          this.ngDataCertificado =data.data;
          $('#modalConsultaCertificado').modal('show');
          localStorage.setItem("certificadoCSD","true");
        }
        if(data.certificado == false){
          $('#modalAltaFiel').modal('show');
        }
    });
  }

  descargarCertificado(){
    
    let rfc: string  =  localStorage.getItem("nombreUsu");
    this._ConsultasService.descargarCertificado(rfc, "CSD").subscribe(data =>{     
        var blob = new Blob([data], { type: "application/zip" });
      saveAs(
        blob,
        "RepoBox_" + rfc + "_" + "CSD" + ".zip"
      );
      
  });
  }


}

export interface PeriodicElement {
  Serie: string;
  Vencimiento: string;

}

const ELEMENT_DATA: PeriodicElement[] = [
  {Serie: "1", Vencimiento: 'Hydrogen'},
];
