
import { Injectable } from "@angular/core";
import { RegistroModelo } from "../Interfaces/formulario.comprobante";
import { Http, Headers,HttpModule } from "@angular/http";
import { HttpEventType } from '@angular/common/http';
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;

import "rxjs/Rx";
import { map } from 'rxjs/operators';

@Injectable()
export class indexService {
//=================================
//Cadenas de conexion
//=================================
  conexion:string=ConexionApisRepoBox;


  private urlEnviarContacto:string = this.conexion+"api/Servicio/EnviarAContacto"
  private urlRegistroIndex:string = this.conexion+"api/Servicio/registroindex";
  private _uploadCFDI:string = this.conexion+"api/Servicio/uploadCFDI";
  constructor(private http: Http) {}
//=================================
//Registra un nuevo usuario repobox
//=================================
  public Registrar(comp: RegistroModelo) {
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    return this.http 
      .post(this.urlRegistroIndex, comp, { headers: headerOptions })
      .map(x => x.json()); 
  } 
 
//=============================================================================
//Metodo para que el cliente pueda comunicarse con soporte repobox (Index.html)
//=============================================================================
   public EnviarAContacto(nombre,email,mensaje,empresa,telefono) {
     
    var headerOptions = new Headers({ "Content-Type": "application/json" });
   
    return this.http.post(this.urlEnviarContacto+"/?nombre="+nombre+"&email="+email+"&empresa="+empresa+"&telefono="+telefono,JSON.stringify(mensaje),{ headers: headerOptions }).map(x => x.json());
  }
//=============================================================================
//Metodo para verificar CFDI
//=============================================================================

  public uploadCFDI(cfdiObject :FormData) {
  
    //var body = JSON.stringify(dato);
    var headerOptions = new Headers({ "Content-Type": "multipart/form-data" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
  
    return this.http
      .post(this._uploadCFDI, cfdiObject) 
      .map(x => x.json());
      
  }
}
