import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatTableDataSource} from '@angular/material';
import * as $ from "jquery";
import { ConsultasService } from '../../../../services/consultas.service';
import { SweetAlertModals } from '../../../Clases/alert';
declare var jQuery: any;
declare var $: any;
import { saveAs } from "file-saver";

@Component({
  selector: 'app-consulta-fiel',
  templateUrl: './consulta-fiel.component.html',
  styleUrls: ['./consulta-fiel.component.css']
})
export class ConsultaFielComponent implements OnInit {
  displayedColumns: string[] = ['Serie','FechaAlta' ,'Vencimiento','Editar'];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  Alerts:SweetAlertModals;
  ngDataCertificado : any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private _ConsultasService : ConsultasService){}

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.Alerts= new SweetAlertModals();
  }
  ngAfterViewInit(): void {
    this.ConsultarCertificado();
    console.log("asd");
    
  }


  mostrarModalCer(){
    $("#modalConsultaCertificadoFiel").modal("hide"); 
    $('#modalAltaFielF').modal('show');
  }

  public ConsultarCertificado(){
    let rfc: string  =  localStorage.getItem("nombreUsu");
    this._ConsultasService.ConsultarCertificado(rfc, "FIEL").subscribe(data =>{
        if(data.success !=true){
          this.Alerts.ModalWarning("Ocurrio un problema",data.msg)
        }            
        if(data.certificado == true){
          this.ngDataCertificado =data.data;
          $('#modalConsultaCertificadoFiel').modal('show');
          localStorage.setItem("certificadoFiel","true");
        }
        if(data.certificado == false){
          $('#modalAltaFielF').modal('show');
        }
    });
  }

  descargarCertificado(){
    
    let rfc: string  =  localStorage.getItem("nombreUsu");
    this._ConsultasService.descargarCertificado(rfc, "FIEL").subscribe(data =>{    
     
        var blob = new Blob([data], { type: "application/zip" });
      saveAs(
        blob,
        "RepoBox_" + rfc + "_" + "FIEL" + ".zip"
      );
      
  });
  }

}

export interface PeriodicElement {
  Serie: string;
  Vencimiento: string;

}

const ELEMENT_DATA: PeriodicElement[] = [
  {Serie: "1", Vencimiento: 'Hydrogen'},
];

