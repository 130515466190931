import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class Auth0ServiceAdminService implements CanActivate {

  isLogged:boolean;
  usuarioName:any;
  constructor( private router:Router) {  this.isLogged = false;}
  isloged(){
    var aValue = localStorage.getItem("token");
    let usuario=localStorage.getItem("tipo");
      if(aValue!=null && usuario=="esAdmin"){
        return this.isLogged=true
      }
      else{
        return this.isLogged=false;
      }
  }
  public login (admin){
      this.isLogged = true;
      if(admin.loAdmin != undefined){
        localStorage.setItem("token",admin.token);
        return "esAdmin";
      }else{
        return "error";
      }
  }
    public logout(){
      this.isLogged = false;
      localStorage.removeItem("token");
      localStorage.removeItem("tipo");
      this.router.navigate([""]);
    }

    public canActivate( ){
      if( this.isloged() ){
        return true;
      }else{
        this.router.navigate([""]);
        return false;
      }
    }
}
