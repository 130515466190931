import { Injectable } from '@angular/core';
import { Http, Response, Headers } from "@angular/http";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private conexionApis : any = ConexionApisRepoBox;
  private ValidarLoginUri: string = this.conexionApis + "api/admin/LoginAdmin";
  

  constructor(private http: Http) { }

  Login(user, pass) {
    let url = this.ValidarLoginUri;
    let usuario = user + "," + pass;
    var body = JSON.stringify(usuario);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
}
