import { Component, OnInit } from "@angular/core";
import { MatTableDataSource, MatSnackBar } from "@angular/material";
import { ConsultasService } from "../../services/consultas.service";
import * as $ from "jquery";
import swal from "sweetalert2";
import { Auth0Service } from "../../services/auth0.service";
import { buzonService } from "../../services/buzon.service";
import {
  AceptacionRechazoModel,
  PendientesModel
} from "../../Interfaces/formulario.comprobante";
import { saveAs } from "file-saver";
import { SweetAlertModals } from '../Clases/alert';
import { FormControl } from '@angular/forms';
declare var $: any;
@Component({
  selector: "app-buzon-cancelacion",
  templateUrl: "./buzon-cancelacion.component.html",
  styleUrls: ["./buzon-cancelacion.component.css"]
})
export class BuzonCancelacionComponent implements OnInit {
  comprobante: any[] = [];
  SonRecibosPagos: boolean = false;
  ComplementoDePago: any = [];
  Pagado: any;
  Total: any = 0;
  Saldo: any = 0;
  TotalComplemento: any = 0;
  StatusComprobante: any = 0;
  fechacancelacion: String = "";
  comprobanteIndividual: any;
  LIGAFINAL: string = null;
  menuTableFact: boolean = false;
  idContextMenu: number = 0;
  indiceContextMenu: number = 0;
  fechaAcuseValidacion: string;
  nombreUsuario: string;
  alerts:SweetAlertModals;
  final: string = this.getDate2();
  ngMostrarAltaCer: boolean = false;
  passwordValidad : boolean = false;
  passwordForm : FormControl = new FormControl();

  uuidCargado : any;
  indiceCargado : any;
  tipoCargado : any;

  style1 : string ='margin: 0.5em 1.5em 0 1.5em';
  style2 : string ='margin: 2.5em 1.5em 0 1.5em';

  displayedColumns: string[] = [
    "RFC",
    "Cliente",
    "Serie",
    "Folio",
    "Fecha",
    "Total",
    "Val",
    //"COM",
    "ZIP",
    "Intentos",
    "Refresh"
  ];

  ModeloAcepRecha: AceptacionRechazoModel = {
    aceptados:[],
    rechazados: [],
    rfcConsulta: ""
  };
  isLoadingResults : boolean = true;
  constructor(
    private _consultasService: ConsultasService,
    private _buzonService: buzonService,
    public snackBar: MatSnackBar,
    public _Auth0Service: Auth0Service
  ) {
    this.alerts= new SweetAlertModals();
  }

  dataSource = new MatTableDataSource<any>();

  refresh() {
    return (this.dataSource.data = this.comprobante);
  }
  ngOnInit() {
    this.Refrescar();
  }

  public ConsultarCertificado(){
    let password = this.passwordForm.value;
    let rfc: string  =  localStorage.getItem("nombreUsu");
    this._consultasService.ValidarPasswordCer(rfc, password).subscribe(data =>{
        if(data.success !=true){
          this.alerts.ModalWarning("Ocurrio un problema",data.msg);
          return false;
        }            
        if(data.certificado == true){
          this.passwordValidad = data.autorizado;
          if(!this.passwordValidad){
            this.alerts.ModalWarning("Atención","La contraseña no coincide con la del CSD");
          }else{
            this.OpcionSiNOAceptar(this.uuidCargado, this.indiceCargado, this.tipoCargado);
          }
        }else{
          this.AltaCertificado();
          $("#modalVerificarPassword").modal("hide"); 
        }
    });
  }
  
  Refrescar() {
    this._buzonService
      .GetCFDIPendientes(localStorage.getItem("nombreUsu"))
      .subscribe(data => {
        this.comprobante = data.data;
        this.refresh();
        this.isLoadingResults = false;
      });
    error => console.error(error);
  }

  public GetIdRFC(id: any) {
    this._consultasService
      .EnviarID(this.comprobante[id].Clave, this.SonRecibosPagos)
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          this.ComplementoDePago = data.resultado;
          console.log("escomeplemento");
          var total = 0;
          for (let index = 0; index < this.ComplementoDePago.length; index++) {
            //Realiza la sumatoria de los montos
            var element = this.ComplementoDePago[index].ImpPagadoDet;
            total = total + element;
          }

          //Este es el total de la factura
          this.Pagado = total;

          if (this.Pagado == -1) {
            this.Pagado = 0;
          }

          //Saldo:  este es el total de la factura menos lo pagado
          this.Saldo = this.ComplementoDePago[0].Total - this.Pagado;

          //Factura: este es el total de la factura
          this.TotalComplemento = this.ComplementoDePago[0].Total;

          //Este es el estatus de la factura
          this.StatusComprobante = this.ComplementoDePago[0].StatusComprobante;

          // Validacion si la factura contiene complemento de pago

          if (
            this.ComplementoDePago[0].FechaCancelacion != 0 ||
            this.ComplementoDePago[0].FechaCancelacion != null
          ) {
            this.fechacancelacion = this.ComplementoDePago[0].FechaCancelacion;
          }

          if (this.ComplementoDePago[0].Errores == -1)
            if (this.ComplementoDePago[0].IDPago == 0) {
              this.ComplementoDePago = null;
            }
        }
      });
    error => console.error(error);

    this.comprobanteIndividual = this.comprobante[id];
  }

  CerrarSesion(dataMensaje: string) {
    this.WarningPerzonalizado("Atencion!", dataMensaje);
    this._Auth0Service.logout();
  }

  private total() {
    this.Total = 0;

    for (let index = 0; index < this.comprobante.length; index++) {
      this.Total = this.Total + this.comprobante[index].Total;
    }
  }
  public verComprobante(id: any,tipo:number) {

    // if(tipo==7)
    // {
    //   this.verCOMReciboPagos(id)
    // }
    // else{
    this.LIGAFINAL = null;

    this._consultasService
      .setPDF(id.replace(".xml", ".pdf"))
      .subscribe(data => {
        
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
          if(this.LIGAFINAL != null)
          this.LIGAFINAL=null;
          this.LIGAFINAL = data.Liga;
          $("#modalXCOMB").modal("show");
        }
      });
    error => console.error(error);
    id = null;
    }
  // }
//   public verCOMReciboPagos(id: any) {
//     this.LIGAFINAL = "";
//     let ruta = id.replace("BUCKET", "Archivos/ComplementosDePagos");
  
//   let rutaFinal 
// if(!ruta.includes("_ComplementoPago.xml"))
// {
//   rutaFinal=ruta.replace(".xml", "_ComplementoPago.pdf")
// }
// else
// {
//   rutaFinal=ruta.replace(".xml", ".pdf")
// }
 
//     this._consultasService
//       .setPDFComplemento(rutaFinal)
//       .subscribe(data => {
//         if (data.session == 1) {
//           this.CerrarSesion(data.msg);
//         } else {
//           var ligatemp = data.Liga;
//           this.LIGAFINAL = data.Liga;
//           $("#modalXCOMB").modal("show");
//         }
//       });
//     error => console.error(error);
//     id = null;
//   }
  public mostrarValidacion(e, id: string) {
    e.preventDefault();
    this.coloRow(e);
    this.menuTableFact = true;

    let columna = $(e.target).parents("tr")[0].id;
    let fecha = $(e.target).parents("tr")[0].title;
    fecha = fecha.split("\n");
    this.fechaAcuseValidacion = fecha[0]
      .replace("Fecha de entrada: ", "")
      .replace("/", "_")
      .replace("/", "_");
    columna = columna.split(",");
    this.idContextMenu = columna[1];

    this.indiceContextMenu = columna[0];

    $("#menucontext").css("display", "block");
    $("#menuTablafact").css({ display: "block", left: e.pageX, top: e.pageY });
  }
  public coloRow(i) {
    $("#tblBuzon tr").removeClass("Seleccionado");
    $(i.currentTarget).addClass("Seleccionado");
  }
  //descarga el arrchivo zip de la factura
  public DercargarZip(nombre, fichero: any,tipo:number) {
    if(tipo==7)
    {
      this.DescargarReciboPagos(nombre,fichero)
    }
    else{
    this.WargningInferior(
      "Ha comenzado la descarga del cliente\n" + nombre,
      "Espere un momento.",
      2000
    );
    this._consultasService.ZipService(fichero).subscribe(response => {
      var blob = new Blob([response], { type: "application/zip" });
      saveAs(blob, "RepoBox_" +localStorage.getItem("nombreUsu") + "_" + this.final + ".zip");
    }),
      error => console.error(error);
  }
  }
  public DescargarReciboPagos(nombre, id: any) {
    //let ruta = id.replace("BUCKET", "BUCKET/ComplementosDePagos");
    let ruta = id.replace("Archivos","BUCKET");
  
    let rutaFinal 
  if(!ruta.includes("_ComplementoPago.xml"))
  {
    rutaFinal=ruta.replace(".xml", "_ComplementoPago.pdf")
  }
  else
  {
    rutaFinal=ruta
  }
    this.WargningInferior(
      "Ha comenzado la descarga del cliente\n" + nombre,
      "Espere un momento.",
      2000
    );
    this._consultasService
      .ZipService(rutaFinal)
      .subscribe(response => {
        var blob = new Blob([response], { type: "application/zip" });
        saveAs(
          blob,
          "RepoBox_" + localStorage.getItem("nombreUsu") + "_" + this.final + ".zip"
        );

        // var urlToOpen = URL.createObjectURL(blob);

        // window.open(urlToOpen);
      }),
      error => console.error(error);
  }





  conseguirdia(dia) {
    if (dia.toString().length == 3) return dia.substring(1, 3);
    else return dia;
  }
  conseguirmes(mes) {
    if (mes.toString().length == 3) return mes.substring(1, 3);
    else return mes;
  }

  //obtiene la fecha actual
  private getDate2() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let dia = "0" + now.getDate().toString();

    // let diaBueno =dia.toString.length>=2? dia.toString().replace('0',''):dia.toString() ;
    let today =
      now.getFullYear() +
      "-" +
      this.conseguirmes(mesFinal) +
      "-" +
      this.conseguirdia(dia);

    return (this.final = today);
  }
  private WargningInferior(message: string, action: string, duracion: number) {
    //NOMBRE REAL SNACKBAR
    this.snackBar.open(message, action, {
      duration: duracion
    });
  }
  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
  AceptarRechazar(UUID, indice, tipo:string) {
    this.ModeloAcepRecha == null;
    if(tipo == "aceptar")
    this.ModeloAcepRecha.aceptados.push(UUID);
    else if(tipo == "rechazar")
    this.ModeloAcepRecha.rechazados.push(UUID);
    this.ModeloAcepRecha.rfcConsulta = localStorage.getItem("nombreUsu");

    this._buzonService
      .acepatarCancelacion(this.ModeloAcepRecha)
      .subscribe(data => {
        
        if(data.success == false){
          
          if (data.msg == "No se encontro certificado"){
            this.alerts.ModalError(data.msg, "");
            this.AltaCertificado()
            return; 
          }
          else{
            this.alerts.ModalError("ERROR", data.msg)
          }
          
        }
        else{
          swal(
            'Listo!',
            'Usted ha '+ (tipo === 'aceptar' ? 'aceptado': 'rechazado' )+' la cancelacion',
            'success'
          );
          this.dataSource.data.splice(indice,1)
          this.dataSource.data=this.dataSource.data;

        }
      }, error =>{
        error => console.error(error);

      });

  }

  public OpcionSiNOAceptar(UUID, indice, tipo : string){
    if(this.passwordValidad){
      swal({
        title: '¿Estas seguro de '+tipo+'?',
        text: "Despues de "+tipo+ " la solicitud los cambios no podran ser revertidos",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aceptar',
        cancelButtonText:'Cancelar',
      }).then((result) => {
        if (result.value) {
          this.AceptarRechazar(UUID,indice,tipo)
          
        }
      });
    }else{
      this.uuidCargado = UUID;
      this.indiceCargado = indice;
      this.tipoCargado = tipo;
      $('#modalVerificarPassword').modal('show');
    }
  }

  public AltaCertificado(){
    swal({
      title: 'No se encontro certificado',
      text: "Es necesario previamente haber dado de alta un certificado, para poder aceptar o rechazar solicitudes",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Dar de alta',
      cancelButtonText:'Mas tarde',
    }).then((result) => {
      if (result.value) {
        
      $("#modalAltaFiel").modal()
      }
    });
  }
}
