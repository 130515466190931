import {SelectionModel} from '@angular/cdk/collections';
import {Component, OnInit} from '@angular/core';
import { Auth0Service } from "../../services/auth0.service";
import {MatTableDataSource} from '@angular/material';
import { ConsultaNomina } from "../../Interfaces/formulario.comprobante";
import { FormGroup, FormControl } from "@angular/forms";

import { DomSanitizer } from "@angular/platform-browser";
import { saveAs } from "file-saver";
import * as $ from "jquery";
import {CustomValidators } from "ng4-validators";
import swal from "sweetalert2";
import { NominaService } from '../../services/nomina.service';
import { ConsultasService } from '../../services/consultas.service';



declare var jQuery: any;
declare var $: any;
export interface PeriodicElement {
  nombre: string;
  rfc: string;
  serie: number;
  folio: string;
}

@Component({
  selector: 'app-nomina-empleado',
  templateUrl: './nomina-empleado.component.html',
  styleUrls: ['./nomina-empleado.component.css']
})
export class NominaEmpleadoComponent implements OnInit {
  formaNomina: FormGroup;
  Nomina: any = [];
  displayedColumns: string[] = [   
    "serie",
    "folio",
    "fecha",
    "fechaInicioFin",
    "pago",
    "percepciones",
    "deducciones",
    "isr",
    "total",
    "pdf",
    "zip",
    "select"
  ];
  isLoadingResults:boolean=false;
  UrlLiga: string = "";
   // inicial: Date = new Date(this.getDate1()) 
  // final: Date = new Date(this.getDate2()) 
  inicial: string = this.getDate1();
  final: string = this.getDate2();
  UsuarioSelected: any;
  empleadoTemp: any = [];
  chartz: any = [];
  offInput: string;
  iniciarCarga: boolean;
  NominaTemporal: any = [];
  contadorIndex: number = 1;

  selection = new SelectionModel<PeriodicElement>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnInit() {
    
    
    
    $(document).on("click",".close", function () {
      this.LIGAFINAL="";
    });
    $(document).on("click",".modal-backdrop, .fade, .show", function () {
      this.LIGAFINAL="";
    });
    this.UsuarioSelected= this._Auth0Service.usuarioNamePagina();

    let modaltrue=  localStorage.getItem("ModalContra");

    if(modaltrue=='0')
    {  $("document").ready(function() {
      $("#cambiarContrasena").modal("show");
    });
}

  

  }

  refresh() {
    return (this.dataSource.data = this.Nomina);
  }

  consultaNomina: ConsultaNomina = {
    lsFechaIni: "",
    lsFechaFin: "",
    receptor: this.UsuarioSelected,
    index: "1",
    ClaveAgrupadora:"",
    empleado: "",
    tipoFecha: 1
  };

  constructor(
    private _consultaService: NominaService,
    private _consultaServiceComprobante: ConsultasService,
    public sanitizer: DomSanitizer,
    public _Auth0Service: Auth0Service,private _NominaService: NominaService  ) {
    this.formaNomina = new FormGroup({
      lsFechaInicial: new FormControl(
        this.inicial,
        CustomValidators.minDate("2005-01-01")
      ),
      lsFechaFinal: new FormControl(
        this.final,
        CustomValidators.minDate("2005-01-01")
      ),
      lsCveAgrupadora: new FormControl(""),
      tipoFecha: new FormControl("1")
    });
  }

  dataSource = new MatTableDataSource<any>();

  
  public guardarNomina() {
    
    this.consultaNomina.lsFechaIni = this.formaNomina.value["lsFechaInicial"];
    this.consultaNomina.lsFechaFin = this.formaNomina.value["lsFechaFinal"];
    this.consultaNomina.tipoFecha = this.formaNomina.value["tipoFecha"];
    this.consultaNomina.receptor = "0";

    this.consultaNomina.ClaveAgrupadora = this.formaNomina.value[
      "lsCveAgrupadora"
    ];
    this.contadorIndex = 1;
    this.consultaNomina.index = "1";
    

  
    if (this.consultaNomina.lsFechaIni == null) {
      this.WarningErrorFechaInicial();
    } else if (this.consultaNomina.lsFechaFin == null) {
      this.WarningErrorFechaFinal();
    } else {
      this.enviarConsultaNomina(this.consultaNomina);
    }
  }

  enviarConsultaNomina(nom) {
    var Ini = new Date(this.formaNomina.value.lsFechaInicial);
    var Fin = new Date(this.formaNomina.value.lsFechaFinal);
    if (Ini > Fin) {
      
      this.WarningFechaIniMayor();
    } else {
      this.isLoadingResults=true;

      this._consultaService.consultaNomina(nom).subscribe(data => {
        debugger
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
        
        this.Nomina = data.Nomina;

        this.validaciones(data.msg);

        if (this.iniciarCarga == true) {
          for (let index = 0; index < this.Nomina.length; index++) {
            this.NominaTemporal.push(data.Nomina[index]);
          }
          this.iniciarCarga = false;
          this.Nomina = this.NominaTemporal;


        }
        this.Nomina = this.refresh();
        this.consultaNomina.receptor = "0";

        this.isLoadingResults=false;}
      });
    }
    this.selection.clear();
  }
  
  LIGAFINAL:string="";
  public ObtenerPdfNomina(row) {
    this.LIGAFINAL="";
    this._consultaServiceComprobante
      .setPDF(this.Nomina[row].RutaFactura + ".pdf")
      .subscribe(data => {
        if (data.session == 1) {
          this.CerrarSesion(data.msg);
        } else {
        this.LIGAFINAL= data.Liga;
        $("#modalnominaUS").modal("show")
        }
      });
    error => console.log(error);

    this._NominaService.UpdatePdf(this.Nomina[row].Clave).subscribe(data=>{
      if (data.session == 1) {
        this.CerrarSesion(data.msg);
      } else {
   }
    })
    row = null;
  }

  private fixArrayID() {
    var files = $('input[name="filesN"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value;
    }


    return files_names;
  }

  public DescargarMultiCheckBox() {
    

    var elzip = this.fixArrayID().toString();

    if (elzip != "") {
      this._consultaServiceComprobante
        .ZipService(elzip.toString())
        .subscribe(response => {
          
          var blob = new Blob([response], { type: "application/zip" });
          saveAs(blob, "RepoBox_" + this.UsuarioSelected + "_" + this.final);

          var urlToOpen = URL.createObjectURL(blob);

          window.open(urlToOpen);
        }),
        error => console.error(error);
    } else
    if(this.Nomina.toString()=="")
    {
      this.WarningRealizeUnaConsulta();
    }else
     {
     
      this.WarningSeleccioneFacturas();
    }
  }
  //SEGURIDAD URL
  getEmbedUrl() {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.UrlLiga);
    //return this.sanitizer.bypassSecurityTrustResourceUrl('https://repobox.s3-us-west-2.amazonaws.com/Archivos/TGM110214QK0/2017-11/03709DD3-AEDD-4432-8156-22D774F87786.pdf?AWSAccessKeyId=AKIAI5YLRHV7QTYOX2QA&Expires=1528328193&Signature=OD8YLJwEfcnTe3S%2F3qnY7RGcbIU%3D');
  }

  //descarga el arrchivo zip de la factura
  public DercargarZip(id: any) {
    

    this._consultaService
      .DescargaZipNomina(this.Nomina[id].RutaFactura)
      .subscribe(response => {
        
        var blob = new Blob([response], { type: "application/zip" });
        // saveAs(blob, "RepoBox_" + this.nombreUsuario + "_" + this.final+".zip");
        saveAs(blob, "RepoBox_-_-_.zip");

      }),
      error => console.error(error);
  }

  public DercargarZipMultiple() {
    

    var arrayRow = this.fixArrayGetROW().toString();
    if (arrayRow != "") {
      this._consultaService.DescargaZipNomina(arrayRow).subscribe(response => {
        
        var blob = new Blob([response], { type: "application/zip" });
        saveAs(blob, "RepoBox_");

      }),
        error => console.error(error);
    } else {
      this.WarningSeleccione();
    }
  }

  private  WarningSeleccioneFacturas() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: 'Seleccione facturas',
      text: 'Intente seleccionando al menos una factura',
      type: 'question',
      confirmButtonText: 'Entendido'
    })
  }
  private WarningRealizeUnaConsulta() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: 'Realize una consulta',
      text: 'Intente seleccionando al menos una factura',
      type: 'question',
      confirmButtonText: 'Entendido'
    })
  }

  //obtiene el id de la columna
  private fixArrayGetROW() {
    
    var files = $('input[name="filesN"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].id.replace("-input","");
    }


    return files_names;
  }

  //obtine el valor de la columna
  private fixArrayGetValue() {
    
    var files = $('input[name="filesN"]:checked');
    var files_names = [];
    for (var i = 0; i < files.length; i++) {
      files_names[i] = files[i].value;
    }


    return files_names;
  }

  //obtiene la fecha actual
  private getDate1() {
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    var day = new Date(now.getFullYear(), now.getMonth(), 1);
    var today = now.getFullYear() + "-" + this.conseguirmes(mesFinal) + "-" + "0" + day.getDate();

    return (this.inicial = today.toString());
  }
  conseguirdia(dia)
  {

    if(dia.toString().length==3)
      return dia.substring(1,3)
    else
    return dia
  }
  conseguirmes(mes) {
    
    if (mes.toString().length == 3) return mes.substring(1, 3);
    else return mes;
  }
  // conseguirdia(dia)
  // {
  //   if(dia.toString().length==1)
  //     return "0"+dia
  //   else
  //   return dia
  // }
  //obtiene la fecha actual
  private getDate2() {
 
    let now = new Date();
    let month = (now.getFullYear(), now.getMonth() + 1);
    let mesFinal = month.toString().length <= 2 ? "0" + month : month + "-";
    // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    let dia= "0"+now.getDate().toString() ;
   
    // let diaBueno =dia.toString.length>=2? dia.toString().replace('0',''):dia.toString() ;
    let today = now.getFullYear() + "-" + this.conseguirmes(mesFinal) + "-"  +  this.conseguirdia(dia);
 
    return (this.final = today);
  }

  //DATEPICKER MATERIAL
  //  //obtiene la fecha actual 
  // private getDate1() {
  //   let now = new Date();
  //   let month = (now.getFullYear(), now.getMonth()+1);
  //   let mesFinal = month.toString().length < 2 ?+month:month + "-" 
  //   var day = new Date(now.getFullYear(), now.getMonth(), 1);
  //   let  today= new Date(now.getFullYear() + "-" +  mesFinal + "-" + day.getDate());
  //   console.log(today);
  //   return (this.inicial = today);
  // }
  // //obtiene la fecha actual
  // private getDate2() {
  //   let now = new Date();
  //   let dia = new Date();
  //    let month = (now.getFullYear(), now.getMonth()+1);
  //    let mesFinal = month.toString().length < 2 ?"0"+month:month + "-" 
  //   // var day = new Date(now.getFullYear(), now.getMonth() + 1, 0);
  //   let  today= new Date(now.getFullYear() + "-" +  mesFinal + "-" + (dia.getDate()+1));
 
    
  //    console.log(today);
  //   return (this.final = today);
  // }

  
  private validaciones(msg) {
    if (msg == "No se ha encontrado ningun comprobante de nomina") {
      this.WarningConsultasVacias();
    } else if (msg == "fechas") {
    }
  }

  private WarningConsultasVacias() {
    // this.MensajeDelModal = "No se encontraron facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "No se encontraron facturas",
      text: "Intente realizar una consulta distinta",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }

  private WarningFechaIniMayor() {
    swal({
      title: "Error de fechas",
      text: "La fecha inicial no puede ser mayor a la fecha final ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningSeleccione() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Seleccione facturas",
      text: "Intente seleccionando al menos una factura",
      type: "question",
      confirmButtonText: "Entendido"
    });
  }

  private WarningErrorFechaInicial() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Error de fecha inicial",
      text: "Se debe ingresar un campo de fecha inicial ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  private WarningErrorFechaFinal() {
    // this.MensajeDelModal = "Seleccione facturas";
    // $("#modalError").modal("show"); //Open Modal
    swal({
      title: "Error de fecha final",
      text: "Se debe ingresar un campo de fecha final ",
      type: "error",
      confirmButtonText: "Entendido"
    });
  }
  public coloRow(i){
    
    $("#tblTabla tr").removeClass("Seleccionado");
    $(i.currentTarget).addClass("Seleccionado");
  
  }
  CerrarSesion(dataMensaje:string) {
    this.WarningPerzonalizado("Atencion!",dataMensaje);
    this._Auth0Service.logout();
  }
  private WarningPerzonalizado(titulo, texto) {
    swal({
      title: titulo,
      text: texto,
      type: "question",
      confirmButtonText: "Entendido"
    });
  }
}
