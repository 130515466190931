import { Injectable } from "@angular/core";
import {ConsultaComprobante, ConsultaNomina, ModelDescargaMasiva} from "../Interfaces/formulario.comprobante";
import { Http, Response, Headers } from "@angular/http";
import { ResponseContentType } from "@angular/http";
var ConexionApisRepoBox = require('../services/Conexion').ConexionApisRepobox;
import "rxjs/Rx";
import { RequestOptions } from "@angular/http";
@Injectable()

export class ConsultasService { 
  private conexionApis: any = ConexionApisRepoBox;
  private ValidarLoginUri: string = this.conexionApis + "api/consulta/NewLogin";
  private ValidarLoginNominaUri: string = this.conexionApis + "api/consulta/NewLoginNomina";
  private ConsultaComprobanteUri: string = this.conexionApis + "api/consulta/Comprobante/consultaComprobante";
  private urlRFC: string = this.conexionApis + "api/consulta/ComplementoPago/";
  private UpdatePagada: string = this.conexionApis + "api/consulta/updatePagada";
  private UrlVerificarComprobantes: string =this.conexionApis + "api/consulta/VerificarComprobantes";
  private UrlVerificarComprobantesPagos: string =this.conexionApis + "api/consulta/VerificarComprobantesPagos";
  private UrlBuscarReceptor: string = this.conexionApis + "api/consulta/buscarReceptor";
  private UrlConsultarProvClien: string = this.conexionApis + "api/consulta/buscarReceptorTodos";
  private UrlCambiarSession: string = this.conexionApis + "api/consulta/CambiarSession";
  private descargaMasivaComp : string = this.conexionApis + "api/consulta/descargaMasivaComprobantes";
  private EstatusDescargaMasiva : string = this.conexionApis + "api/consulta/EstatusDescargaMasiva";
  private UpdateDescargaMasiva : string = this.conexionApis + "api/consulta/UpdateDescargaMasiva";
  private GetobtenerReporte: string =this.conexionApis+"api/consulta/obtenerReporte";
  private GetobtenerExcel: string =this.conexionApis+"api/consulta/obtenerExcel";
  private ReporteComprobanteUri: string = this.conexionApis + "api/consulta/ReporteComprobante/consultaComprobante";

  
  //==================================
  //servicios aws
  //==================================
  private URLPDF: string = this.conexionApis + "api/ServiceAws/ObtenerFileAWS/?ID=";
  private GetFIlesUrl: string = this.conexionApis + "api/ServiceAws/GetFiles";
  private GetFilesSinTokenUrl: string = this.conexionApis + "api/ServiceAws/GetFilesSinToken";
  private GetFilePdfSinTokenUrl: string = this.conexionApis + "api/ServiceAws/GetFilePdfSinToken";
  
  //==================================
  //Descargas de CFDI
  //==================================
  private UrlDescargaSatManual: string = this.conexionApis + "api/Servicio/DescargaSatManual";
  private UrlDescargaSatAutomatica: string = this.conexionApis + "api/Servicio/DescargaSatAutomatica";
  private UrlEnviarArchivos: string = this.conexionApis + "api/Servicio/EnviarArchivos"; 
  private UrlEditarAcuse: string = this.conexionApis + "api/Servicio";
  private UrlCambiarContrasena: string = this.conexionApis + "api/Servicio/CambiarContrasena"; //cambiar constaseña almacenamiento
  
  private UrlEnviarArchivosSinToken: string = this.conexionApis + "api/Servicio/EnviarArchivosSinToken"; 

  //===================================
  //Sesion
  //==================================
  private urlCantidadConsulta: string = this.conexionApis + "api/Servicio/CambiarCantidadDeConsulta/?cant=";
  private uriActualizarToken: string =this.conexionApis+"api/Servicio/ActualizarToken";
  private UrlRecuperarPassword: string = this.conexionApis + "api/Servicio/RecuperarPasswordSesion";
  private UrlCancelarSuscripcion: string = this.conexionApis + "api/Servicio/cancelarSuscripcion";
  private UrlGuardarCorreo: string = this.conexionApis + "api/consulta/guardarCorreoSucursal";
  //============================
  // Altas
  //============================
  private altaCertificadoRepobox: string = this.conexionApis + "api/Servicio/altaCertificadoRepobox";
  private DescargaZipCertificadoUrl: string = this.conexionApis + "api/Servicio/DescargaZipCertificado";
  
  //===========================
  //Consultas
  private ConsultarCertificadoA: string =this.conexionApis+"api/Servicio/ConsultarCertificado";
  private GetFilesCertificado: string =this.conexionApis+"api/Servicio/GetFilesCertificado";
  private ValidarPasswordUrl : string = this.conexionApis+"api/Servicio/ValidarPasswordCertificado"
  
  //===========================
  
  constructor(private http: Http) {}

  consultaComproba(consultaCom: ConsultaComprobante,index: string) {
    var body = JSON.stringify(consultaCom);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
    return this.http
      .post(this.ConsultaComprobanteUri+"/?NewPageSize="+index, body, { headers: headerOptions })
      .map(x => x.json());
  }

  consultaReporteComprobante(consultaCom: ConsultaComprobante,index: string) {
    var body = JSON.stringify(consultaCom);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    //var requestOptions = new RequestOptions({method : RequestMethod.Post,headers : headerOptions});
    return this.http
      .post(this.ReporteComprobanteUri+"/?NewPageSize="+index, body, { headers: headerOptions })
      .map(x => x.json());
  }
  LoginValidate(user, pass) {

    let url = this.ValidarLoginUri;
    let usuario = user + "," + pass;
    var body = JSON.stringify(usuario);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  LoginValidateNomina(user, pass) {

    let url = this.ValidarLoginNominaUri;
    let usuario = user + "," + pass;
    var body = JSON.stringify(usuario);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  getComprobantes() {
    return this.http.get("").map(res => res.json());
  }

  setPDF(index) {
    let url = `${this.URLPDF}${index}`;
    var body = JSON.stringify(index);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    var rgd = this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

 

  public ZipService(Ficheros) {
    
    let url = this.GetFIlesUrl;
    let body: string = JSON.stringify(Ficheros);

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, {
        headers: headerOptions,
        responseType: ResponseContentType.Blob
      })
      .map((response: Response) => response.blob());
  }

  public ZipServiceSinToken(Ficheros) {
    
    let url = this.GetFilesSinTokenUrl;
    let body: string = JSON.stringify(Ficheros);

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, {
        headers: headerOptions,
        responseType: ResponseContentType.Blob
      })
      .map((response: Response) => response.blob());
  }

  public GetFilePdfSinToken(Ficheros) {
    let url = this.GetFilePdfSinTokenUrl;
    let body: string = JSON.stringify(Ficheros);

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, {headers: headerOptions })
      .map(x => x.json());
  }
  

  public ZipServiceMultiple(Ficheros: any = []) {
    var body = JSON.stringify(Ficheros);

    let url = `${this.GetFIlesUrl}${Ficheros}`;
    var headerOptions = new Headers({ "Content-Type": "arraybuffer" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .get(url, {
        headers: headerOptions,
        responseType: ResponseContentType.Blob
      })
      .map((response: Response) => response.blob());
  }
  public pagada(psClave, psSrc) {
    let url = `${this.UpdatePagada}/${psClave}/${psSrc}`;
    // var body = {psClave:psClave},{psSrc:psSrc}
    let body = JSON.stringify(url);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  setPDFComplemento(index) {
    let url = `${this.URLPDF}${index}`;
    var body = JSON.stringify(index);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    var rgd = this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  EnviarID(id,esPago) {
    if(esPago==true){
     esPago=1
    }else{
      esPago=0
    }
    
    let url = `${this.urlRFC}${id}/${esPago} `;

    var headerOptions = new Headers({ "Content-Type": "arraybuffer" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http.get(url, { headers: headerOptions }).map(x => x.json());
  }
  VerificarComprobantes(Clave) {
    let url = `${this.UrlVerificarComprobantes}`;
    var body = JSON.stringify(Clave);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  VerificarComprobantesPagos(Clave) {
    let url = `${this.UrlVerificarComprobantesPagos}`;
    var body = JSON.stringify(Clave);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    // var rgd = this.http
    //   .post(url, body, { headers: headerOptions })
    //   .map(x => x.json());
    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  public BusquedaReceptorTodas(nombre, tipoConsulta) {
    let url = `${this.UrlConsultarProvClien}`;
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .get(url + "/" + nombre + "/" + tipoConsulta, { headers: headerOptions })
      .map(x => x.json());
  }
  public BusquedaReceptor(nombre, tipoConsulta) {
    let url = `${this.UrlBuscarReceptor}`;
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .get(url + "/" + nombre + "/" + tipoConsulta, { headers: headerOptions })
      .map(x => x.json());
  }
  DescargaSatManual(claveCiec, fechaInicial, fechaFinal, recibidas) {
    var body = JSON.stringify(
      claveCiec + fechaInicial + fechaFinal + recibidas
    );
    let url = `${
      this.UrlDescargaSatManual
    }/?claveCiec=${claveCiec}&initialDateTime=${fechaInicial}&finalDateTime=${fechaFinal}&Recibidas=${recibidas}`;

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  DescargaSatAutomatica(claveCiec) {
    var body = JSON.stringify(claveCiec);
    let url = `${this.UrlDescargaSatAutomatica}/?claveCiec=${claveCiec}`;

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  EnviarArchivos(id, email) {
    var body = JSON.stringify(id);
    let url = `${this.UrlEnviarArchivos}/?mailTo=${email}`;

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  EnviarArchivosSinToken(id, email, rfcEmisor: string, rfcReceptor: string) {
    var body = JSON.stringify(id);
    let url = `${this.UrlEnviarArchivosSinToken}/?mailTo=${email}&rfcEmisor=${rfcEmisor}&rfcReceptor=${rfcReceptor}`;

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  
  EnviarCantidadConsulta(cant) {
    let url = `${this.urlCantidadConsulta}${cant}`;

    var headerOptions = new Headers({ "Content-Type": "arraybuffer" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, null, { headers: headerOptions })
      .map(x => x.json());
  }

  CambiarContrasena(psContrasenaOld, psContrasenaNew) {
    let url = this.UrlCambiarContrasena;
    var body = JSON.stringify({ psContrasenaOld, psContrasenaNew });

    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  NewSignUp(
    psNombre,
    psRazonSocial,
    psRfc,
    psContrasena,
    psContrasena2,
    psCiec,
    psTelefono,
    psDireccion,
    psPostal,
    psEstado,
    psCiudad,
    psIngreso,
    psComentario
  ) {
    // let url = this.UrlNewSignUp;
    // var body = JSON.stringify({psNombre,
    //    psRazonSocial,
    //    psRfc,
    //    psContrasena,
    //    psContrasena2,
    //    psCiec,
    //    psTelefono,
    //    psDireccion,
    //    psPostal,
    //    psEstado,
    //    psCiudad,
    //    psIngreso,
    //    psComentario});
    // var headerOptions = new Headers({ "Content-Type": "application/json" });
    // headerOptions.append("Authorization", localStorage.getItem("Token"));
    // return this.http
    //   .post(url, body, { headers: headerOptions })
    //   .map(x => x.json());
  }
  EditarAcuseValidacion(code) {
    let url = this.UrlEditarAcuse + "/EditarAcuseValidacion";
    var body = JSON.stringify(code);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  EditarAcuseRecepcion(code) {
    let url = this.UrlEditarAcuse + "/EditarAcuseRecepcion";
    var body = JSON.stringify(code);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  EditarConfigComprobante(code) {
    let url = this.UrlEditarAcuse + "/EditarConfigComprobante";
    var body = JSON.stringify(code);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  public CambiarSession(rfc, tokenEmpPrincipal) {
    let url = `${this.UrlCambiarSession}/${localStorage.getItem("clavePrincipal")}`;
    var body = JSON.stringify(rfc);
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    if (tokenEmpPrincipal == null) {
      headerOptions.append("Authorization", localStorage.getItem("Token"));
    } else if (tokenEmpPrincipal == "undefined") {
      headerOptions.append("Authorization", localStorage.getItem("Token"));
    } else {
      headerOptions.append("Authorization", tokenEmpPrincipal);
    }

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }
  RecuperarPassword(correo ,valorCheck) {
    let url = this.UrlRecuperarPassword+"/?EsNomina="+valorCheck;
    var body = JSON.stringify(correo);
    var headerOptions = new Headers({ "Content-Type": "application/json" });

    return this.http
      .post(url, body, { headers: headerOptions })
      .map(x => x.json());
  }

  cancelarSuscripcion(base64) {
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
    let url = `${this.UrlCancelarSuscripcion}/${base64}`;
      return this.http.get(url, { headers: headerOptions }).map(x => x.json());
  }

  public ActualizarToken() {
  var headerOptions = new Headers({ "Content-Type": "application/json" });
  headerOptions.append("Authorization", localStorage.getItem("Token"));
  
    return this.http.post(this.uriActualizarToken,JSON.stringify(localStorage.getItem("Token")),{ headers: headerOptions }).map(x => x.json());
}
  public descargaMasivaCFDI(parametrosConsulta: ModelDescargaMasiva){
    var headerOptions = new Headers({ "Content-Type": "application/json" });
    headerOptions.append("Authorization", localStorage.getItem("Token"));
      return this.http
      .post(this.descargaMasivaComp, parametrosConsulta, { headers: headerOptions })
      .map(x => x.json());
    }
    public GetEstatusDescargaMasiva(){
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
        return this.http
        .get(this.EstatusDescargaMasiva, { headers: headerOptions })
        .map(x => x.json());
      }
    public UpdateEstatusDescargaMasiva(idDescarga : number){
      
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
        return this.http
        .post(this.UpdateDescargaMasiva,idDescarga, { headers: headerOptions })
        .map(x => x.json());
      }
    //=============================================================================
    //Metodo para verificar CFDI
    //=============================================================================
    public altaCertificado(cfdiObject :FormData) {   
        var headerOptions = new Headers({ "Authorization": localStorage.getItem("Token")});
        let options = new RequestOptions({ headers: headerOptions });   
        return this.http
        .post(this.altaCertificadoRepobox,cfdiObject, options) 
        .map(x => x.json());
        
    }
    public DescargaZipCertificado(rfc:string ) {   
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));

      var body = JSON.stringify(rfc);
      let options = new RequestOptions({ headers: headerOptions });   
      return this.http.post(this.DescargaZipCertificadoUrl, body, {
        headers: headerOptions,
        responseType: ResponseContentType.Blob
      })
      .map((response: Response) => response.blob());
        
    }
    //=============================================================================
    //Metodo para Consultar Certificado
    //=============================================================================
    public ConsultarCertificado( rfc:string, tipo : string = "CSD" ) { 
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));

      var body = JSON.stringify(rfc);
        let options = new RequestOptions({ headers: headerOptions });   
        return this.http
        .post(this.ConsultarCertificadoA + "/" + tipo, body, { headers: headerOptions }) 
        .map(x => x.json());
        
    }

    public ValidarPasswordCer( rfc:string, password : string) { 
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));

      var body = JSON.stringify(password);
        let options = new RequestOptions({ headers: headerOptions });   
        return this.http
        .post(this.ValidarPasswordUrl + "/" + rfc, body, { headers: headerOptions }) 
        .map(x => x.json());
        
    }
    //=============================================================================
    //Metodo para DESCARGAR Certificado
    //=============================================================================

    public descargarCertificado(rfc:string, tipo: string) {
      var headerOptions = new Headers({ "Content-Type": "arraybuffer" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
      return this.http
        .get(this.GetFilesCertificado+"/"+rfc + "/"+ tipo , {headers: headerOptions,responseType: ResponseContentType.Blob})
        .map((response: Response) => response.blob());
    }
    
    public ObtenerReporte(comprobante:ConsultaComprobante) { 
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
      var body = comprobante;
      

      return this.http
        .post(this.GetobtenerReporte, body, { headers: headerOptions })
        .map(x => x.json());
    }

    public ObtenerExcel(comprobante:ConsultaComprobante) { 
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
      var body = comprobante;
      

      return this.http
        .post(this.GetobtenerExcel, body, { headers: headerOptions })
        .map(x => x.json());
    }

    guardarCorreoSucursal( correo : string){
      var headerOptions = new Headers({ "Content-Type": "application/json" });
      headerOptions.append("Authorization", localStorage.getItem("Token"));
      let url = this.UrlGuardarCorreo + "/" + correo;
      let body = null;
      return this.http
        .post(url, body ,{ headers: headerOptions })
        .map(x => x.json());
    }
    
  }

