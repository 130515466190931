import { Servicios } from '../../../Interfaces/ClsEmpresaPermisos';
export class ValidarPermisos{
    Notificaciones:boolean=false;
    EnvioEmail:boolean=false;
    CorreoPersonalizado:boolean=false;
    DescargaSatAut:boolean=false;
    DescargaSatMan:boolean=false;
    VerificacionMan:boolean=false;
    UtilizarWebServices:boolean=false;
    RegistroDocsPagados:boolean=false;
    ConsultaGrafica:boolean=false;
    PersonalizarNotRecepcion:boolean=false;
    PersonalizarNotValidacion:boolean=false;
    PersonalizaEnvioComp:boolean=false;
    VerificacionArt69B:boolean=false    ;
    constructor(){ }

         public  consultarPermisos(listaPermisos:Servicios[])
         { 
             let IDs = [];
            for (var i = 0; i < listaPermisos.length; i++) {
                // //Notificaciones
                // if(listaPermisos[i].ID==2)
                // this.Notificaciones=true;
                
                //Envío de comprobantes vía e-mail
                if(listaPermisos[i].ID==3)
                this.EnvioEmail=true;

            //     //Correo personalizado No se esta utilizando
                if(listaPermisos[i].ID==4)
                this.CorreoPersonalizado=true;

            //     //Cotejado SAT Automatico
                if(listaPermisos[i].ID==5)
                this.DescargaSatAut=true;

            //     //Descarga SAT Manual
                if(listaPermisos[i].ID==6)
                this.DescargaSatMan=true;

            //     //Verificacion manual de vigencia de documentos
                if(listaPermisos[i].ID==7)
                this.VerificacionMan=true;

            // //     //Utilizacion de webservices
            // //     if(listaPermisos[i].ID==8)
            // //     this.UtilizarWebServices=true;

            // //     //Registro de documentos pagados
                if(listaPermisos[i].ID==9)
                this.RegistroDocsPagados=true;
            // //     //Consulta grafica
            // //     if(listaPermisos[i].ID==10)
            // //     this.ConsultaGrafica=true;

                //Personalizar nofiticacion de recepción
                if(listaPermisos[i].ID==11)
                this.PersonalizarNotRecepcion=true;

            //     //Personalizar notificación de validación 
                if(listaPermisos[i].ID==12)
                this.PersonalizarNotValidacion=true;

               //Personaliza envío de  comprobantes
               if(listaPermisos[i].ID==13)
               this.PersonalizaEnvioComp=true;

            // //    Verificacion de la lista Art. 69-B
                if(listaPermisos[i].ID==15)
                this.VerificacionArt69B=true;
                
              }
         }

}