import { Component, ViewChild } from "@angular/core";
import { CKEditorComponent } from "ng2-ckeditor";

import * as $ from "jquery";

import { ConsultasService } from '../../services/consultas.service';
import { SweetAlertModals } from '../Clases/alert';
import { ValidarPermisos } from '../consulta-facturas/ClasesFacturas/Permisos';
import { Router } from '@angular/router';

declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-edicioncomprobante',
  templateUrl: './edicioncomprobante.component.html',
  styleUrls: ['./edicioncomprobante.component.css']
})
export class EdicioncomprobanteComponent {
  formato: any =
  '<p style="text-align:center">Repobox</p> <p><a href="http://www.repobox.com.mx"><img id="logo" src="../assets/NuevoIndex/img/logos/logo.png" /> </a></p> <p>Haga clic en el siguiente enlace para descargar sus archivos: {Size} aproximadamente.</p> <blockquote> <p>&nbsp;<a href="{linkDownload}" target="_blank">Descargar</a></p> </blockquote> <h2>Disfruta de los beneficios de ser cliente de</h2> <p><a href="http://www.repobox.com.mx"><img src="../assets/NuevoIndex/img/logos/logo.png" /></a></p> <p>Repobox es una plataforma estandar para administrar la relacion con los clientes y proveedores, desde la integracion del padron, hasta el eficaz intercambio de documentos, funcionando con la seguridad y alta disponibilidad que nos brinda el trabajar sobre la nube. Fomentando la transparencia y eficiencia en los procesos administrativos de la instituciones.</p> <h2>SOPORTE</h2> <p><img src="https://k60.kn3.net/D/9/F/B/7/1/894.png" />(644) 179 7888<br /> <img src="https://k60.kn3.net/D/9/F/B/7/1/894.png" />(644) 179 7089<br /> <img src="https://k60.kn3.net/D/9/F/B/7/1/894.png" />(644) 415 6020</p> <p>Visita nuestro sitio de soporte</p> <p><a href="https://repobox.freshdesk.com/support/home">Repositorio y facturacion electronica</a> <a href="https://htamexico.freshdesk.com/support/home">ERP</a></p> <p><a href="http://www.repobox.com.mx/#contacto">RepoBox, Contrata ahora!</a></p> <p><br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> &nbsp;</p> <p style="text-align:center">&nbsp;</p>';
ckeditorContent: string = this.formato;
alerta = new SweetAlertModals
botonImagenIndex1: boolean = false;
botonImagenIndex2: boolean = false;
botonImagenIndex3: boolean = false;
botonImagenIndex4: boolean = false;
botonImagenIndex5: boolean = false;
botonImagenIndex6: boolean = false;
botonImagenIndex7: boolean = false;
botonImagenIndex8: boolean = false;
@ViewChild(CKEditorComponent)
ckEditor: CKEditorComponent;
permisosUsuarios:ValidarPermisos;
constructor(private _consultasService:ConsultasService,public router:Router) {
  this.permisosUsuarios = new ValidarPermisos();

  let usuarioEmpresa=JSON.parse(localStorage.getItem("usuario"));
  this.permisosUsuarios.consultarPermisos(usuarioEmpresa.Servicios);
}
ngAfterViewChecked() {

  (function($) {
    var options = {
      allowedFormats: ["jpg", "png", "gif"]
    };

    $.fn.imgUpload = function(opts) {
      if (this.filter("div").hasClass("img-upload")) {
        options = $.extend(options, opts);
        bindEvents(this);
      }
      return this;
    };

    function getHtmlErrorMsg(msg) {
      return (
        "<div class='alert alert-danger alert-dismissible'><button type='button' class='close' data-dismiss='alert'><span>&times;</span></button>" +
        msg +
        "</div>"
      );
    }

    function getHtmlImg(src) {
      return (
        "<img src='" +
        src +
        "' alt='Image preview' width='100' class='img-thumbnail'>"
      );
    }

    function isValidImgUrl(url, callback) {
      var img = new Image();
      img.onerror = function() {
        callback(url, false, "URL does not refer to an image.");
      };
      img.onload = function() {
        var fileExt = url.substring(url.length - 3).toLowerCase();
        if ($.inArray(fileExt, options.allowedFormats) > -1) {
          callback(url, true, "");
        } else {
          callback(url, false, "Image format not allowed.");
        }
      };
      img.src = url;
    }

    function bindEvents($imgUpload) {
      $imgUpload.find(".img-file-btn a").click(function() {
        showFileTab($(this).parent());
      });
      var $fileTab = $imgUpload.find(".img-file-tab");
      $fileTab.find(".img-select-btn").change(function() {
        selectImgFile($(this));
      });
      $fileTab.find(".img-remove-btn").click(function() {
        removeImgFile($(this));
      });
      $imgUpload.find(".img-url-btn a").click(function() {
        showUrlTab($(this).parent());
      });
      $imgUpload.find(".img-url-tab .img-select-btn").click(function() {
        selectImgUrl($(this));
      });
    }

    function showFileTab($fileBtn) {
      if (!$fileBtn.hasClass("active")) {
        var $imgUpload = $fileBtn.closest(".img-upload");
        var $urlTab = $imgUpload.find(".img-url-tab");

        $fileBtn.addClass("active");
        $imgUpload.find(".img-file-tab").show();
        $imgUpload.find(".img-url-btn").removeClass("active");
        $urlTab.hide();

        $urlTab.find(".alert").remove();
        $urlTab.find("input").val("");
        $urlTab.find(".img-select-btn").text("Submit");
        $urlTab.find("img").remove();
      }
    }

    function selectImgFile($selectBtn) {
      var $input = $selectBtn.find("input");
      var $fileTab = $selectBtn.closest(".img-file-tab");
      var hasFile = $input[0].files && $input[0].files[0];
      $fileTab.find(".alert").remove();

      if (!hasFile) {
        $fileTab.prepend(getHtmlErrorMsg("Error uploading file."));
        return;
      }

      var file = $input[0].files[0];
      var fileExt = file.name.substring(file.name.length - 3).toLowerCase();

      if ($.inArray(fileExt, options.allowedFormats) > -1) {
        var reader = new FileReader();
        reader.onload = function(e) {
          $fileTab.find("img").remove();
          $fileTab.append(getHtmlImg(reader.result));
        };
        reader.onerror = function(e) {
          $fileTab.prepend(getHtmlErrorMsg("Error uploading file."));
        };
        reader.readAsDataURL(file);

        $selectBtn.find("span").text("Cambiar");
        $fileTab.find(".img-remove-btn").css("display", "inline-block");
      } else {
        $fileTab.prepend(getHtmlErrorMsg("Image format not allowed."));
      }
    }

    function removeImgFile($removeBtn) {
      var $fileTab = $removeBtn.closest(".img-file-tab");
      $fileTab.find(".alert").remove();
      $fileTab.find(".img-select-btn span").text("Buscar");
      $fileTab.find("input").val("");
      $fileTab.find("img").remove();
      $removeBtn.hide();
    }

    function showUrlTab($urlBtn) {
      if (!$urlBtn.hasClass("active")) {
        var $imgUpload = $urlBtn.closest(".img-upload");
        var $fileTab = $imgUpload.find(".img-file-tab");

        $urlBtn.addClass("active");
        $imgUpload.find(".img-url-tab").show();
        $imgUpload.find(".img-file-btn").removeClass("active");
        $fileTab.hide();

        $fileTab.find(".alert").remove();
        $fileTab.find("input").val("");
        $fileTab.find(".img-select-btn span").text("Buscar");
        $fileTab.find(".img-remove-btn").hide();
        $fileTab.find("img").remove();
      }
    }

    function selectImgUrl($selectBtn) {
      var $urlTab = $selectBtn.closest(".img-url-tab");
      var $input = $urlTab.find("input");
      $urlTab.find(".alert").remove();

      if ($selectBtn.text() == "X") {
        $input.val("");
        $selectBtn.text("Submit");
        $urlTab.find("img").remove();
        return;
      }

      isValidImgUrl($urlTab.find("input:text").val(), function(
        url,
        isValid,
        msg
      ) {
        if (isValid) {
          $urlTab.find("input:hidden").val(url);
          $urlTab.find("img").remove();
          $urlTab.append(getHtmlImg(url));
          $selectBtn.text("X");
        } else {
          $urlTab.prepend(getHtmlErrorMsg(msg));
        }
      });
    }
  })(jQuery);
  $("#cke_1_contents").css("height", "500px");
  $(".img-upload").imgUpload();
  //Called after every check of the component's view. Applies to components only.
  //Add 'implements AfterViewChecked' to the class.
  let editor = this.ckEditor.instance;
 let config = editor.config;


  editor.config.height = "400";

  editor.config.toolbarGroups = [
    { name: "document", groups: ["mode", "document", "doctools"] },
    { name: "clipboard", groups: ["clipboard", "undo"] },
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"]
    },
    { name: "forms", groups: ["forms"] },
    "/",
    { name: "basicstyles", groups: ["basicstyles", "cleanup"] },
    {
      name: "paragraph",
      groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
    },
    { name: "links", groups: ["links"] },
    { name: "insert", groups: ["insert"] },
    "/",
    { name: "styles", groups: ["styles"] },
    { name: "colors", groups: ["colors"] },
    { name: "tools", groups: ["tools"] },
    { name: "others", groups: ["others"] },
    { name: "about", groups: ["about"] },

  ] ;



  editor.ui.addRichCombo('my-combo', {

    label: 'Parámetros',
    title: 'Parámetros',
    toolbar: 'basicstyles,0',

    panel: {

        multiSelect: false,
        attributes: {  }
    },

    init: function () {
        var cont = 0;
        var removeItem = "";
        var objCods_ = null;

//objCods_ = ["<p style='color:black;font-family:Verdana,Geneva,Tahoma,sans-serif'>{FECHA}</p>", "<p style='color:black;font-family:Verdana,Geneva,Tahoma,sans-serif'>{FOLIOVALIDACION}</p>", "{FOLIOENVIO}", "{EMISOR}", "{RFCEMISOR}", "{RECEPTOR}", "{RFCRECEPTOR}", "{FOLIOFAC}", "{FECHAFAC}", "{SERIEFAC}", "{UUID}", "{ERRORES}", "{TablaErrores}"];
objCods_ = ["{linkDownload}", "{Size}"];
        while (objCods_.length != 0)
        {
            removeItem=objCods_[0];
            this.add(removeItem, removeItem);
            objCods_.splice($.inArray(objCods_[0], objCods_), 1);
            cont++;
        }

    },
    onClick: function (value) {
      editor.focus();
      editor.fire('saveSnapshot');

      editor.insertHtml(value);

      editor.fire('saveSnapshot');
  }




})

}
ngOnInit(): void {
  //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //Add 'implements OnInit' to the class.
  if(this.permisosUsuarios.PersonalizaEnvioComp!=true)
  {
    this.router.navigate(["/comprobante/configuracion"])
  }
}

public Guardar()
{

  //this.formato.replace("{{'}}",'{{"}}')


  if(!this.ckeditorContent.includes("{linkDownload}"))
  {
    this.alerta.ModalError("Error",'Agrege el parametro {linkDownload}')
  }
  else   if(!this.ckeditorContent.includes("{Size}"))
  {
    this.alerta.ModalError("Error",'Agrege el parametro {Size}')
  }
  else
  {
    this._consultasService.EditarConfigComprobante(btoa(this.ckeditorContent)).subscribe(data=>{

      if (data.success == true)
        this.alerta.ModalSuccess("Perfecto","Guardado con exito")
    else
    this.alerta.ModalError("Error",data.msg.toString())
    })
  }


}
sumarIndex(sc) {

  if (sc == 1) {
    this.botonImagenIndex1 = true;
  }
  if (sc == 2) {
    this.botonImagenIndex2 = true;
  }
  if (sc == 3) {
    this.botonImagenIndex3 = true;
  }
  if (sc == 4) {
    this.botonImagenIndex4 = true;
  }
  if (sc == 5) {
    this.botonImagenIndex5 = true;
  }
  if (sc == 6) {
    this.botonImagenIndex6 = true;
  }
  if (sc == 7) {
    this.botonImagenIndex7 = true;
  }
  if (sc == 8) {
    this.botonImagenIndex8 = true;
  }

  //  var itm = document.getElementById("myList2").lastChild;
  //  var cln = itm.cloneNode(true);
  // document.getElementById("myList1").appendChild(cln);
}
removerItem(sc) {

  if (sc == 1) {
    this.botonImagenIndex1 = false;
  }
  if (sc == 2) {
    this.botonImagenIndex2 = false;
  }
  if (sc == 3) {
    this.botonImagenIndex3 = false;
  }
  if (sc == 4) {
    this.botonImagenIndex4 = false;
  }
  if (sc == 5) {
    this.botonImagenIndex5 = false;
  }
  if (sc == 6) {
    this.botonImagenIndex6 = false;
  }
  if (sc == 7) {
    this.botonImagenIndex7 = false;
  }
  if (sc == 8) {
    this.botonImagenIndex8 = false;
  }
  // var elem = document.getElementById(idName);
  // elem.parentNode.removeChild(elem);
}
public CalcelarFormato() {
  this.ckeditorContent = this.formato;
}
}
