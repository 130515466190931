import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class GlobalsService {
    usuarioIRC : boolean = false;
    mensajeToolbar : boolean = false;
    loginIndex : boolean = false;
    constructor() {}

    consultarUsuarioIRC(){
       let usuario = localStorage.getItem('usuarioIRC');
       if(usuario == "true"){
           this.usuarioIRC = true;
       }else{
           this.usuarioIRC = false;
       }
       return this.usuarioIRC;
    }

}
