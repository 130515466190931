import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class Auth0Service implements CanActivate {
  isLogged:boolean;
  usuarioName:any;
  usuarioName2: any;
  constructor( private router:Router) {  this.isLogged = false;}
  isloged(){

    var aValue = localStorage.getItem("Token");
    let usuario=localStorage.getItem("tipo");
      if(aValue!=null && usuario=="esEmpresa"){
        return this.isLogged=true
      }
      else{
        return this.isLogged=false;
      }
  }
  
  public   login  (objectoUsu){
      this.isLogged = true;
      if(objectoUsu.empresa != undefined){
             //SIGNIFICA QUE ES USUARIO EMPRESA
             this.rfcUsuario(objectoUsu.empresa.RFC);
             this.razonSocial(objectoUsu.empresa.Empresa);
             localStorage.setItem("Token", objectoUsu.token);
             localStorage.setItem("TokenVence", objectoUsu.tokenVence);
             return "esEmpresa";
        }
      else  if(objectoUsu.loNomina != undefined){
           //SIGNIFICA QUE ES USUARIO NOMINA
           
           this.rfcUsuario(objectoUsu.loNomina.Rfc);
           this.razonSocial(objectoUsu.loNomina.Nombre);  
           localStorage.setItem("Token", objectoUsu.token);
           if(objectoUsu.loNomina.ModContra != undefined)
           localStorage.setItem("ModalContra", objectoUsu.loNomina.ModContra);
           return "esNomina";    
     }else{
      return false;
     }
       
  }
  
    public logout(){
      this.isLogged = false;
      localStorage.removeItem("Token");
      localStorage.removeItem("nombreUsu")
      localStorage.removeItem("razonSocial");
      localStorage.removeItem("tipo");
      localStorage.removeItem("TokenMaster");
      localStorage.removeItem("empresaXusuario");
      localStorage.removeItem("usuario");
      localStorage.removeItem("certificadoCSD");
      localStorage.removeItem("prueba");
      localStorage.removeItem("clavePrincipal");
      this.router.navigate([""]);
    }
    public especialLogout()
    {
      localStorage.removeItem("Token");
      localStorage.removeItem("nombreUsu")
      localStorage.removeItem("razonSocial");
      localStorage.removeItem("tipo");
      localStorage.removeItem("certificadoCSD");
 
    }
    public rfcUsuario(rfc){ 
        localStorage.setItem("nombreUsu", rfc); 
      }
    public   razonSocial(razon){ 
        localStorage.setItem("razonSocial", razon); 
      }
    public  usuarioNamePagina(){
      this.usuarioName="RFC: "+localStorage.getItem("nombreUsu")+" - "+localStorage.getItem("razonSocial");
       return  this.usuarioName;
        }

        public usuarioNamePagina2(){
          this.usuarioName2 = localStorage.getItem("nombreUsu");
          return this.usuarioName2;
        }
      public   tipoUsuario():boolean{
       
          let usuario=localStorage.getItem("tipo");
          if(usuario=="esEmpresa")
          return true;
          else return false;
         
        }
    public canActivate( ){
      if( this.isloged() ){
        return true;
      }else{
        this.router.navigate([""]);
        return false;
      }
    }
  }
  
